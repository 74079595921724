import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import type { MessageGraph } from '@bladebinge/types';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { ThreadMessage } from './thread-atoms/ThreadMessage';

export const messageCreatedAtSortReversed = (a: MessageGraph, b: MessageGraph) => {
    const { createdAt: createdAtA } = a;
    const { createdAt: createdAtB } = b;
    const aDate = createdAtA ? Date.parse(createdAtA) : null;
    const bDate = createdAtB ? Date.parse(createdAtB) : null;

    if (!aDate || !bDate) {
        return 0;
    }

    return aDate > bDate ? 1 : -1;
};

export const MessageThreadView = ({
    loading = true,
    messages = [],
    viewAsUserId
}: {
    readonly loading: boolean;
    readonly messages?: MessageGraph[];
    readonly viewAsUserId?: string;
}) => {
    const sortedMessages = useMemo(
        () =>
            loading
                ? null
                : messages
                      .sort(messageCreatedAtSortReversed)
                      .map((message) => (
                          <ThreadMessage key={message.id} message={message} viewAsUserId={viewAsUserId} />
                      )),
        [loading, messages, viewAsUserId]
    );

    return (
        <Grid container>
            <Grid container item xs={12} sx={{ position: 'relative' }}>
                {loading ? (
                    <Grid
                        container
                        item
                        xs={12}
                        sx={{
                            position: 'absolute',
                            zIndex: 1,
                            textAlign: 'center',
                            left: '48%'
                        }}
                    >
                        <LoadingIndicator inline />
                    </Grid>
                ) : (
                    sortedMessages
                )}
            </Grid>
        </Grid>
    );
};
