export const senderColor = 'primary.contrastText';
export const senderBgColor = 'primary.main';
export const recipientBgColor = 'secondary.main';
export const recipientColor = 'secondary.contrastText';

export const paperStyles = {
    sender: {
        position: 'relative',
        my: 1.5,
        mx: 0.5,
        px: 1.5,
        py: {
            xs: 0.5,
            sm: 1,
            md: 2.5
        },
        backgroundColor: `${senderBgColor}`,
        color: `${senderColor}`,
        fontWeight: 'bold',
        fontSize: '1.1em',
        '&:before': {
            content: '""',
            width: 0,
            height: 0,
            position: 'absolute',
            border: '8px solid',
            borderLeftColor: `${senderBgColor}`,
            borderRightColor: 'transparent',
            borderTopColor: `${senderBgColor}`,
            borderBottomColor: 'transparent',
            right: '-15px',
            top: '15px'
        },
        wordBreak: 'break-word'
    },
    recipient: {
        position: 'relative',
        mx: 1.5,
        my: 1.5,
        p: 1.5,
        py: {
            xs: 0.5,
            sm: 1,
            md: 2.5
        },
        backgroundColor: `${recipientBgColor}`,
        color: `${recipientColor}`,
        fontWeight: 'bold',
        fontSize: '1.1em',
        '&:before': {
            content: '""',
            width: 0,
            height: 0,
            position: 'absolute',
            border: '8px solid',
            borderColor: `${recipientBgColor}`,
            borderLeftColor: 'transparent',
            borderRightColor: `${recipientBgColor}`,
            borderBottomColor: 'transparent',
            borderTopColor: `${recipientBgColor}`,
            left: '-15px',
            top: '15px'
        },
        wordBreak: 'break-word'
    }
};
