import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CurrentUserPersonalData, User, UserTerms } from '@bladebinge/types';
import {
    getActiveProfileIdFromLocalStorage,
    getMeFromLocalStorage,
    setActiveAvatarThumbnailInLocalStorage,
    setActiveProfileIdInLocalStorage,
    setMeInLocalStorage
} from '@bladebinge/web-service-common/src/utils/local-storage/local-storage-util';
import { getPubSub } from '@bladebinge/web-service-common/src/pubsub/get-pub-sub';
import { logoutUserGetRequest } from '../../server/api-proxy/ui-mutation-fns/logout-user';
import { useLoggedInUserActiveProfile } from '../../hooks/react-query/logged-in-user-hooks/use-logged-in-user-active-profile';
import { useLoggedInUser } from '../../hooks/react-query/logged-in-user-hooks/use-logged-in-user';
import { useRedirectToLogin } from '../../hooks/use-redirect-to-login';
import { useLoggedInUserActivePurchaseOfferListingIds } from '../../hooks/react-query/logged-in-user-hooks/use-logged-in-user-active-purchase-offer-listing-ids';

interface MeContext {
    activeProfileId: string | null;
    activePurchaseOfferListingIds: Set<string>;
    hasActiveListings?: boolean;
    id?: string | null;
    isLoggedInUserFetched: boolean;
    loadingActiveProfile: boolean;
    logoutUser(signal?: AbortSignal): unknown;
    me: CurrentUserPersonalData | null;
    requiredTermsVersion: string;
    setMe(me: CurrentUserPersonalData | null): void;
    updateUserTermsInActiveProfile(userTerms: UserTerms): void;
    user?: User | null;
    userAgreedToCurrentTerms: boolean | null;
}

const meContext = createContext<MeContext>({
    activeProfileId: null,
    activePurchaseOfferListingIds: new Set(),
    hasActiveListings: false,
    id: null,
    isLoggedInUserFetched: false,
    loadingActiveProfile: false,
    async logoutUser(signal?: AbortSignal): Promise<void> {},
    me: null,
    requiredTermsVersion: '',
    setMe(me: CurrentUserPersonalData | null) {},
    updateUserTermsInActiveProfile() {},
    user: null,
    userAgreedToCurrentTerms: null
});

const { Provider } = meContext;

export const MeContextProvider = ({
    children,
    currentUser
}: {
    readonly children: React.ReactNode;
    readonly currentUser: CurrentUserPersonalData | null;
}) => {
    const queryClient = useQueryClient();
    const redirectToLogin = useRedirectToLogin();

    const [activeProfileId, setActiveProfileId] = useState<string | null>(null);
    const [hasActiveListings, setHasActiveListings] = useState<boolean>(false);

    const [me, setMe] = useState<CurrentUserPersonalData | null>(currentUser);
    const [userAgreedToCurrentTerms, setUserAgreedToCurrentTerms] = useState<boolean | null>(null);
    const [activePurchaseOfferListingIds, setActivePurchaseOfferListingIds] = useState<Set<string>>(new Set());

    const logoutUserMutation = useMutation({
        mutationFn: logoutUserGetRequest,
        onSuccess() {
            queryClient.clear();
            setMeInLocalStorage(null);
            setActiveProfileIdInLocalStorage();
            setActiveAvatarThumbnailInLocalStorage();
            setMe(null);
            // redirect if in a user route
            redirectToLogin(true);
        }
    });
    const { isPending: isProcessingLogout } = logoutUserMutation;

    const { data: activeProfileServerData, isLoading: loadingActiveProfile } = useLoggedInUserActiveProfile({
        userId: me?.id
    });

    const { data: user, isFetched: isLoggedInUserFetched } = useLoggedInUser({
        id: me?.id
    });

    const { data: rawActiveOfferListingIds } = useLoggedInUserActivePurchaseOfferListingIds({
        loggedInUserId: me?.id
    });

    useEffect(() => {
        const activeListingIdsArray = Array.isArray(rawActiveOfferListingIds) ? rawActiveOfferListingIds : [];
        setActivePurchaseOfferListingIds(new Set(activeListingIdsArray));
    }, [rawActiveOfferListingIds]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setActiveProfileId(getActiveProfileIdFromLocalStorage());
        setMe(getMeFromLocalStorage());
    }, []);

    const updateUserTermsInActiveProfile = (userTerms: UserTerms) => {
        const requiredTermsVersion = activeProfileServerData?.requiredTermsVersion;
        const userTermsVersion = userTerms?.termsVersion;

        if (!requiredTermsVersion || !userTermsVersion) {
            return;
        }

        setUserAgreedToCurrentTerms(requiredTermsVersion === userTermsVersion);
    };

    useEffect(() => {
        setActiveProfileIdInLocalStorage(activeProfileServerData?.id);
        setActiveAvatarThumbnailInLocalStorage(activeProfileServerData?.avatar?.thumbnailUrl);
        setActiveProfileId(activeProfileServerData?.id ?? null);
        setHasActiveListings(activeProfileServerData?.hasActiveListings ?? false);

        if (activeProfileServerData?.userTerms) {
            updateUserTermsInActiveProfile(activeProfileServerData.userTerms);
        }
    }, [activeProfileServerData]);

    const logoutUser = useCallback(() => {
        if (isProcessingLogout) {
            return;
        }

        try {
            logoutUserMutation.mutate(undefined);
        } catch (err) {
            // do nothing
        }
    }, [isProcessingLogout, me?.id]);

    useEffect(() => {
        getPubSub().on('uiLogout', logoutUser);

        return () => {
            getPubSub().removeListener('uiLogout', logoutUser);
        };
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <Provider
            value={{
                activeProfileId,
                activePurchaseOfferListingIds,
                isLoggedInUserFetched,
                loadingActiveProfile,
                logoutUser,
                hasActiveListings,
                id: me && me?.id ? me.id : null,
                me,
                requiredTermsVersion: activeProfileServerData?.requiredTermsVersion ?? '',
                setMe,
                updateUserTermsInActiveProfile,
                user,
                userAgreedToCurrentTerms
            }}
        >
            {children}
        </Provider>
    );
};

export const useMe = () => useContext(meContext);
