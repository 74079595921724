/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 */
const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const readableBytes = (num: number): string => {
    const neg = num < 0;
    let absoluteNum = num;

    if (neg) {
        absoluteNum = -num;
    }

    if (absoluteNum < 1) {
        return (neg ? '-' : '') + absoluteNum + ' B';
    }

    const exponent = Math.min(Math.floor(Math.log(absoluteNum) / Math.log(1000)), units.length - 1);

    const formattedNum = Number((absoluteNum / 1000 ** exponent).toFixed(2));

    const unit = units[exponent];

    return (neg ? '-' : '') + formattedNum + ' ' + unit;
};
