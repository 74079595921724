import { ATTACHMENT_ONLY_EMPTY_MESSAGE_CONTENT } from '@bladebinge/web-service-common/src/constants/messaging';

export const getMessageBodyDisplayText = ({
    attachmentCount = 0,
    messageBody,
    hideAttachmentText = false,
    t,
    truncateLength
}: {
    attachmentCount?: number;
    messageBody: string;
    hideAttachmentText?: boolean;
    t: (key: string, options?: Record<string, unknown>) => string;
    truncateLength?: number;
}) => {
    const messageBodyTrimmed = messageBody.trim();

    const messageDisplayTextFull =
        messageBodyTrimmed === ATTACHMENT_ONLY_EMPTY_MESSAGE_CONTENT
            ? hideAttachmentText
                ? ''
                : t('common:messaging.attachment_only_text', { count: attachmentCount })
            : messageBodyTrimmed;

    const messageDisplayText = truncateLength
        ? `${messageDisplayTextFull.slice(0, truncateLength)}${messageDisplayTextFull.length > truncateLength ? '...' : ''}`
        : messageDisplayTextFull;

    return messageDisplayText;
};
