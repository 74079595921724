// ratings are out of 10 but we use 5-star scale
export const getStarValueFromRating = (rating?: number) => {
    if (!Number.isFinite(rating)) {
        return 0;
    }

    if (!rating || rating < 0) {
        return 0;
    }

    return rating / 2;
};
