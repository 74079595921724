import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import type { AddressType } from '@bladebinge/types';
import { useTranslation } from 'next-i18next';
import { getAddressCacheKey } from '@bladebinge/web-service-common/src/utils/get-address-cache-key';
import { LoadingIndicator } from '../atoms/LoadingIndicator';
import { useUserAddressBook } from '../../hooks/react-query/logged-in-user-hooks/use-user-address-book';

export const UserProfileAddressSelector = ({
    clearSelectionText,
    inputLabel,
    onSelect,
    preselectedAddressId = '',
    preselectPrimaryType,
    title
}: {
    readonly clearSelectionText?: string;
    readonly inputLabel: string;
    readonly onSelect: (addressId: string) => void;
    readonly preselectedAddressId?: string;
    readonly preselectPrimaryType?: AddressType;
    readonly title: string;
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [selectedAddressBookId, setSelectedAddressBookId] = React.useState<string>('');

    const { data: addressBook = [], isFetched: isAddressBookFetched } = useUserAddressBook();

    const clearButtonText = clearSelectionText ?? t('common:address_form.clear_selection');

    const handleSelectAddress = (addressId: string | '') => {
        setSelectedAddressBookId(addressId as string);
        onSelect(addressId as string);
    };

    const handleSelectAddressOnChange = (event: SelectChangeEvent<string>) => {
        const addressId: unknown = event.target.value ?? '';
        handleSelectAddress(addressId as string);
    };

    const handleClearSelection = () => {
        setSelectedAddressBookId('');
        onSelect('');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (isAddressBookFetched && Array.isArray(addressBook) && addressBook.length > 0) {
            const preselectedAddressById = addressBook.find((address) => address.id === preselectedAddressId);

            if (preselectedAddressById && preselectedAddressId !== selectedAddressBookId) {
                setSelectedAddressBookId(preselectedAddressId);
                onSelect(preselectedAddressId);
                return;
            }

            if (preselectPrimaryType) {
                const selectionKey = preselectPrimaryType === 'billing' ? 'isPrimaryBilling' : 'isPrimaryShipping';
                const preselectAddress = addressBook.find((address) => address[selectionKey]);
                if (preselectAddress && preselectAddress?.id && selectedAddressBookId !== preselectAddress.id) {
                    setSelectedAddressBookId(preselectAddress.id);
                    onSelect(preselectAddress.id);
                    return;
                }
            }

            const firstAddressBookAddressId = addressBook[0].id;
            setSelectedAddressBookId(firstAddressBookAddressId);
            onSelect(firstAddressBookAddressId);
        }
    }, [addressBook, isAddressBookFetched]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const addressOptionsMarkup = Array.isArray(addressBook)
        ? addressBook.map((address) => {
              const key = getAddressCacheKey(address);
              const { id, isPrimaryBilling, isPrimaryShipping, name, street1, city, state, zip } = address;
              return (
                  <MenuItem key={key} value={id} sx={{ whiteSpace: 'normal' }}>
                      <Typography className="address-book-option" variant="body2" sx={{ overflow: 'hidden' }}>
                          {name}: {street1} {city}, {state} {zip}
                          {isPrimaryShipping && (
                              <Typography variant="caption" sx={{ color: 'info.dark', ml: 0.75 }}>
                                  {`* ${t('common:address_form.primary_shipping_address')}`}
                              </Typography>
                          )}
                          {isPrimaryBilling && (
                              <Typography variant="caption" sx={{ color: 'info.dark', ml: 0.75 }}>
                                  {`* ${t('common:address_form.primary_billing_address')}`}
                              </Typography>
                          )}
                      </Typography>
                  </MenuItem>
              );
          })
        : [];

    if (!isAddressBookFetched) {
        return <LoadingIndicator color="primary" />;
    }

    if (!addressBook || !Array.isArray(addressBook) || (Array.isArray(addressBook) && addressBook.length === 0)) {
        return null;
    }

    return (
        <Grid container justifyContent="flex-start">
            <Grid item xs={12} sm={12} sx={{ my: 2 }}>
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="address_selector_label" sx={{ ml: 2 }}>
                        {inputLabel}
                    </InputLabel>
                    <Select
                        id="address_selector"
                        fullWidth
                        labelId="address_selector_label"
                        open={open}
                        onChange={handleSelectAddressOnChange}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        sx={{ whiteSpace: 'normal' }}
                        value={selectedAddressBookId}
                        variant="outlined"
                    >
                        {addressOptionsMarkup}
                    </Select>
                </FormControl>
                {selectedAddressBookId && (
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button
                                color="secondary"
                                data-testid="clear_address_book_selection_btn"
                                fullWidth
                                onClick={handleClearSelection}
                                size="large"
                                sx={{
                                    mt: 2,
                                    mr: 0,
                                    mb: 3,
                                    ml: 0
                                }}
                                variant="contained"
                            >
                                {clearButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
