/* eslint-disable complexity */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// import { useMutation } from '@tanstack/react-query';
// import { calculateBuyerFinancingCharge } from '@bladebinge/web-service-common/src/utils/calculate-buyer-financing-charge';
import {
    OrderShipmentHydratedGraph,
    PurchaseOfferCheckoutSteps,
    type PurchaseOfferCheckoutStepsType,
    type PurchaseOfferGraph,
    StripePaymentInfo,
    UiQueryCacheKey
} from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { useLoggedInUserPurchaseOfferCheckoutData } from '../../hooks/react-query/logged-in-user-hooks/use-loggged-in-user-purchase-offer-checkout-data';
import { useMe } from '../me/me-context';

// type PurchaseOfferCheckoutSteps =
//     | 'SELECT_LISTINGS_SHIPPING'
//     | 'SELECT_TRADE_LISTINGS_SHIPPING'
//     | 'MAKE_PAYMENT'
//     | 'SHIP_ITEMS'
//     | 'WAIT_TO_SHIP';

interface PurchaseOfferCheckoutContext {
    checkoutOfferCacheKey: UiQueryCacheKey | null;
    fetchPurchaseOfferDataError: Error | null;
    isFetchingPurchaseOfferData: boolean;
    isPurchaseOfferFetched: boolean;
    isPurchaseOfferDataPending: boolean;
    loggedInUserCompletedOfferSteps: Map<string, PurchaseOfferCheckoutStepsType>;
    loggedInUserRemainingOfferSteps: Map<string, PurchaseOfferCheckoutStepsType>;
    onCompleteStep: (stepKey: string, stepType: PurchaseOfferCheckoutStepsType) => void;
    // onUndoStep: (stepKey: string, stepType: PurchaseOfferCheckoutStepsType) => void;
    remoteUserCompletedOfferSteps: Map<string, PurchaseOfferCheckoutStepsType>;
    remoteUserRemainingOfferSteps: Map<string, PurchaseOfferCheckoutStepsType>;
    purchaseOfferId: string | null;
    purchaseOffer: PurchaseOfferGraph | null;
    setPurchaseOfferId: (purchaseOfferId: string) => void;
    settlementPaymentIntentData: Map<string, StripePaymentInfo>;
    updateSettlementPaymentIntentData: (settlementId: string, paymentInfo: StripePaymentInfo) => void;
    // updateShipmentTaxData: (cartType: 'listings' | 'trades', taxData: CartSalesTaxData) => void;
}

const purchaseOfferCheckoutContext = createContext<PurchaseOfferCheckoutContext>({
    checkoutOfferCacheKey: null,
    fetchPurchaseOfferDataError: null,
    isFetchingPurchaseOfferData: false,
    isPurchaseOfferDataPending: false,
    isPurchaseOfferFetched: false,
    loggedInUserCompletedOfferSteps: new Map(),
    loggedInUserRemainingOfferSteps: new Map(),
    onCompleteStep(stepKey: string, stepType: PurchaseOfferCheckoutStepsType) {},
    // onUndoStep: (stepKey: string, stepType: PurchaseOfferCheckoutStepsType) => {},
    remoteUserCompletedOfferSteps: new Map(),
    remoteUserRemainingOfferSteps: new Map(),
    purchaseOfferId: null,
    purchaseOffer: null,
    setPurchaseOfferId(id: string) {},
    settlementPaymentIntentData: new Map(),
    updateSettlementPaymentIntentData(settlementId: string, paymentInfo: StripePaymentInfo) {}
});

const { Provider } = purchaseOfferCheckoutContext;

export const PurchaseOfferCheckoutContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const router = useRouter();
    const { id: loggedInUserId } = useMe();

    const [checkoutOfferCacheKey, setCheckoutOfferCacheKey] = useState<UiQueryCacheKey | null>(null);
    const [purchaseOfferId, setPurchaseOfferId] = useState<string | null>(null);
    const [loggedInUserCompletedOfferSteps, setLoggedInUserCompletedOfferSteps] = useState<
        Map<string, PurchaseOfferCheckoutStepsType>
    >(new Map());
    const [loggedInUserRemainingOfferSteps, setLoggedInUserRemainingOfferSteps] = useState<
        Map<string, PurchaseOfferCheckoutStepsType>
    >(new Map());
    const [remoteUserCompletedOfferSteps, setRemoteUserCompletedOfferSteps] = useState<
        Map<string, PurchaseOfferCheckoutStepsType>
    >(new Map());
    const [remoteUserRemainingOfferSteps, setRemoteUserRemainingOfferSteps] = useState<
        Map<string, PurchaseOfferCheckoutStepsType>
    >(new Map());
    const [settlementPaymentIntentData, setSettlementPaymentIntentData] = useState<Map<string, StripePaymentInfo>>(
        new Map()
    );

    useEffect(() => {
        const { purchaseOfferId: routerPurchaseOfferId = null } = router.query as { purchaseOfferId?: string };
        if (routerPurchaseOfferId !== purchaseOfferId) {
            setPurchaseOfferId(routerPurchaseOfferId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);

    const {
        data: purchaseOffer = null,
        isFetching: isFetchingPurchaseOfferData,
        isPending: isPurchaseOfferDataPending,
        isFetched: isPurchaseOfferFetched,
        error: fetchPurchaseOfferDataError,
        refetch: refetchPurchaseOfferData
    } = useLoggedInUserPurchaseOfferCheckoutData({
        loggedInUserId,
        purchaseOfferId
    });

    useEffect(() => {
        refetchPurchaseOfferData();

        setCheckoutOfferCacheKey(
            loggedInUserId && purchaseOfferId
                ? uiCacheKeyBuilderMap.loggedInUserPurchaseOfferCheckoutData({ loggedInUserId, purchaseOfferId })
                : null
        );
    }, [loggedInUserId, purchaseOfferId, refetchPurchaseOfferData]);

    useEffect(() => {
        if (!purchaseOffer) {
            return;
        }

        const loggedInUserTasksResponsibility = new Map<string, PurchaseOfferCheckoutStepsType>();
        const loggedInUserCompletedTasks = new Map<string, PurchaseOfferCheckoutStepsType>();
        const remoteUserTasksResponsibility = new Map<string, PurchaseOfferCheckoutStepsType>();
        const remoteUserCompletedTasks = new Map<string, PurchaseOfferCheckoutStepsType>();

        const { listingOrder, tradeOrder, offeredByUserOfferCheckoutSettlement, offeredToUserOfferCheckoutSettlement } =
            purchaseOffer;
        const listingOrderShipments = (listingOrder?.orderShipments ?? []) as OrderShipmentHydratedGraph[];
        const offerHasListingsToShip = listingOrderShipments.length > 0;
        const listingBuyerId = listingOrder?.buyerId ?? null;
        const loggedInUserIsListingBuyer = listingBuyerId === loggedInUserId;
        const loggedInUserIsOfferedByUser = purchaseOffer.offeredByUserId === loggedInUserId;

        const tradeOrderShipments = (tradeOrder?.orderShipments ?? []) as OrderShipmentHydratedGraph[];
        const offerHasTradesToShip = tradeOrderShipments.length > 0;
        const tradeBuyerId = tradeOrder?.buyerId ?? null;
        const loggedInUserIsTradeBuyer = tradeBuyerId === loggedInUserId;

        listingOrderShipments.forEach(
            ({ id: orderShipmentId, hasSellerPaidShipping, vendorRateQuoteId }: OrderShipmentHydratedGraph) => {
                const hasSelectedRate = Boolean(vendorRateQuoteId);

                // if hasSelectedRate, step is complete
                if (hasSelectedRate) {
                    if (hasSellerPaidShipping) {
                        loggedInUserIsListingBuyer
                            ? remoteUserCompletedTasks.set(
                                  `${PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING}_${orderShipmentId}`,
                                  PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING
                              )
                            : loggedInUserCompletedTasks.set(
                                  `${PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING}_${orderShipmentId}`,
                                  PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING
                              );
                    } else {
                        loggedInUserIsListingBuyer
                            ? loggedInUserCompletedTasks.set(
                                  `${PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING}_${orderShipmentId}`,
                                  PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING
                              )
                            : remoteUserCompletedTasks.set(
                                  `${PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING}_${orderShipmentId}`,
                                  PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING
                              );
                    }
                } else if (hasSellerPaidShipping) {
                    loggedInUserIsListingBuyer
                        ? remoteUserTasksResponsibility.set(
                              `${PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING}_${orderShipmentId}`,
                              PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING
                          )
                        : loggedInUserTasksResponsibility.set(
                              `${PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING}_${orderShipmentId}`,
                              PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING
                          );
                } else {
                    loggedInUserIsListingBuyer
                        ? loggedInUserTasksResponsibility.set(
                              `${PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING}_${orderShipmentId}`,
                              PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING
                          )
                        : remoteUserTasksResponsibility.set(
                              `${PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING}_${orderShipmentId}`,
                              PurchaseOfferCheckoutSteps.SELECT_LISTINGS_SHIPPING
                          );
                }
            }
        );

        tradeOrderShipments.forEach(
            ({ id: orderShipmentId, hasSellerPaidShipping, vendorRateQuoteId }: OrderShipmentHydratedGraph) => {
                // confirm that vendorRateQuoteId is selected, will not be purchased/have a vendorTransactionId until after payments are made
                const hasSelectedRate = Boolean(vendorRateQuoteId);

                // if hasSelectedRate, step is complete
                if (hasSelectedRate) {
                    if (hasSellerPaidShipping) {
                        loggedInUserIsTradeBuyer
                            ? remoteUserCompletedOfferSteps.set(
                                  `${PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING}_${orderShipmentId}`,
                                  PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING
                              )
                            : loggedInUserCompletedOfferSteps.set(
                                  `${PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING}_${orderShipmentId}`,
                                  PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING
                              );
                    } else {
                        loggedInUserIsTradeBuyer
                            ? loggedInUserCompletedOfferSteps.set(
                                  `${PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING}_${orderShipmentId}`,
                                  PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING
                              )
                            : remoteUserCompletedOfferSteps.set(
                                  `${PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING}_${orderShipmentId}`,
                                  PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING
                              );
                    }
                } else if (hasSellerPaidShipping) {
                    loggedInUserIsTradeBuyer
                        ? remoteUserTasksResponsibility.set(
                              `${PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING}_${orderShipmentId}`,
                              PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING
                          )
                        : loggedInUserTasksResponsibility.set(
                              `${PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING}_${orderShipmentId}`,
                              PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING
                          );
                } else {
                    loggedInUserIsTradeBuyer
                        ? loggedInUserTasksResponsibility.set(
                              `${PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING}_${orderShipmentId}`,
                              PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING
                          )
                        : remoteUserTasksResponsibility.set(
                              `${PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING}_${orderShipmentId}`,
                              PurchaseOfferCheckoutSteps.SELECT_TRADE_LISTINGS_SHIPPING
                          );
                }
            }
        );

        const offeredByUserOwesSettlementPayment = Boolean(
            offeredByUserOfferCheckoutSettlement?.incomingUserBalanceDue &&
                offeredByUserOfferCheckoutSettlement?.incomingUserBalanceDue > 0
        );
        const offeredToUserOwesSettlementPayment = Boolean(
            offeredToUserOfferCheckoutSettlement?.incomingUserBalanceDue &&
                offeredToUserOfferCheckoutSettlement?.incomingUserBalanceDue > 0
        );
        const COMPLETED_STATUSES = ['COMPLETED', 'NOT_APPLICABLE'];
        const byUserHasSettledPayment = COMPLETED_STATUSES.includes(
            offeredByUserOfferCheckoutSettlement?.incomingPaymentStatus ?? ''
        );
        const toUserHasSettledPayment = COMPLETED_STATUSES.includes(
            offeredToUserOfferCheckoutSettlement?.incomingPaymentStatus ?? ''
        );

        if (offeredByUserOwesSettlementPayment && !byUserHasSettledPayment) {
            loggedInUserIsOfferedByUser
                ? loggedInUserTasksResponsibility.set(
                      `${PurchaseOfferCheckoutSteps.MAKE_PAYMENT}_${offeredByUserOfferCheckoutSettlement?.id}`,
                      PurchaseOfferCheckoutSteps.MAKE_PAYMENT
                  )
                : remoteUserTasksResponsibility.set(
                      `${PurchaseOfferCheckoutSteps.MAKE_PAYMENT}_${offeredByUserOfferCheckoutSettlement?.id}`,
                      PurchaseOfferCheckoutSteps.MAKE_PAYMENT
                  );
        }

        if (!offeredByUserOwesSettlementPayment || byUserHasSettledPayment) {
            loggedInUserIsOfferedByUser
                ? loggedInUserCompletedOfferSteps.set(
                      `${PurchaseOfferCheckoutSteps.MAKE_PAYMENT}_${offeredByUserOfferCheckoutSettlement?.id}`,
                      PurchaseOfferCheckoutSteps.MAKE_PAYMENT
                  )
                : remoteUserCompletedOfferSteps.set(
                      `${PurchaseOfferCheckoutSteps.MAKE_PAYMENT}_${offeredByUserOfferCheckoutSettlement?.id}`,
                      PurchaseOfferCheckoutSteps.MAKE_PAYMENT
                  );
        }

        if (offeredToUserOwesSettlementPayment && !toUserHasSettledPayment) {
            loggedInUserIsOfferedByUser
                ? remoteUserTasksResponsibility.set(
                      `${PurchaseOfferCheckoutSteps.MAKE_PAYMENT}_${offeredByUserOfferCheckoutSettlement?.id}`,
                      PurchaseOfferCheckoutSteps.MAKE_PAYMENT
                  )
                : loggedInUserTasksResponsibility.set(
                      `${PurchaseOfferCheckoutSteps.MAKE_PAYMENT}_${offeredByUserOfferCheckoutSettlement?.id}`,
                      PurchaseOfferCheckoutSteps.MAKE_PAYMENT
                  );
        }

        if (!offeredToUserOwesSettlementPayment || toUserHasSettledPayment) {
            loggedInUserIsOfferedByUser
                ? remoteUserCompletedOfferSteps.set(
                      `${PurchaseOfferCheckoutSteps.MAKE_PAYMENT}_${offeredByUserOfferCheckoutSettlement?.id}`,
                      PurchaseOfferCheckoutSteps.MAKE_PAYMENT
                  )
                : loggedInUserCompletedOfferSteps.set(
                      `${PurchaseOfferCheckoutSteps.MAKE_PAYMENT}_${offeredByUserOfferCheckoutSettlement?.id}`,
                      PurchaseOfferCheckoutSteps.MAKE_PAYMENT
                  );
        }

        if (byUserHasSettledPayment && toUserHasSettledPayment) {
            loggedInUserIsOfferedByUser && tradeOrderShipments.length > 0
                ? loggedInUserTasksResponsibility.set(
                      PurchaseOfferCheckoutSteps.SHIP_ITEMS,
                      PurchaseOfferCheckoutSteps.SHIP_ITEMS
                  )
                : remoteUserTasksResponsibility.set(
                      PurchaseOfferCheckoutSteps.SHIP_ITEMS,
                      PurchaseOfferCheckoutSteps.SHIP_ITEMS
                  );
        }

        if (!byUserHasSettledPayment || !toUserHasSettledPayment) {
            if (offerHasListingsToShip) {
                loggedInUserIsListingBuyer
                    ? remoteUserTasksResponsibility.set(
                          PurchaseOfferCheckoutSteps.WAIT_TO_SHIP,
                          PurchaseOfferCheckoutSteps.WAIT_TO_SHIP
                      )
                    : loggedInUserTasksResponsibility.set(
                          PurchaseOfferCheckoutSteps.WAIT_TO_SHIP,
                          PurchaseOfferCheckoutSteps.WAIT_TO_SHIP
                      );
            }

            if (offerHasTradesToShip) {
                loggedInUserIsTradeBuyer
                    ? remoteUserTasksResponsibility.set(
                          PurchaseOfferCheckoutSteps.WAIT_TO_SHIP,
                          PurchaseOfferCheckoutSteps.WAIT_TO_SHIP
                      )
                    : loggedInUserTasksResponsibility.set(
                          PurchaseOfferCheckoutSteps.WAIT_TO_SHIP,
                          PurchaseOfferCheckoutSteps.WAIT_TO_SHIP
                      );
            }
        }

        if (toUserHasSettledPayment && byUserHasSettledPayment) {
            if (offerHasTradesToShip) {
                loggedInUserIsTradeBuyer
                    ? remoteUserTasksResponsibility.set(
                          PurchaseOfferCheckoutSteps.SHIP_ITEMS,
                          PurchaseOfferCheckoutSteps.SHIP_ITEMS
                      )
                    : loggedInUserTasksResponsibility.set(
                          PurchaseOfferCheckoutSteps.SHIP_ITEMS,
                          PurchaseOfferCheckoutSteps.SHIP_ITEMS
                      );
            }

            if (offerHasListingsToShip) {
                loggedInUserIsListingBuyer
                    ? remoteUserTasksResponsibility.set(
                          PurchaseOfferCheckoutSteps.SHIP_ITEMS,
                          PurchaseOfferCheckoutSteps.SHIP_ITEMS
                      )
                    : loggedInUserTasksResponsibility.set(
                          PurchaseOfferCheckoutSteps.SHIP_ITEMS,
                          PurchaseOfferCheckoutSteps.SHIP_ITEMS
                      );
            }
        }

        setLoggedInUserRemainingOfferSteps(loggedInUserTasksResponsibility);
        setLoggedInUserCompletedOfferSteps(loggedInUserCompletedTasks);
        setRemoteUserRemainingOfferSteps(remoteUserTasksResponsibility);
        setRemoteUserCompletedOfferSteps(remoteUserCompletedTasks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUserId, purchaseOffer]);

    const onCompleteStep = (stepKey: string, stepType: PurchaseOfferCheckoutStepsType) => {
        const remainingStepToDelete = `${stepType}_${stepKey}`;
        if (loggedInUserRemainingOfferSteps.has(remainingStepToDelete)) {
            loggedInUserRemainingOfferSteps.delete(remainingStepToDelete);
            loggedInUserCompletedOfferSteps.set(remainingStepToDelete, stepType);
            setLoggedInUserRemainingOfferSteps(new Map(loggedInUserRemainingOfferSteps.entries()));
        }

        refetchPurchaseOfferData();
    };

    const updateSettlementPaymentIntentData = (settlementId: string, paymentInfo: StripePaymentInfo) => {
        const existingPaymentInfoDataCloneForUpdate = new Map(settlementPaymentIntentData.entries());
        existingPaymentInfoDataCloneForUpdate.set(settlementId, paymentInfo);
        setSettlementPaymentIntentData(existingPaymentInfoDataCloneForUpdate);
    };

    return (
        <Provider
            value={{
                checkoutOfferCacheKey,
                fetchPurchaseOfferDataError,
                isFetchingPurchaseOfferData,
                isPurchaseOfferDataPending,
                isPurchaseOfferFetched,
                loggedInUserCompletedOfferSteps,
                loggedInUserRemainingOfferSteps,
                onCompleteStep,
                purchaseOffer,
                purchaseOfferId,
                setPurchaseOfferId,
                remoteUserRemainingOfferSteps,
                remoteUserCompletedOfferSteps,
                settlementPaymentIntentData,
                updateSettlementPaymentIntentData
            }}
        >
            {children}
        </Provider>
    );
};

export const usePurchaseOfferCheckoutContext = () => useContext(purchaseOfferCheckoutContext);
