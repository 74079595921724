import React, { SyntheticEvent, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import FlagIcon from '@mui/icons-material/Flag';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FlaggableEntityTypes, ModerationFlagWithHydratedRelatedEntities } from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { useMe } from '../../context/me/me-context';
import { ModerationFlagForm } from '../FormComponents/ModerationFlagForm';
import { StyledModalCancelIcon, StyledModalPaper } from '../styled-shared';
import { deleteModerationFlagRequest } from '../../server/api-proxy/ui-mutation-fns/moderation-flags';
import { useUserModerationFlags } from '../../hooks/use-user-moderation-flags';

export const FlaggingIconButton = ({
    className = '',
    flaggedEntityId,
    flaggedEntityOwnerId,
    flaggedEntityType,
    size = 'small'
}: {
    readonly className?: string;
    readonly flaggedEntityId: string;
    readonly flaggedEntityOwnerId?: string;
    readonly flaggedEntityType: FlaggableEntityTypes;
    readonly size?: 'small' | 'medium' | 'large';
}) => {
    const { t } = useTranslation();
    const { id: flaggedByUserId } = useMe();
    const queryClient = useQueryClient();

    const { data: flags, error: userFlagsError } = useUserModerationFlags({
        userId: flaggedByUserId
    });

    const [isFlaggingFormModalOpen, setIsFlaggingFormModalOpen] = useState<boolean>(false);
    const existingFlagId = useMemo(() => {
        if (!Array.isArray(flags)) {
            return '';
        }

        const existingFlag = flags.find(
            (flag: ModerationFlagWithHydratedRelatedEntities) => flag.flaggedEntityId === flaggedEntityId
        );
        return existingFlag ? existingFlag.id : '';
    }, [flaggedEntityId, flags]);

    const deleteFlagMutation = useMutation({
        mutationFn: deleteModerationFlagRequest,
        onSuccess() {
            queryClient.setQueryData(
                uiCacheKeyBuilderMap.loggedInUserModerationFlagsSubmitted({
                    loggedInUserId: flaggedByUserId
                }),
                (oldData: ModerationFlagWithHydratedRelatedEntities[] | undefined) =>
                    oldData?.filter((item) => item.id !== existingFlagId)
            );
        }
    });
    const { isPending: isDeletingFlag, error: deleteFlagError, reset: resetDeleteFlagMutation } = deleteFlagMutation;

    const handleFlagClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        setIsFlaggingFormModalOpen(true);
    };

    const handleModalCloseClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        setIsFlaggingFormModalOpen(false);
    };

    const flagRemovalHandler = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (!existingFlagId || !flaggedByUserId) {
            return;
        }

        deleteFlagMutation.mutate({ flaggedByUserId, moderationFlagId: existingFlagId });
    };

    const closeModal = () => setIsFlaggingFormModalOpen(false);

    if (!flaggedByUserId || !flaggedEntityId || !flaggedEntityType) {
        return null;
    }

    // no need to flag your own items
    if (flaggedEntityOwnerId === flaggedByUserId || flaggedEntityId === flaggedByUserId) {
        return null;
    }

    const formModal = (
        <Modal
            aria-label={t('common:moderation_flags.flagging_form')}
            open={isFlaggingFormModalOpen}
            onClose={handleModalCloseClick}
            closeAfterTransition
            components={{
                Backdrop
            }}
        >
            <StyledModalPaper>
                <StyledModalCancelIcon onClick={handleModalCloseClick} />
                <Grid container spacing={2} justifyContent="center">
                    <Grid item container>
                        <Grid item xs={12}>
                            <ModerationFlagForm
                                flaggedByUserId={flaggedByUserId}
                                flaggedEntityId={flaggedEntityId}
                                flaggedEntityOwnerId={flaggedEntityOwnerId}
                                flaggedEntityType={flaggedEntityType}
                                onClose={closeModal}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </StyledModalPaper>
        </Modal>
    );

    const submitButtonLabel = existingFlagId
        ? t(`common:moderation_flags.unflagging_button_labels.${flaggedEntityType}`)
        : t(`common:moderation_flags.flagging_button_labels.${flaggedEntityType}`);

    const submitHandler = existingFlagId ? flagRemovalHandler : handleFlagClick;
    const errorCloseHandler = (e: SyntheticEvent) => {
        e.stopPropagation();
        resetDeleteFlagMutation();
    };

    return (
        <IconButton
            className={className}
            color={existingFlagId ? 'error' : 'inherit'}
            disabled={isDeletingFlag}
            aria-label={submitButtonLabel}
            onClick={submitHandler}
            title={submitButtonLabel}
            size={size}
        >
            <>
                {userFlagsError && (
                    <Alert severity="error">
                        <Typography variant="body2" component="div">
                            {userFlagsError?.message}
                        </Typography>
                    </Alert>
                )}
                {deleteFlagError && (
                    <Alert severity="error" onClose={errorCloseHandler}>
                        <Typography variant="body2" component="div">
                            {deleteFlagError?.message}
                        </Typography>
                    </Alert>
                )}
                <FlagIcon sx={{ fontSize: 'inherit' }} />
                {formModal}
            </>
        </IconButton>
    );
};
