import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { displayCurrency } from '@bladebinge/web-service-common/src/utils/display-currency';
import { ListingDisplayPrice } from '../../listing/ListingDisplayPrice';

export const OfferTotals = ({
    includeDetailedBreakdown = true,
    showPrices = true,
    showOriginalTotal = false,
    originalTotal,
    originalTradeTotal,
    offerTotal,
    offerTradeTotal = 0,
    offerTotalVariant = 'h5',
    originalTotalVariant = 'body2',
    tradeTotalVariant = 'body2',
    showDiscount = false
}: {
    readonly includeDetailedBreakdown?: boolean;
    readonly showOriginalTotal?: boolean;
    readonly showPrices?: boolean;
    readonly originalTotal: number;
    readonly originalTradeTotal: number;
    readonly offerTotal: number;
    readonly offerTradeTotal?: number;
    readonly originalTotalVariant?: 'body1' | 'body2' | 'caption';
    readonly offerTotalVariant?: 'h5' | 'h6' | 'body1' | 'body2';
    readonly tradeTotalVariant?: 'h5' | 'h6' | 'body1' | 'body2';
    readonly showDiscount?: boolean;
}) => {
    const { t } = useTranslation();

    const offerDiscountText = useMemo(() => {
        if (!showDiscount || !originalTotal || !offerTotal) {
            return '-';
        }

        const currencyDiff = displayCurrency(originalTotal - offerTotal);
        const percentage = ((originalTotal - offerTotal) / originalTotal) * 100;

        if (percentage === 0) {
            return '';
        }

        const percentString = `${percentage.toFixed(2)}%`;
        return percentage < 0 ? `+ ${percentString} (${currencyDiff})` : `- ${percentString} (${currencyDiff})`;
    }, [showDiscount, originalTotal, offerTotal]);

    const tradeDiscountText = useMemo(() => {
        if (!showDiscount || !originalTradeTotal || !offerTradeTotal) {
            return '-';
        }

        const currencyDiff = displayCurrency(originalTradeTotal - offerTradeTotal);
        const percentage = ((originalTradeTotal - offerTradeTotal) / originalTradeTotal) * 100;

        if (percentage === 0) {
            return '';
        }

        const percentString = `${percentage.toFixed(2)}%`;
        return percentage < 0 ? `+ ${percentString} (${currencyDiff})` : `- ${percentString} (${currencyDiff})`;
    }, [showDiscount, originalTradeTotal, offerTradeTotal]);

    const offerOrRequestTotal = useMemo(() => offerTotal - offerTradeTotal, [offerTotal, offerTradeTotal]);

    return (
        <Grid item container xs={12}>
            {includeDetailedBreakdown && (
                <>
                    {showOriginalTotal && (
                        <Grid item xs={12}>
                            <Typography
                                variant={originalTotalVariant}
                                component="div"
                                sx={{
                                    m: 0,
                                    p: 0,
                                    textAlign: 'right',
                                    color: 'contrastText'
                                }}
                            >
                                {t('common:purchase_offer.original_total')}
                                {showPrices ? (
                                    <ListingDisplayPrice
                                        price={originalTotal}
                                        color="inherit"
                                        sx={{ display: 'inline', ml: 2 }}
                                    />
                                ) : (
                                    ' - '
                                )}
                            </Typography>
                        </Grid>
                    )}
                    {showDiscount && offerDiscountText && (
                        <Grid item xs={12}>
                            <Typography
                                variant={originalTotalVariant}
                                component="div"
                                sx={{ m: 0, p: 0, textAlign: 'right' }}
                            >
                                {offerDiscountText}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={{ m: 0, p: 0, textAlign: 'right', fontWeight: 'bold' }}
                        >
                            {t('common:purchase_offer.user_offered_you')}
                            <ListingDisplayPrice price={offerTotal} color="inherit" sx={{ display: 'inline', ml: 2 }} />
                        </Typography>
                    </Grid>
                </>
            )}
            {offerTradeTotal !== 0 && (
                <>
                    <Grid item xs={12}>
                        <Typography
                            variant={tradeTotalVariant}
                            component="div"
                            sx={{
                                m: 0,
                                p: 0,
                                textAlign: 'right'
                            }}
                        >
                            {t('common:purchase_offer.trade_value')}
                            {showPrices ? (
                                <ListingDisplayPrice price={-1 * offerTradeTotal} sx={{ display: 'inline', ml: 2 }} />
                            ) : (
                                ' - '
                            )}
                        </Typography>
                    </Grid>
                    {showDiscount && tradeDiscountText && (
                        <Grid item xs={12}>
                            <Typography
                                variant={tradeTotalVariant}
                                component="div"
                                sx={{ m: 0, p: 0, textAlign: 'right' }}
                            >
                                {tradeDiscountText}
                            </Typography>
                        </Grid>
                    )}
                </>
            )}
            <Grid
                item
                xs={12}
                sx={{
                    borderTopColor: 'divider',
                    borderTopSize: '1px',
                    borderTopStyle: 'solid'
                }}
            >
                <Typography variant={offerTotalVariant} component="div" sx={{ m: 0, p: 0, textAlign: 'right' }}>
                    {offerOrRequestTotal > 0
                        ? t('common:purchase_offer.offer_total')
                        : t('common:purchase_offer.requested_total')}
                    {showPrices ? (
                        <ListingDisplayPrice
                            color="info.main"
                            price={offerOrRequestTotal}
                            sx={{ display: 'inline', ml: 2 }}
                        />
                    ) : (
                        ' - '
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
};
