import { type ParsedUrlQuery } from 'querystring';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { ProfileTypes, SearchFilter, SearchFilterType, UserProfileEntity } from '@bladebinge/types';
import { usePrevious } from '../../hooks/use-previous';
import { ORDERED_SEARCH_FILTER_CLASS_HEADINGS } from '../../utils/constants';
import { groupFiltersByFilterProperty } from '../../utils/search-filters/group-filters-by-filter-property';
import { updateNextRouterQuery } from '../../utils/update-next-router-query';
import { useUserPreferences } from '../user-preferences/user-preferences-context';
import { useSearchFiltersQuery } from '../../hooks/react-query/use-search-filters-query';
import { AUTO_TAGGING_STOP_WORDS } from './auto-tagging-stop-words';

export type TokenizedFilterMappings = {
    knives: { [token: string]: number[] };
    accessories: { [token: string]: number[] };
};

const OFFICIAL_BLADEBINGE_USERNAME = 'bladebinge';
const TOKENIZATION_EXCLUSION_SLUGS = ['knife_blade_length', 'price_range'];
const IGNORE_CHARS = /(\(|\)|-|\/|(n\/a))/g;
const safeToken = (token: string = '') => token.toLowerCase().replaceAll(IGNORE_CHARS, '');
type FilterMode = 'ANY' | 'ALL' | '';
const AccessoryFilterSlugsToTokenize = new Set<SearchFilterType>()
    .add('condition')
    .add('country_of_origin')
    .add('accessory_category')
    .add('accessory_brand')
    .add('accessory_material');

const KnifeFilterSlugsToTokenize = new Set<SearchFilterType>()
    .add('condition')
    .add('country_of_origin')
    .add('knife_blade_length')
    .add('knife_blade_steel_type')
    .add('knife_brand')
    .add('knife_category')
    .add('knife_deployment_method')
    .add('knife_handle_material')
    .add('knife_lock_type');

// Not sure how to reconcile types here but this should only be used for string or number values
const difference = (arrOne: unknown[], arrTwo: unknown[]): unknown[] =>
    arrOne.filter((x: unknown) => !arrTwo.includes(x));
const hasDifferenceBetweenArrays = (arrA: unknown[], arrB: unknown[]) => {
    const arrANotInArrB = difference(arrA, arrB);
    const arrBNotInArrA = difference(arrB, arrA);
    const hasArrDiff = arrANotInArrB.length > 0 || arrBNotInArrA.length > 0;
    return hasArrDiff;
};

interface SearchFilterContext {
    activeFilterDrawerSearch: string;
    browseOnlyUserProfiles: UserProfileEntity[];
    limitToProfileTypes: ProfileTypes[];
    clearFilters: () => void;
    clearSearch: (refetch?: boolean) => void;
    confirmedSearchValue: string;
    filterMode: FilterMode;
    filters: SearchFilter[];
    filtersByIdMap: { [id: number]: SearchFilter };
    findTokenizedSiteSearchFilters: (
        siteSearchString: string,
        listingCategory?: keyof TokenizedFilterMappings
    ) => number[];
    forceAnyMode: boolean;
    hasFilterContextSelections: boolean;
    isFilterContextDirty: boolean;
    isFilterDrawerOpen: boolean;
    OFFICIAL_BLADEBINGE_USERNAME: string;
    onlyShowBladebingeOfficialPostings: boolean;
    orderedFilterCategories: SearchFilter[][];
    performSearch: () => void;
    resetFilters: () => void;
    routerGETfilterIds: number[];
    routerGETusernameSearch: string;
    routerGETlimitToProfileTypes: ProfileTypes[];
    selectedDrawerFilterIds: number[];
    selectedDrawerFilterMode: FilterMode;
    setActiveFilterDrawerSearch: (searchString: string) => void;
    setBrowseOnlyUserProfiles: (userProfiles: UserProfileEntity[]) => void;
    setFilterMode: (mode: FilterMode) => void;
    setIsFilterDrawerOpen: (open: boolean) => void;
    setLimitToProfileTypes: (profileTypes: ProfileTypes[]) => void;
    setOnlyShowBladebingeOfficialPostings: (onlyBladebingeOfficial: boolean) => void;
    setSelectedDrawerFilterIds: (filterIds: number[]) => void;
    setSelectedDrawerFilterMode: (mode: FilterMode) => void;
    setSiteSearchUIInputValue: (userInput: string) => void;
    showMobileSearchBar: boolean;
    setShowMobileSearchBar: (show: boolean) => void;
    siteSearchUIInputValue: string;
    usernameQueryUIInputValue: string;
}

const searchFilterContext = createContext<SearchFilterContext>({
    activeFilterDrawerSearch: '',
    browseOnlyUserProfiles: [],
    limitToProfileTypes: [],
    clearFilters() {},
    clearSearch(refetch?: boolean) {},
    confirmedSearchValue: '',
    filterMode: '',
    filters: [],
    filtersByIdMap: {},
    findTokenizedSiteSearchFilters: (
        siteSearchString: string = '',
        listingCategory: keyof TokenizedFilterMappings = 'knives'
    ) => [],
    forceAnyMode: false,
    hasFilterContextSelections: false,
    isFilterContextDirty: false,
    isFilterDrawerOpen: false,
    OFFICIAL_BLADEBINGE_USERNAME,
    onlyShowBladebingeOfficialPostings: false,
    orderedFilterCategories: [],
    performSearch() {},
    resetFilters() {},
    routerGETfilterIds: [],
    routerGETusernameSearch: '',
    routerGETlimitToProfileTypes: [],
    selectedDrawerFilterIds: [],
    selectedDrawerFilterMode: '',
    setActiveFilterDrawerSearch(searchString: string = '') {},
    setBrowseOnlyUserProfiles(userProfiles: UserProfileEntity[]) {},
    setFilterMode(mode: FilterMode) {},
    setIsFilterDrawerOpen(open: boolean) {},
    setLimitToProfileTypes(profileTypes: ProfileTypes[]) {},
    setOnlyShowBladebingeOfficialPostings(onlyBladebingeOfficial: boolean) {},
    setSelectedDrawerFilterIds(filterIds: number[]) {},
    setSelectedDrawerFilterMode(mode: 'ANY' | 'ALL' | '') {},
    setShowMobileSearchBar(open: boolean) {},
    setSiteSearchUIInputValue(userInput: string) {},
    showMobileSearchBar: false,
    siteSearchUIInputValue: '',
    usernameQueryUIInputValue: ''
});

const { Provider } = searchFilterContext;

const getSelectedFiltersFromQuery = (queryParams: ParsedUrlQuery = {}) => {
    const { searchFilters = '' } = queryParams;
    const filtersArray = Array.isArray(searchFilters)
        ? searchFilters
        : decodeURIComponent(searchFilters as string).split(',');
    return filtersArray.filter(Boolean).map((idString: string) => Number.parseInt(idString, 10));
};

const getUsernameSearchFromQuery = (queryParams: ParsedUrlQuery = {}) => {
    const { username = '' } = queryParams;
    return Array.isArray(username) ? encodeURIComponent(username.join(',')) : username;
};

const getLimitToProfileTypesFromQuery = (queryParams: ParsedUrlQuery = {}) => {
    const { profileTypes = '' } = queryParams;
    const profileTypesArray = (
        Array.isArray(profileTypes) ? profileTypes : decodeURIComponent(profileTypes as string).split(',')
    ) as ProfileTypes[];
    return profileTypesArray.filter(Boolean) as ProfileTypes[];
};

const getFilterModeFromQuery = (queryParams: ParsedUrlQuery = {}): FilterMode => {
    const { filterMode } = queryParams;
    return filterMode === 'ALL' || filterMode === 'ANY' ? filterMode : '';
};

export const SearchFilterContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { query } = router;

    const { listingPagingLimit } = useUserPreferences();
    const { data: filters = [] } = useSearchFiltersQuery();

    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
    const [showMobileSearchBar, setShowMobileSearchBar] = useState<boolean>(false);

    const [forceAnyMode, setForceAnyMode] = useState<boolean>(true);
    const [filterMode, setFilterMode] = useState<FilterMode>(getFilterModeFromQuery(query));
    const [filtersByIdMap, setFiltersByIdMap] = useState<{ [id: number]: SearchFilter }>({});
    const usernameGETAtLoadTime = getUsernameSearchFromQuery(query);

    const [selectedDrawerFilterIds, setSelectedDrawerFilterIds] = useState<number[]>(
        getSelectedFiltersFromQuery(query)
    );
    const [selectedDrawerFilterMode, setSelectedDrawerFilterMode] = useState<FilterMode>(filterMode);
    const [routerGETfilterIds, setRouterGETfilterIds] = useState<number[]>(getSelectedFiltersFromQuery(query));
    const [routerGETlimitToProfileTypes, setRouterGETlimitToProfileTypes] = useState<ProfileTypes[]>(
        getLimitToProfileTypesFromQuery(query)
    );
    const [routerGETusernameSearch, setRouterGETusernameSearch] = useState<string>(usernameGETAtLoadTime);

    const [activeFilterDrawerSearch, setActiveFilterDrawerSearch] = useState<string>('');
    const debouncedSetActiveFilterDrawerSearch = debounce(setActiveFilterDrawerSearch, 300);

    const [browseOnlyUserProfiles, setBrowseOnlyUserProfiles] = useState<UserProfileEntity[]>([]);
    const [limitToProfileTypes, setLimitToProfileTypes] = useState<ProfileTypes[]>([]);
    const prevBrowseOnlyUserProfiles = usePrevious(browseOnlyUserProfiles);
    const [usernameQueryUIInputValue, setUsernameQueryUIInputValue] = useState<string>('');
    const prevUsernameQueryUIInputValue = usePrevious(usernameQueryUIInputValue);
    const [memoUsernameQueryUIInputValue, setMemoUsernameQueryUIInputValue] = useState<string>('');

    // Management of global site search field
    const [confirmedSearchValue, setConfirmedSearchValue] = React.useState('');
    const [siteSearchUIInputValue, setSiteSearchUIInputValue] = React.useState('');

    const [onlyBladebingePostsShown, setOnlyBladebingePostsShown] = useState<boolean>(
        usernameGETAtLoadTime === OFFICIAL_BLADEBINGE_USERNAME
    );

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const { q: activeSearchQuery = '' } = query;
        const activeSearch = Array.isArray(activeSearchQuery) ? activeSearchQuery.join('') : activeSearchQuery;

        if (!activeSearch) {
            return;
        }

        setConfirmedSearchValue(activeSearch);
        setSiteSearchUIInputValue(activeSearch);
    }, [query?.q]);

    useEffect(() => {
        // map filters by id after load for faster loopkup table
        const filtersById = filters.reduce<{ [id: number]: SearchFilter }>((acc, filter) => {
            if (filter?.id) {
                acc[filter.id] = filter;
            }

            return acc;
        }, {});

        setFiltersByIdMap(filtersById);
    }, [filters, setFiltersByIdMap]);

    useEffect(() => {
        const filterQueryMode = getFilterModeFromQuery(query);
        if (filterMode !== filterQueryMode) {
            setFilterMode(filterQueryMode);
        }

        const updatedRouterGETfilterIds = getSelectedFiltersFromQuery(query);
        setRouterGETfilterIds(updatedRouterGETfilterIds);

        const updatedRouterGETusernameSearch = getUsernameSearchFromQuery(query);
        setRouterGETusernameSearch(updatedRouterGETusernameSearch);

        const updateRouterGETlimitProfileTypes = getLimitToProfileTypesFromQuery(query);
        setRouterGETlimitToProfileTypes(updateRouterGETlimitProfileTypes);
    }, [query]);

    useEffect(() => {
        if (onlyBladebingePostsShown) {
            setMemoUsernameQueryUIInputValue(usernameQueryUIInputValue);
            setUsernameQueryUIInputValue(OFFICIAL_BLADEBINGE_USERNAME);
            return;
        }

        if (prevUsernameQueryUIInputValue && prevUsernameQueryUIInputValue === OFFICIAL_BLADEBINGE_USERNAME) {
            setUsernameQueryUIInputValue(memoUsernameQueryUIInputValue);
            setMemoUsernameQueryUIInputValue('');
        }
    }, [onlyBladebingePostsShown]);

    useEffect(() => {
        if (onlyBladebingePostsShown) {
            return;
        }

        const selectedUsernames = encodeURIComponent(
            browseOnlyUserProfiles
                .map(({ username }) => username)
                .filter((username) => username !== OFFICIAL_BLADEBINGE_USERNAME)
                .join(',')
        );

        const activeUsernames = prevBrowseOnlyUserProfiles ? selectedUsernames : routerGETusernameSearch;

        setUsernameQueryUIInputValue(activeUsernames);
    }, [browseOnlyUserProfiles, onlyBladebingePostsShown, routerGETusernameSearch]);

    useEffect(() => {
        setLimitToProfileTypes(routerGETlimitToProfileTypes);
    }, [routerGETlimitToProfileTypes, setLimitToProfileTypes]);

    // force 'any' mode if a user selects multiple same-category entries
    // otherwise the search makes no sense
    useEffect(() => {
        const drawerSelectedFilterCategories = selectedDrawerFilterIds.map(
            (id: number) => filtersByIdMap?.[id]?.filterTypeSlug
        );
        // set logic determines if any categories have more than one entry
        setForceAnyMode(new Set(drawerSelectedFilterCategories).size !== drawerSelectedFilterCategories.length);
    }, [selectedDrawerFilterIds]);

    const orderedFilterCategories: SearchFilter[][] = useMemo(() => {
        const categorizedFilters = groupFiltersByFilterProperty({
            filters: filters ?? [],
            groupOnProperty: 'filterClass'
        });
        return ORDERED_SEARCH_FILTER_CLASS_HEADINGS.map<SearchFilter[]>(
            (filterCategory) => (categorizedFilters[filterCategory] ?? []) as SearchFilter[]
        );
    }, [filters]);

    const tokenizedFilterMappings: TokenizedFilterMappings = useMemo(
        () =>
            filters.reduce<{
                knives: { [token: string]: number[] };
                accessories: { [token: string]: number[] };
            }>(
                (mapping, filter) => {
                    const { filterTypeSlug, id: filterId, slug } = filter;
                    if (TOKENIZATION_EXCLUSION_SLUGS.includes(filterTypeSlug)) {
                        return mapping;
                    }

                    const nameRaw = t(`common:filters.${filterTypeSlug}.${slug}`);
                    const name = safeToken(nameRaw);

                    const nameTokens = name.split(' ');
                    nameTokens.forEach((token) => {
                        if (!token || AUTO_TAGGING_STOP_WORDS.has(token)) {
                            return;
                        }

                        if (KnifeFilterSlugsToTokenize.has(filterTypeSlug)) {
                            const previousKnivesTokenMapping = mapping.knives?.[token] ? mapping.knives?.[token] : [];
                            mapping.knives[token] = previousKnivesTokenMapping.concat(filterId);
                        }

                        if (AccessoryFilterSlugsToTokenize.has(filterTypeSlug)) {
                            const previousAccessoryTokenMapping = mapping.accessories?.[token]
                                ? mapping.accessories?.[token]
                                : [];
                            mapping.accessories[token] = previousAccessoryTokenMapping.concat(filterId);
                        }
                    });
                    return mapping;
                },
                {
                    knives: {},
                    accessories: {}
                }
            ),
        [filters]
    );

    const findTokenizedSiteSearchFilters = useMemo(
        () =>
            (searchString: string = '', listingCategory: keyof TokenizedFilterMappings = 'knives') => {
                const allRawTokensArray = searchString
                    .trim()
                    .replaceAll(/\r\n|\r|\n/g, ' ')
                    .split(' ');
                // dedupe tokens
                const searchStringTokens = Array.from(new Set(allRawTokensArray));

                const allIds = searchStringTokens.reduce<number[]>((locatedIds, tokenRaw = '') => {
                    const token = safeToken(tokenRaw);

                    const relatedFilterIds = tokenizedFilterMappings?.[listingCategory]?.[token] ?? [];

                    if (relatedFilterIds.length > 0) {
                        return relatedFilterIds.concat(locatedIds);
                    }

                    return locatedIds;
                }, []);

                // de-dupe ids
                return Array.from(new Set(allIds));
            },
        [tokenizedFilterMappings]
    );

    useEffect(() => {
        if (!isFilterDrawerOpen) {
            setSelectedDrawerFilterMode('');
        }
    }, [isFilterDrawerOpen]);

    const formState = useMemo(() => {
        const isDrawerFilterSelectionListChanged = hasDifferenceBetweenArrays(
            selectedDrawerFilterIds,
            routerGETfilterIds
        );

        const isSelectedProfileListChanged = hasDifferenceBetweenArrays(
            browseOnlyUserProfiles.map(({ username }) => username),
            (prevBrowseOnlyUserProfiles ?? []).map(({ username }) => username)
        );

        const isFilterModeChanged =
            (selectedDrawerFilterMode && selectedDrawerFilterMode !== getFilterModeFromQuery(query)) ||
            getFilterModeFromQuery(query) !== filterMode;

        const isOnlySearchBladebingeChanged =
            (onlyBladebingePostsShown && routerGETusernameSearch !== OFFICIAL_BLADEBINGE_USERNAME) ||
            (!onlyBladebingePostsShown && routerGETusernameSearch === OFFICIAL_BLADEBINGE_USERNAME);
        const isUsernameSearchChanged = routerGETusernameSearch !== usernameQueryUIInputValue;

        const isLimitToProfileTypesChanged = hasDifferenceBetweenArrays(
            routerGETlimitToProfileTypes,
            limitToProfileTypes
        );

        const hasFilterIdSelections = selectedDrawerFilterIds.length > 0;
        const hasUserProfileSelections = browseOnlyUserProfiles.length > 0;
        const hasProfileSearchInput = Boolean(usernameQueryUIInputValue);

        const formData = {
            isDirty:
                isDrawerFilterSelectionListChanged ||
                isOnlySearchBladebingeChanged ||
                isFilterModeChanged ||
                isUsernameSearchChanged ||
                isLimitToProfileTypesChanged ||
                isSelectedProfileListChanged,
            hasSelections:
                onlyBladebingePostsShown || hasFilterIdSelections || hasUserProfileSelections || hasProfileSearchInput
        };

        return formData;
    }, [
        browseOnlyUserProfiles,
        filterMode,
        isFilterDrawerOpen,
        limitToProfileTypes,
        onlyBladebingePostsShown,
        selectedDrawerFilterIds,
        selectedDrawerFilterMode,
        usernameQueryUIInputValue,
        router,
        routerGETusernameSearch,
        routerGETlimitToProfileTypes
    ]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const updateBrowseResults = () => {
        const removeKeys = [
            ...(limitToProfileTypes.length > 0 ? [] : ['profileTypes']),
            ...(siteSearchUIInputValue ? ['offset'] : ['q', 'offset']),
            ...(usernameQueryUIInputValue ? [] : ['username']),
            ...(selectedDrawerFilterIds.length > 0 ? [] : ['searchFilters', 'filterMode'])
        ];

        updateNextRouterQuery({
            router,
            pathname: '/browse',
            shallow: false,
            updatedQuery: {
                ...(siteSearchUIInputValue ? { q: siteSearchUIInputValue } : {}),
                ...(filterMode ? { filterMode } : {}),
                ...(selectedDrawerFilterIds.length > 0
                    ? { searchFilters: encodeURIComponent(selectedDrawerFilterIds.map((id) => `${id}`).join(',')) }
                    : {}),
                ...(limitToProfileTypes.length > 0
                    ? { profileTypes: encodeURIComponent(limitToProfileTypes.join(',')) }
                    : {}),
                ...(usernameQueryUIInputValue ? { username: usernameQueryUIInputValue } : {}),
                ...(listingPagingLimit ? { limit: `${listingPagingLimit}` } : {})
            },
            removeKeys
        });
    };

    const performSearch = () => {
        setConfirmedSearchValue(siteSearchUIInputValue);
        updateBrowseResults();
    };

    const clearSearch = useCallback((refetch?: boolean) => {
        setSiteSearchUIInputValue('');
        setConfirmedSearchValue('');
        if (refetch) {
            updateBrowseResults();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearFilters = () => {
        setSelectedDrawerFilterIds([]);
        setBrowseOnlyUserProfiles([]);
        setLimitToProfileTypes([]);
        setUsernameQueryUIInputValue('');
        setOnlyBladebingePostsShown(false);
        setFilterMode('');
    };

    const resetFilters = () => {
        setSelectedDrawerFilterIds(routerGETfilterIds);
        setUsernameQueryUIInputValue(routerGETusernameSearch);
        setBrowseOnlyUserProfiles(
            browseOnlyUserProfiles.filter(({ username }) => routerGETusernameSearch.includes(username))
        );
        setLimitToProfileTypes(
            limitToProfileTypes.filter((profileType) => routerGETlimitToProfileTypes.includes(profileType))
        );
        setOnlyBladebingePostsShown(routerGETusernameSearch === OFFICIAL_BLADEBINGE_USERNAME);
    };

    return (
        <Provider
            value={{
                activeFilterDrawerSearch,
                browseOnlyUserProfiles,
                limitToProfileTypes,
                clearFilters,
                clearSearch,
                confirmedSearchValue,
                orderedFilterCategories,
                hasFilterContextSelections: formState.hasSelections,
                isFilterContextDirty: formState.isDirty,
                filterMode: forceAnyMode ? 'ANY' : filterMode,
                filters,
                filtersByIdMap,
                findTokenizedSiteSearchFilters,
                forceAnyMode,
                isFilterDrawerOpen,
                OFFICIAL_BLADEBINGE_USERNAME,
                onlyShowBladebingeOfficialPostings: onlyBladebingePostsShown,
                performSearch,
                resetFilters,
                routerGETfilterIds,
                routerGETlimitToProfileTypes,
                routerGETusernameSearch,
                selectedDrawerFilterIds,
                selectedDrawerFilterMode,
                setActiveFilterDrawerSearch: debouncedSetActiveFilterDrawerSearch,
                setBrowseOnlyUserProfiles,
                setLimitToProfileTypes,
                setFilterMode,
                setIsFilterDrawerOpen,
                setOnlyShowBladebingeOfficialPostings: setOnlyBladebingePostsShown,
                setSelectedDrawerFilterIds,
                setSelectedDrawerFilterMode,
                setShowMobileSearchBar,
                setSiteSearchUIInputValue,
                showMobileSearchBar,
                siteSearchUIInputValue,
                usernameQueryUIInputValue
            }}
        >
            {children}
        </Provider>
    );
};

export const useSearchFilterContext = () => useContext(searchFilterContext);
