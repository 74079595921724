import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { StyledDialogImageZoomCancelIcon, StyleDialogPaper, StyledZoomedImage } from '../styled-shared';

interface ZoomImage {
    alt: string;
    src: string;
}

export const ZoomedImageDialog = ({
    initialImage,
    onClearModal,
    sortedItems = []
}: {
    readonly initialImage?: ZoomImage;
    readonly onClearModal: () => void;
    readonly sortedItems?: ZoomImage[];
}) => {
    const { t } = useTranslation();
    const [detailModalImage, setDetailModalImage] = useState<ZoomImage | undefined>(initialImage);

    const hasNavigation = useMemo(() => sortedItems.length > 1, [sortedItems]);

    const displayedImageIndex = useMemo(
        () => sortedItems.findIndex((item) => item.src === detailModalImage?.src),
        [detailModalImage, sortedItems]
    );

    const loadNextImage = useCallback(() => {
        const nextIndex = displayedImageIndex === sortedItems.length - 1 ? 0 : displayedImageIndex + 1;
        const { src, alt } = sortedItems[nextIndex];
        setDetailModalImage({ src, alt });
    }, [displayedImageIndex, sortedItems]);

    const loadPrevImage = useCallback(() => {
        const prevIndex = displayedImageIndex === 0 ? sortedItems.length - 1 : displayedImageIndex - 1;
        const { src, alt } = sortedItems[prevIndex];
        setDetailModalImage({ src, alt });
    }, [displayedImageIndex, sortedItems]);

    useEffect(() => {
        setDetailModalImage(initialImage);
    }, [initialImage]);

    // add two buttons to navigate between images using prev and next functions
    return (
        <Dialog
            aria-label={t('common:listing.zoom_image')}
            components={{
                Backdrop
            }}
            PaperComponent={StyleDialogPaper}
            fullWidth
            maxWidth="xl"
            open={Boolean(detailModalImage)}
            onClose={onClearModal}
        >
            <DialogContent sx={{ pt: '20px', px: 0 }}>
                <StyledDialogImageZoomCancelIcon onClick={onClearModal} color="error" sx={{ zIndex: 1 }} />
                <Grid container justifyContent="center" spacing={1} sx={{ position: 'relative' }}>
                    <Grid item sx={{ position: 'relative' }}>
                        <StyledZoomedImage {...detailModalImage} />
                    </Grid>
                    {hasNavigation && (
                        <Grid
                            container
                            justifyContent="space-between"
                            item
                            sx={{ position: 'absolute', top: '25vh', zIndex: 1 }}
                        >
                            <Grid item xs={2} sm={2}>
                                <Box
                                    sx={{
                                        textAlign: {
                                            xs: 'right',
                                            sm: 'center'
                                        }
                                    }}
                                >
                                    <IconButton
                                        aria-label={t('common:images.view_previous_image')}
                                        color="secondary"
                                        edge="start"
                                        id="previous_zoom_image_btn"
                                        onClick={loadPrevImage}
                                        title={t('common:images.view_previous_image')}
                                        size="large"
                                        sx={{
                                            backgroundColor: 'rgba(0,0,0,0.35)',
                                            '&:active': {
                                                backgroundColor: 'rgba(0,0,0,0.65)'
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0,0,0,0.65)'
                                            }
                                        }}
                                    >
                                        <NavigateBeforeIcon
                                            sx={{
                                                fontSize: {
                                                    xs: '2rem',
                                                    sm: '3rem'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <IconButton
                                        aria-label={t('common:images.view_next_image')}
                                        color="secondary"
                                        edge="start"
                                        id="next_zoom_image_btn"
                                        onClick={loadNextImage}
                                        title={t('common:images.view_next_image')}
                                        size="large"
                                        sx={{
                                            backgroundColor: 'rgba(0,0,0,0.35)',
                                            '&:active': {
                                                backgroundColor: 'rgba(0,0,0,0.35)'
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0,0,0,0.65)'
                                            }
                                        }}
                                    >
                                        <NavigateNextIcon
                                            sx={{
                                                fontSize: {
                                                    xs: '2rem',
                                                    sm: '3rem'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
