import type { ParsedUrlQuery } from 'querystring';
import { MessageGraph, PaginatedObjectionListResults, UiQueryCacheKey } from '@bladebinge/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { userMessageThreadRequest } from '../../../server/api-proxy/messages';
import { defaultGetNextPageParam, defaultGetPreviousPageParam } from '../infinite-query-defaults';

const DEFAULT_MESSAGE_THREAD_POLL_INTERVAL = 2500;

const threadMessageFetchFn =
    ({
        cacheKey,
        query,
        parentMessageId,
        threadUserIds,
        userId
    }: {
        cacheKey: UiQueryCacheKey;
        query: ParsedUrlQuery;
        parentMessageId: string;
        threadUserIds: string[];
        userId?: string | null;
    }) =>
    async ({
        pageParam
    }: {
        pageParam?: { _offset?: string };
    }): Promise<PaginatedObjectionListResults<MessageGraph>> => {
        if (!userId || !pageParam || pageParam?._offset === undefined) {
            throw new Error('User ID is required to fetch messages');
        }

        const messageThreadData = await userMessageThreadRequest({
            queryParams: {
                ...query,
                offset: `${pageParam._offset}`
            },
            parentMessageId,
            threadUserIds,
            userId
        });
        const { error: { message: loadMessagesThreadError = '' } = {} } = messageThreadData;

        if (loadMessagesThreadError) {
            throw new Error(loadMessagesThreadError);
        }

        return {
            ...messageThreadData,
            cacheKey
        };
    };

export const useMessageThread = ({
    hasMounted = false,
    pollInterval = DEFAULT_MESSAGE_THREAD_POLL_INTERVAL,
    query,
    parentMessageId,
    threadUserIds,
    threadMessageCreatorId,
    userId
}: {
    hasMounted: boolean;
    pollInterval?: number;
    query: ParsedUrlQuery;
    parentMessageId: string;
    threadUserIds: string[];
    threadMessageCreatorId: string;
    userId?: string | null;
}) => {
    const cacheKey = uiCacheKeyBuilderMap.messageThread({
        query,
        userId,
        parentMessageId,
        threadMessageCreatorId,
        threadUserIds
    });

    return useInfiniteQuery({
        enabled: hasMounted && Boolean(userId),
        queryKey: cacheKey,
        queryFn: threadMessageFetchFn({
            cacheKey,
            query,
            parentMessageId,
            threadUserIds,
            userId
        }),
        refetchInterval: pollInterval,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        retry: false,
        // message thread sort order is by time DESC so next/previous page logic feels reversed
        getPreviousPageParam: defaultGetPreviousPageParam,
        getNextPageParam: defaultGetNextPageParam,
        staleTime: 0,
        initialPageParam: { _offset: query?._offset === undefined ? '0' : `${query._offset}` }
    });
};
