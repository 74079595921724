import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const getUserSubmittedModerationFlags = async ({ userId }: { userId: string }) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserSubmittedModerationFlags.build({
                userId
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};
