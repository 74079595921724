import type { ParsedUrlQuery } from 'querystring';
import {
    ListingGraph,
    PaginatedObjectionListResults,
    SortingQueryParamMapping,
    UiQueryCacheKey
} from '@bladebinge/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getProfileIdListingsByPage } from '../../server/api-proxy/user-profiles';
import { defaultGetNextPageParam, defaultGetPreviousPageParam } from './infinite-query-defaults';

const profileListingsByProfileIdFetchFn =
    ({
        cacheKey,
        query,
        userProfileId
    }: {
        cacheKey: UiQueryCacheKey;
        query: ParsedUrlQuery;
        userProfileId?: string | null;
    }) =>
    async ({
        pageParam
    }: {
        pageParam?: { _offset?: string };
    }): Promise<PaginatedObjectionListResults<ListingGraph>> => {
        if (!userProfileId || !pageParam || pageParam?._offset === undefined) {
            throw new Error('userProfileId are required to fetch profile listings by profile id');
        }

        const response = await getProfileIdListingsByPage({
            queryParams: {
                ...query,
                offset: `${pageParam._offset}`
            },
            userProfileId
        });

        const { error: { message: loadError = '' } = {} } = response;

        if (loadError) {
            throw new Error(loadError);
        }

        return {
            ...response,
            cacheKey
        };
    };

export const useProfileListingsByProfileId = ({
    hasMounted = false,
    q,
    query,
    signal,
    userProfileId,
    userListingSortByData = {
        sortBy: '',
        sortDirection: '',
        uiSelectorValue: ''
    }
}: {
    hasMounted: boolean;
    q?: string;
    query: ParsedUrlQuery;
    signal?: AbortSignal;
    userProfileId?: string | null;
    userListingSortByData?: SortingQueryParamMapping;
}) => {
    const { uiSelectorValue, ...sortByData } = userListingSortByData;
    const fetchParams = {
        ...query,
        ...sortByData,
        q
    };

    const cacheKey = uiCacheKeyBuilderMap.userProfileListingsByProfileId({ query: fetchParams, userProfileId });

    return useInfiniteQuery({
        enabled: hasMounted && Boolean(userProfileId),
        initialPageParam: query?.offset === undefined ? { _offset: '0' } : { _offset: `${query.offset}` },
        queryKey: cacheKey,
        queryFn: profileListingsByProfileIdFetchFn({ cacheKey, query: fetchParams, userProfileId }),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        getNextPageParam: defaultGetNextPageParam,
        getPreviousPageParam: defaultGetPreviousPageParam
    });
};
