import { Image, ImageType, PaginatedObjectionListResults, UiQueryCacheKey } from '@bladebinge/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getUserImageUploads } from '../../../server/api-proxy/user-image-upload';
import { defaultGetNextPageParam, defaultGetPreviousPageParam } from '../infinite-query-defaults';

const userImageUploadsFetchFn =
    ({
        cacheKey,
        limit,
        imageType,
        userId
    }: {
        cacheKey: UiQueryCacheKey;
        limit: number;
        imageType: ImageType;
        userId: string;
    }) =>
    async ({ pageParam }: { pageParam?: { _offset?: string } }): Promise<PaginatedObjectionListResults<Image>> => {
        if (!userId || !imageType || !pageParam || pageParam?._offset === undefined) {
            throw new Error('User ID and imageType are required to fetch user image uploads');
        }

        const response = await getUserImageUploads({
            queryParams: {
                limit: `${limit}`,
                imageType,
                offset: `${pageParam._offset}`
            },
            userId
        });
        const { error: { message: loadError = '' } = {} } = response;

        if (loadError) {
            throw new Error(loadError);
        }

        return {
            ...response,
            cacheKey
        };
    };

export const useUserImageUploads = ({
    hasMounted = false,
    imageType = 'messageImages' as ImageType,
    limit,
    offset = 0,
    signal,
    userId
}: {
    hasMounted: boolean;
    imageType: ImageType;
    limit: number;
    offset?: number;
    signal?: AbortSignal;
    userId: string;
}) => {
    const cacheKey = uiCacheKeyBuilderMap.userImageUploads({
        loggedInUserId: userId,
        imageType,
        uploadLimit: limit
    });

    return useInfiniteQuery({
        enabled: hasMounted && Boolean(userId),
        initialPageParam: offset === undefined ? { _offset: '0' } : { _offset: `${offset}` },
        queryKey: cacheKey,
        queryFn: userImageUploadsFetchFn({ cacheKey, limit, imageType, userId }),
        placeholderData: (prev) => prev,
        getNextPageParam: defaultGetNextPageParam,
        getPreviousPageParam: defaultGetPreviousPageParam
    });
};
