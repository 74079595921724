import React from 'react';
import { useTranslation } from 'next-i18next';
import TextField from '@mui/material/TextField';

export const CustomPhoneNumber = React.forwardRef<HTMLInputElement>((props, ref) => {
    const { t } = useTranslation();

    return (
        <TextField
            InputLabelProps={{ shrink: true }}
            {...props}
            inputRef={ref}
            fullWidth
            label={t('common:address_form.phone')}
            margin="normal"
            placeholder={t('common:address_form.phone')}
            variant="outlined"
        />
    );
});
