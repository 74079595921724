import React, { createContext, useContext, useState } from 'react';
import { Image, ImageType } from '@bladebinge/types';
import { MESSAGING_IMAGE_ATTACHMENT_UPLOAD_COUNT_LIMIT } from '@bladebinge/web-service-common/src/constants/uploads';
import { useMe } from '../me/me-context';
import { useUserImageUploads } from '../../hooks/react-query/logged-in-user-hooks/use-user-image-uploads';
import { useHasMounted } from '../../hooks/use-has-mounted';

interface MessageImagesContext {
    detachedImageUploads: Image[];
    loadingUserImageUploads: boolean;
    loadingUserImageUploadsError?: Error | null;
    imageType: ImageType;
    imageUploadCountLimit: number;
    setDetachedImageUploads: (images: Image[]) => void;
    updateImagesPagingLimit?: (newLimit: number | null) => void;
    userImageUploadsCurrentPageItems: Image[];
    userImageUploadsTotal: number;
    userImageUploadsLimit: number;
}

const messageImagesContext = createContext<MessageImagesContext>({
    detachedImageUploads: [],
    imageType: 'messageImages' as ImageType,
    imageUploadCountLimit: MESSAGING_IMAGE_ATTACHMENT_UPLOAD_COUNT_LIMIT,
    loadingUserImageUploads: false,
    loadingUserImageUploadsError: null,
    setDetachedImageUploads(images: Image[]) {},
    updateImagesPagingLimit(newLimit: number | null) {},
    userImageUploadsCurrentPageItems: [],
    userImageUploadsTotal: 0,
    userImageUploadsLimit: 0
});

const { Provider, Consumer } = messageImagesContext;

export const MessageImagesContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const { id: loggedInUserId } = useMe();
    const hasMounted = useHasMounted();

    const [imagesPerPage, setImagesPerPage] = useState<number>(8);

    const updateImagesPagingLimit = (limit: number | null) => {
        setImagesPerPage(limit ?? 8);
    };

    const {
        data,
        error: loadingUserImageUploadsError,
        isPending: loadingUserImageUploads
    } = useUserImageUploads({
        hasMounted,
        limit: imagesPerPage,
        imageType: 'messageImages' as ImageType,
        userId: loggedInUserId as string
    });

    const lastPageFetched = Array.isArray(data?.pages) ? data.pages.length - 1 : 0;
    const currentPageData = data?.pages?.[lastPageFetched] ?? { result: [], _limit: 25, _total: 0 };
    const {
        _total: userImageUploadsTotal = 0,
        _limit: userImageUploadsLimit = 0,
        result: userImageUploadsCurrentPageItems = []
    } = currentPageData;

    const [detachedImages, setDetachedImages] = useState<Image[]>([]);

    return (
        <Provider
            value={{
                detachedImageUploads: detachedImages,
                imageUploadCountLimit: MESSAGING_IMAGE_ATTACHMENT_UPLOAD_COUNT_LIMIT,
                imageType: 'messageImages' as ImageType,
                loadingUserImageUploadsError,
                loadingUserImageUploads,
                setDetachedImageUploads: setDetachedImages,
                updateImagesPagingLimit,
                userImageUploadsCurrentPageItems,
                userImageUploadsLimit,
                userImageUploadsTotal
            }}
        >
            {children}
        </Provider>
    );
};

export const MessageImagesContextConsumer = Consumer;

export const useMessageImagesContext = () => useContext(messageImagesContext);
