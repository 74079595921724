import React, { useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { ImageMessageAttachment } from '@bladebinge/types/src/api/models/image';
import Box from '@mui/material/Box';
import { displayDate } from '../../../utils/display-date';
import { useMe } from '../../../context/me/me-context';
import { StyledMessageImageZoomTargetAnchor } from '../../styled-shared';
import { ZoomedImageDialog } from '../../atoms/ZoomedImageDialog';
import { FlaggingIconButton } from '../../atoms/FlaggingIconButton';

const StyledImageFlaggingIconButton = styled(FlaggingIconButton)(({ theme }) => ({
    position: 'absolute',
    fontSize: '1.25rem',
    zIndex: 1,
    top: 0,
    right: 0
}));

export const ZoomableMessageImages = ({
    align = 'left',
    createdAt,
    images = []
}: {
    readonly align?: 'left' | 'right';
    readonly createdAt?: string;
    readonly images: ImageMessageAttachment[];
}) => {
    const { t } = useTranslation();
    const { id: loggedInUser } = useMe();
    const hasImages = images.length > 0;

    const [detailModalImage, setDetailModalImage] = useState<ImageMessageAttachment | undefined>(undefined);
    const setDetailModalContent = (image: ImageMessageAttachment, e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setDetailModalImage(image);
    };

    const allImagesForZoomDialog = useMemo(
        () => images.map((image) => ({ alt: image.alt ?? '', src: getCdnUrl(image.url) })),
        [images]
    );

    if (!hasImages) {
        return null;
    }

    return (
        <>
            {createdAt && (
                <Typography
                    variant="caption"
                    component="div"
                    sx={{
                        my: 1,
                        ...(align === 'right' ? { textAlign: 'right' } : {})
                    }}
                >
                    {displayDate(createdAt)}
                </Typography>
            )}
            <Grid container justifyContent={align === 'right' ? 'flex-end' : 'flex-start'}>
                {images.map((image) => (
                    <Grid key={image.id} item xs={12} sm={6}>
                        <Box sx={{ position: 'relative' }}>
                            <StyledMessageImageZoomTargetAnchor
                                title={t('common:listing.zoom_image')}
                                href="#"
                                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => setDetailModalContent(image, e)}
                            >
                                <img
                                    alt={image.alt ?? ''}
                                    src={getCdnUrl(image.thumbnailUrl)}
                                    style={{
                                        maxWidth: '90%'
                                    }}
                                />
                            </StyledMessageImageZoomTargetAnchor>
                            {loggedInUser !== image.ownerId && (
                                <StyledImageFlaggingIconButton
                                    flaggedEntityType="IMAGE"
                                    flaggedEntityId={image.id}
                                    flaggedEntityOwnerId={image.ownerId}
                                />
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <ZoomedImageDialog
                initialImage={
                    detailModalImage
                        ? {
                              alt: detailModalImage?.alt ?? '',
                              src: getCdnUrl(detailModalImage?.thumbnailUrl ?? '')
                          }
                        : undefined
                }
                onClearModal={() => setDetailModalImage(undefined)}
                sortedItems={allImagesForZoomDialog}
            />
        </>
    );
};
