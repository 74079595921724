import { ModerationFlag, ModerationFlagPostJson, ModerationFlagWithHydratedRelatedEntities } from '@bladebinge/types';
import { PROXY_ROUTES } from '../ui-proxy-routes';
import { normalizedFetch } from '../utils/normalized-fetch';

export const createModerationFlagRequest = async (
    createModerationFlagJson: ModerationFlagPostJson
): Promise<ModerationFlagWithHydratedRelatedEntities> => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.createModerationFlag.build({
            userId: createModerationFlagJson?.flaggedByUserId
        }),
        options: {
            method: 'POST',
            body: JSON.stringify(createModerationFlagJson),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};

export const deleteModerationFlagRequest = async ({
    flaggedByUserId,
    moderationFlagId
}: {
    flaggedByUserId: string;
    moderationFlagId: string;
}): Promise<ModerationFlag> => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.deleteModerationFlag.build({
            moderationFlagId,
            userId: flaggedByUserId
        }),
        options: {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
