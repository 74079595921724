import { useQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { useMe } from '../../../context/me/me-context';
import { fetchUserProfileAddressBook } from '../../../server/api-proxy/user-profile-addresses';

const fetchUserProfileAddressBookQueryFn =
    ({ currentUserId, userProfileId }: { currentUserId: string; userProfileId?: string }) =>
    async () => {
        const response = await fetchUserProfileAddressBook({
            currentUserId,
            userProfileId
        });

        const { error: { message: asyncError = '' } = {} } = response as { error?: { message: string } };

        if (asyncError) {
            // we expect a 404 if the user does not have a referrer. Just return false on error
            throw new Error(asyncError);
        }

        return response;
    };

export const useUserAddressBook = () => {
    const { activeProfileId: userProfileId, id: loggedInUserId } = useMe();

    return useQuery({
        queryKey: uiCacheKeyBuilderMap.userProfileAddressBook({
            loggedInUserId,
            userProfileId
        }),
        queryFn: fetchUserProfileAddressBookQueryFn({
            currentUserId: loggedInUserId as string,
            userProfileId: userProfileId ? userProfileId : undefined
        }),
        placeholderData: (prev) => prev,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        enabled: Boolean(loggedInUserId)
    });
};
