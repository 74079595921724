import React from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { default as MuiRating } from '@mui/material/Rating';
import { getStarValueFromRating } from '../../utils/get-star-value-from-rating';

type AllowedSizes = 'small' | 'medium' | 'large';

export const AverageRating = ({
    averageRating,
    convertToStarRating = true,
    size
}: {
    readonly averageRating: number;
    readonly convertToStarRating?: boolean;
    readonly size: AllowedSizes;
}) => {
    if (averageRating < 0) {
        return (
            <MuiRating
                color="inherit"
                disabled
                emptyIcon={
                    <StarBorderIcon
                        sx={(theme) => ({
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            fontSize: 'inherit'
                        })}
                    />
                }
                readOnly
                value={0}
                precision={0.5}
                size={size}
            />
        );
    }

    return (
        <MuiRating
            readOnly
            emptyIcon={
                <StarBorderIcon
                    sx={(theme) => ({
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        fontSize: 'inherit'
                    })}
                />
            }
            value={convertToStarRating ? getStarValueFromRating(averageRating) : averageRating}
            precision={0.5}
            size={size}
        />
    );
};
