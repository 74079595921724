import { Address } from '@bladebinge/types';

export const getAddressCacheKey = (address: Address, isGuestAddress?: boolean) => {
    const { city, company, country, name, street1, street2, street3, phone, state, zip } = address;

    return [
        name ? name.toLowerCase() : '',
        company ? company.toLowerCase() : '',
        city ? city.toLowerCase() : '',
        street1 ? street1.toLowerCase() : '',
        street2 ? street2.toLowerCase() : '',
        street3 ? street3.toLowerCase() : '',
        state ? state.toLowerCase() : '',
        zip ? zip.toLowerCase() : '',
        country ? country.toLowerCase() : '',
        phone ? phone.toLowerCase() : '',
        isGuestAddress ? 'isGuestAddress' : ''
    ]
        .filter(Boolean)
        .join('_')
        .replaceAll(/\s/g, '_')
        .trim();
};
