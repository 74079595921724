import React from 'react';
import { useTranslation } from 'next-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import { MessageGraph } from '@bladebinge/types';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { Typography } from '@mui/material';
import { StyledDefaultTextNoUnderlineAvatarLink } from '../../../styled-shared';

export const MessageCreatorAvatar = ({ message }: { readonly message: MessageGraph }) => {
    const { t } = useTranslation();
    const avatar = message?.creatorUserProfile?.avatar;
    const username = message?.creatorUserProfile?.username;

    const avatarSx = {
        m: 0,
        height: {
            xs: '28px',
            sm: '45px'
        },
        width: {
            xs: '28px',
            sm: '45px'
        },
        top: {
            xs: '5px'
        },
        mx: {
            xs: 0,
            sm: 1,
            md: 2
        },
        ...(avatar ? {} : { color: 'secondary' })
    };

    const avatarComponent = avatar ? (
        <Avatar alt={username} src={getCdnUrl(avatar?.thumbnailUrl)} sx={avatarSx} />
    ) : (
        <AccountCircleIcon sx={avatarSx} />
    );

    return (
        <StyledDefaultTextNoUnderlineAvatarLink
            href={`/profiles/${username}`}
            title={t('common:user_profile.view_profile_for', { username })}
        >
            {avatarComponent}
            <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                {username}
            </Typography>
        </StyledDefaultTextNoUnderlineAvatarLink>
    );
};
