import { ErrorMessage } from '@hookform/error-message';
import Link from 'next/link';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import { grey, yellow } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import '@mui/system';
import styled from '@emotion/styled';

const ERROR_TEXT_HEX = '#CC0000';
const ERROR_BORDER_HEX = '#F24167';

export const StyledAutoHeightModalPaper = styled(Paper)(({ theme }) => ({
    width: '75vw',
    padding: theme.spacing(1.5),
    overflow: 'scroll',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    margin: '1em auto',
    maxWidth: '800px',
    [theme.breakpoints.down('sm')]: {
        width: '90vw',
        height: '90vh'
    },
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.divider
})) as typeof Paper;

export const StyledAlignRightDivContent = styled('div')(({ theme }) => ({
    textAlign: 'right'
}));

export const StyledBankLogo = styled('img')(({ theme }) => ({
    height: '30px',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.75)
}));

export const StyledBankName = styled('span')(({ theme }) => ({
    fontWeight: 'bold',
    fontStyle: 'italic',
    marginRight: theme.spacing(0.75)
}));

export const StyledBulletGrid = styled(Grid)(({ theme }) => ({
    margin: theme.spacing(3, 0),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    padding: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'primary',
    borderRadius: '4px',
    [theme.breakpoints.only('xs')]: {
        margin: theme.spacing(1, 0)
    }
})) as typeof Grid;

export const StyledCartItemList = styled('ul')(({ theme }) => ({
    width: '100%',
    textIndent: '0',
    padding: theme.spacing(1),
    margin: '0'
}));

export const StyledCenteredFormDivContent = styled('div')(({ theme }) => ({
    margin: theme.spacing(0.5, 0),
    textAlign: 'center'
}));

export const StyledCenteredFormHeading = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(2, 0, 1),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        fontSize: '1.5em',
        margin: theme.spacing(0.75, 0, 0.5)
    }
})) as typeof Typography;

export const StyledCheckboxWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

export const StyledCurrentStageButton = styled(Button)(({ theme }) => ({
    fontStyle: 'italic',
    fontWeight: 'bold',
    cursor: 'default',
    minWidth: '105px',
    textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
        minWidth: '75px',
        fontSize: '.8em'
    }
})) as typeof Button;

export const StyledDataCategory = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: theme.typography.caption.fontSize
})) as typeof Typography;

export const StyledDefaultTextNoUnderlineLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit'
}));

export const StyledDefaultTextNoUnderlineAvatarLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    textAlign: 'center',
    display: 'inline-block'
}));

export const StyledDialogImageZoomCancelIcon = styled(CancelIcon)(({ theme }) => ({
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(0.75),
    right: theme.spacing(0.75),
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
        fontSize: '1.35em'
    }
})) as unknown as typeof CancelIcon;

export const StyleDialogPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: 'rgba(0,0,0,0.5)',
    backgroundImage: 'none',
    padding: 0,
    margin: '0 !important',
    maxWidth: '100vw !important',
    width: '100vw !important'
})) as typeof Paper;

export const StyledDropdownPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3, 2),
    boxShadow: theme.shadows[3]
})) as typeof Paper;

export const StyledListingGridUl = styled('ul')(({ theme }) => ({
    padding: 0,
    margin: 0
}));

export const UnstyledListingGridLi = styled('li')(({ theme }) => ({
    listStyle: 'none'
}));

export const StyledListingGridLi = styled('li')(({ theme }) => ({
    width: '100%',
    maxWidth: '100vw',
    borderBottom: `1px solid ${theme.palette.divider}`,
    listStyle: 'none',
    padding: `${theme.spacing(1)}`,
    margin: '0 auto'
}));

export const StyledNeedsLabelWarningInline = styled('div')(({ theme }) => ({
    display: 'inline-block',
    backgroundColor: yellow.A200,
    color: grey[900],
    padding: theme.spacing(0.75),
    border: `1px solid ${grey[900]}`,
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center'
}));

export const StyledNoUnderlineLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none'
}));

export const StyledTextLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        textDecoration: 'none'
    }
}));

export const StyledTextOverflowGrid = styled(Grid)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})) as typeof Grid;

export const StyledTitleAnchor = styled(Link)(({ theme }) => ({
    fontWeight: 'bold',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
        color: theme.palette.text.secondary,
        cursor: 'pointer'
    }
}));

export const StyledFormMessagingWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    color: ERROR_TEXT_HEX,
    marginBottom: theme.spacing(1)
}));

export const StyledFullWidthForm = styled('form')(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(1)
}));

export const StyledHighlightTag = styled('strong')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '110%',
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: 'rgba(224, 224, 224, 0.45)',
    margin: theme.spacing(0, 0.25, 0, 0)
}));

export const StyledHookFormErrorMessage = styled(ErrorMessage)(({ theme }) => ({
    color: ERROR_TEXT_HEX,
    fontWeight: 'bold',
    margin: theme.spacing(0.5, 0, 2),
    borderLeft: `3px solid ${ERROR_BORDER_HEX}`,
    paddingLeft: theme.spacing(2)
}));

export const StyledFormLegalContentWrap = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    width: '90vw',
    margin: '1em auto',
    maxWidth: '800px',
    padding: theme.spacing(1, 2)
}));

export const StyledUiThumb = styled('img')(({ theme }) => ({
    marginRight: theme.spacing(1),
    maxWidth: '100%',
    height: 'auto',
    width: '85px',
    [theme.breakpoints.down('xs')]: {
        width: '65px'
    }
}));

export const StyledEditGridThumb = styled('img')(({ theme }) => ({
    marginRight: theme.spacing(1),
    width: '96%',
    height: 'auto'
}));

export const StyledReferenceIdLink = styled(Link)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1rem',
    textDecoration: 'underline',
    color: `${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
        color: `${theme.palette.primary.dark}`
    }
}));

export const StyleUploadImage = styled('img')(({ theme }) => ({
    width: '150px',
    [theme.breakpoints.down('sm')]: {
        width: '45px'
    }
}));

export const StyledSortableListingImage = styled('img')(({ theme }) => ({
    maxWidth: '150px',
    [theme.breakpoints.down('sm')]: {
        width: '90%'
    }
}));

export const StyledMessageImageZoomTargetAnchor = styled('a')(({ theme }) => ({
    position: 'relative',
    display: 'inline-block',
    backgroundColor: 'transparent',
    color: 'inherit',
    cursor: 'zoom-in',
    textDecoration: 'none',
    overflow: 'hidden',
    '& > img': {
        borderRadius: '4px',
        border: '4px solid',
        borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
        width: '94%',
        maxWidth: '250px'
    }
}));

export const StyledModalPaper = styled(Paper)(({ theme }) => ({
    width: '75vw',
    height: '85vh',
    padding: theme.spacing(1.5),
    overflow: 'scroll',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    margin: '1em auto',
    maxWidth: '800px',
    [theme.breakpoints.down('sm')]: {
        width: '90vw',
        height: '90vh'
    },
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.divider
})) as typeof Paper;

export const StyledNarrowModalPaper = styled(Paper)(({ theme }) => ({
    width: '85vw',
    minHeight: '35vh',
    maxHeight: '85vh',
    padding: theme.spacing(1.5),
    overflow: 'scroll',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '380px',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '380px'
    },
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.divider
})) as typeof Paper;

export const StyledWideModalPaper = styled(Paper)(({ theme }) => ({
    width: '85vw',
    height: '85vh',
    padding: theme.spacing(1.5),
    overflow: 'scroll',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    margin: '1em auto',
    [theme.breakpoints.down('sm')]: {
        width: '90vw',
        height: '90vh'
    },
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.divider
})) as typeof Paper;

export const StyledMarginYDiv = styled('div')(({ theme }) => ({
    margin: theme.spacing(2, 0)
}));

export const StyledPaddedPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1.5)
})) as typeof Paper;

export const StyledPrice = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.main
})) as typeof Typography;

export const StyledProductImage = styled('img')(({ theme }) => ({
    height: '100%',
    width: 'auto',
    marginRight: theme.spacing(1)
}));

export const StyledGridPaper = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1, 1, 2),
        maxWidth: '100%'
    }
})) as typeof Grid;

export const StyledModalCancelIcon = styled(CancelOutlined)(({ theme }) => ({
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(0.375),
    right: theme.spacing(0.375),
    fontWeight: 'bold',
    zIndex: 1
})) as unknown as typeof CancelOutlined;

export const StyledBulletedList = styled('ul')(({ theme }) => ({
    padding: 0,
    margin: 0,
    '& > li': {
        margin: theme.spacing(2, 0)
    }
}));

export const StyledMyItemList = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& > li': {
        margin: theme.spacing(2, 0)
    }
}));

export const StyledPrimaryAddressType = styled(Typography)(({ theme }) => ({
    display: 'block',
    fontStyle: 'italic',
    color: theme.palette.info.dark
})) as typeof Typography;

export const StyledTextLegibilitySection = styled('section')(({ theme }) => ({
    width: '90%',
    margin: '0 auto',
    marginTop: theme.spacing(4)
}));

export const StyledPlaceOrderButtonWrap = styled('li')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '98%',
    marginLeft: '1%'
}));

export const StyledStageButton = styled(Button)(({ theme }) => ({
    fontStyle: 'italic',
    minWidth: '105px',
    [theme.breakpoints.down('md')]: {
        minWidth: '75px',
        fontSize: '.8em'
    }
})) as typeof Button;

export const StyledTotalsLineItemWrap = styled('li')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '98%',
    marginLeft: '1%',
    borderBottom: `1px solid rgba(224, 224, 224, 0.15)`,
    '&:last-child': {
        borderBottom: 'none'
    },
    marginBottom: '0.5em'
}));

export const StyledZoomedImage = styled('img')(({ theme }) => ({
    marginTop: '12px',
    maxWidth: '96vw'
}));
