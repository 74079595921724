import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '@mui/material/Modal';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';
import { StyledFormLegalContentWrap, StyledModalCancelIcon } from '../styled-shared';
import { FlaggingGuidelines } from './FlaggingGuidelines';

export const FlaggingGuidelinesModal = () => {
    const { t } = useTranslation();
    const [isFlaggingModalOpen, setIsFlaggingModalOpen] = useState<boolean>(false);

    const toggleModal = () => setIsFlaggingModalOpen((previousOpenState) => !previousOpenState);

    return (
        <>
            <Button
                aria-label={t('common:moderation_flags.guidelines.heading')}
                onClick={toggleModal}
                title={t('common:moderation_flags.guidelines.heading')}
                color="inherit"
                sx={{
                    fontFamily: headingFont.style.fontFamily,
                    fontSize: 'inherit',
                    px: 0,
                    color: 'text.primary'
                }}
                variant="text"
            >
                {t('common:moderation_flags.guidelines_tooltip_text')}
                <HelpIcon sx={{ fontSize: 'inherit', mx: 0.25 }} />
            </Button>
            <Modal
                aria-label={t('common:moderation_flags.guidelines.heading')}
                open={isFlaggingModalOpen}
                onClose={() => setIsFlaggingModalOpen(false)}
                closeAfterTransition
                components={{
                    Backdrop
                }}
                sx={{ overflow: 'scroll' }}
            >
                <StyledFormLegalContentWrap>
                    <StyledModalCancelIcon onClick={() => setIsFlaggingModalOpen(false)} />
                    <FlaggingGuidelines />
                </StyledFormLegalContentWrap>
            </Modal>
        </>
    );
};
