import React from 'react';
import 'react-phone-number-input/style.css';
import Grid from '@mui/material/Grid';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { OfferTotals } from './OfferTotals';

export const CreateOfferFormTotals = ({
    includeDetailedBreakdown = true
}: {
    readonly includeDetailedBreakdown?: boolean;
}) => {
    const { originalTotal, offerTotal, offerTradeTotal, originalTradeTotal } = useMakeAnOfferContext();

    return (
        <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ borderBottomColor: 'text.main', borderBottomStyle: 'solid', borderBottomSize: '2px', pb: 1 }}
        >
            <OfferTotals
                includeDetailedBreakdown={includeDetailedBreakdown}
                originalTotal={originalTotal}
                originalTradeTotal={originalTradeTotal}
                offerTradeTotal={offerTradeTotal}
                offerTotal={offerTotal}
                showDiscount={false}
            />
        </Grid>
    );
};
