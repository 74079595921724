import React, { useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useMessageImagesContext } from '../../../context/image-uploads/message-images-context';
import { ImageGridItem } from './ImageGridItem';

export const DeatchedImageUploadsViewer = () => {
    const { t } = useTranslation();
    const { detachedImageUploads, setDetachedImageUploads } = useMessageImagesContext();

    // remove image with imageId = id from detachedImages
    const removeDetachedImage = useCallback(
        (imageId: string) => {
            const updatedDetachedImages = detachedImageUploads.filter((image) => image.id !== imageId);
            setDetachedImageUploads(updatedDetachedImages);
        },
        [detachedImageUploads, setDetachedImageUploads]
    );

    const detachedImagesMarkup = useMemo(
        () =>
            detachedImageUploads.map((image) => (
                <ImageGridItem key={image.id} image={image} onRemove={removeDetachedImage} />
            )),
        [detachedImageUploads, removeDetachedImage]
    );

    if (detachedImagesMarkup.length === 0) {
        return null;
    }

    return (
        <Grid item container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="caption" component="div" sx={{ textAlign: 'center', mb: 2.5 }}>
                    {t('common:messaging:attached_images', { count: detachedImageUploads.length })}
                </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
                {detachedImagesMarkup}
            </Grid>
        </Grid>
    );
};
