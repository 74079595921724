import { default as replaceExt } from 'replace-ext';

const REMOVE_CHARS_REGEX = /\s|-|\./g;
const LEADING_UNDERSCORE_REGEX = /^[_]*/g;
const MULTI_UNDERSCORE_REPLACE = /(_){2,}/g;
const TRAILING_UNDERSCORE_REGEX = /(_)*$/g;

export const renameImagePath = (path: string, prefix: string = ''): string => {
    const filename = prefix ? `${prefix}_${path}` : path;
    return replaceExt(
        filename
            .toLowerCase()
            .trim()
            .replaceAll(REMOVE_CHARS_REGEX, '_')
            .replaceAll(LEADING_UNDERSCORE_REGEX, '')
            .replaceAll(MULTI_UNDERSCORE_REPLACE, '_')
            .replaceAll(TRAILING_UNDERSCORE_REGEX, ''),
        ''
    );
};
