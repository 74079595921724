import React, { useId, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useProfileListingsByProfileId } from '../../../hooks/react-query/use-profile-listings-by-profile-id';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { ListingTile } from '../../listing/ListingTile';
import { NoResults } from '../../atoms/NoResults';
import { PaginationByLinks } from '../../atoms/PaginationByLinksButtons';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { useMe } from '../../../context/me/me-context';
import { GroupedListingsSearchAndSort } from '../GroupedListingsSearchAndSort';

export const CounterOfferAddMoreItemsSection = () => {
    const { t } = useTranslation();
    const ariaId = useId();
    const { activePurchaseOfferListingIds, activeProfileId } = useMe();
    const [manualListingPagingQuery, setManualListingPagingQuery] = useState<{
        limit: number;
        offset: number;
    }>({
        offset: 0,
        limit: 6
    });
    const [searchAndSortParams, setSearchAndSortParams] = useState({
        q: '',
        userListingSortByData: {
            sortBy: '',
            sortDirection: '',
            uiSelectorValue: ''
        }
    });

    const { addListingToOffer, offerListings, removeListingFromOffer } = useMakeAnOfferContext();

    const hasMounted = useHasMounted();

    const {
        data,
        error: loadListingsError,
        isLoading: loadingListings,
        refetch
    } = useProfileListingsByProfileId({
        userProfileId: activeProfileId,
        hasMounted,
        query: {
            limit: `${manualListingPagingQuery.limit}`,
            offset: `${manualListingPagingQuery.offset}`
        },
        ...searchAndSortParams
    });

    const lastPageFetched = Array.isArray(data?.pages) ? data.pages.length - 1 : 0;
    const currentPageData = data?.pages?.[lastPageFetched] ?? { result: [], _limit: 25, _total: 0, _links: undefined };
    const { _total = 0, result: currentPageItems = [], _links } = currentPageData;

    return (
        <Grid container item xs={12} sx={{ my: 1 }}>
            <Accordion sx={{ width: '100%', pl: 0, ml: 0 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`counter-offer-add-items-${ariaId}-content`}
                    id={`counter-offer-add-items-${ariaId}-header`}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="span" sx={{ py: 0, my: 0 }}>
                                {t('common:purchase_offer.add_more_items_to_counter_offer')}
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={11} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div" sx={{ my: 1 }}>
                                {t('common:purchase_offer.choose_items_to_add_to_counter_offer')}
                            </Typography>
                        </Grid>
                        {activeProfileId && (
                            <GroupedListingsSearchAndSort onChange={setSearchAndSortParams} groupId={activeProfileId} />
                        )}
                        {loadListingsError && (
                            <Grid item xs={12}>
                                <Alert severity="error" onClose={() => refetch()}>
                                    {loadListingsError.message}
                                </Alert>
                            </Grid>
                        )}
                        {loadingListings && (
                            <Grid item xs={12}>
                                <LoadingIndicator />
                            </Grid>
                        )}
                        {currentPageItems.length > 0 ? (
                            <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                {currentPageItems.map((listingGraph) => {
                                    const itemIsInOfferAlready = offerListings.has(listingGraph.id);
                                    return (
                                        <Grid
                                            key={listingGraph.id}
                                            item
                                            xs={4}
                                            sm={4}
                                            md={2}
                                            sx={{
                                                opacity: activePurchaseOfferListingIds.has(listingGraph.id) ? 0.5 : 1
                                            }}
                                        >
                                            <ListingTile
                                                iconActionButton={
                                                    <Fab
                                                        color={itemIsInOfferAlready ? 'error' : 'primary'}
                                                        disabled={activePurchaseOfferListingIds.has(listingGraph.id)}
                                                        onClick={() =>
                                                            itemIsInOfferAlready
                                                                ? removeListingFromOffer(listingGraph)
                                                                : addListingToOffer(listingGraph)
                                                        }
                                                        size="small"
                                                        sx={{
                                                            position: 'absolute',
                                                            zIndex: 1,
                                                            top: '-20px',
                                                            right: '-15px'
                                                        }}
                                                        title={
                                                            itemIsInOfferAlready
                                                                ? t('common:purchase_offer.remove_item_from_offer')
                                                                : t('common:purchase_offer.add_item_to_offer')
                                                        }
                                                    >
                                                        {itemIsInOfferAlready ? <DeleteIcon /> : <AddIcon />}
                                                    </Fab>
                                                }
                                                listing={listingGraph}
                                                showActionButtons={false}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : (
                            <NoResults />
                        )}
                        <Grid item xs={12} sx={{ mb: 5 }}>
                            <PaginationByLinks
                                currentPageItemsCount={currentPageItems.length}
                                defaultLimit={5}
                                handleSetPagingParams={setManualListingPagingQuery}
                                links={_links}
                                total={_total}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
