import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { getGlobalStaticFeatureEnablement } from '@bladebinge/web-service-common/src/feature-flags/get-global-static-feature-enablement';
import { DEFAULT_COMMISSION_RATES } from '@bladebinge/web-service-common/src/constants/commission-rates';
import { StyledTextLegibilitySection } from '../styled-shared';
import { useMe } from '../../context/me/me-context';

export const TradesHowDoesItWorkText = () => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();
    const isTradingGloballyEnabled = getGlobalStaticFeatureEnablement('EnableTrades', loggedInUserId) === true;

    return (
        <StyledTextLegibilitySection>
            <Typography component="div" variant="h4">
                {t('common:user_preferences.trading_feature.feature_title')}
            </Typography>
            <Typography variant="body1" component="div" sx={{ my: 2, fontSize: '1.1em', textDecoration: 'underline' }}>
                {t('common:user_preferences.trading_feature.how_does_it_work')}
            </Typography>

            <Typography variant="body1" paragraph>
                {t('common:user_preferences.trading_feature.trades_intro_paragraph_one')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('common:user_preferences.trading_feature.trades_intro_paragraph_two')}
            </Typography>

            <Typography variant="h5" sx={{ mt: 4 }}>
                {t('common:user_preferences.trading_feature.trade_with_confidence_heading')}
            </Typography>
            <Typography variant="body1" sx={{ my: 2, fontSize: '1.1em', textDecoration: 'underline' }}>
                {t('common:user_preferences.trading_feature.trade_safely_on_bladebinge')}
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" paragraph>
                        {t('common:user_preferences.trading_feature.trade_protections_info')}
                    </Typography>
                </li>
            </ul>

            <Typography variant="h5" sx={{ mt: 4 }}>
                {t('common:user_preferences.trading_feature.lower_fees_on_trades')}
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" paragraph>
                        {t('common:user_preferences.trading_feature.lower_your_processing_fees_paragraph_one', {
                            percentage: `${(DEFAULT_COMMISSION_RATES.sales * 100)?.toFixed(1)}%`
                        })}
                    </Typography>
                </li>
            </ul>

            <Typography variant="h5" sx={{ mt: 4 }}>
                {t('common:user_preferences.trading_feature.trading_rule_and_guidlines_heading')}
            </Typography>

            <Typography variant="body1" sx={{ my: 2, fontSize: '1.1em', textDecoration: 'underline' }}>
                {t('common:user_preferences.trading_feature.trade_item_eligibility')}
            </Typography>
            <Typography variant="body1">
                {t('common:user_preferences.trading_feature.your_items_are_trade_eligible_when')}
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" paragraph>
                        {t('common:user_preferences.trading_feature.eligibility_listing_age_rule')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" paragraph>
                        {t('common:user_preferences.trading_feature.eligibility_price_change_rule')}
                    </Typography>
                </li>
            </ul>

            <Typography variant="h5" sx={{ mt: 4 }}>
                {t('common:user_preferences.trading_feature.pricing_rules_heading')}
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" paragraph>
                        {t('common:user_preferences.trading_feature.fifty_percent_pricing_rule')}
                    </Typography>
                </li>
            </ul>

            <Typography variant="body1">
                {t('common:user_preferences.trading_feature.trade_and_offer_value_determination_heading')}
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" paragraph>
                        {t('common:user_preferences.trading_feature.bladebinge_trade_commission_value_explanation')}
                    </Typography>
                </li>
            </ul>

            <Typography variant="h5" sx={{ mt: 4 }}>
                {t('common:user_preferences.trading_feature.disabling_trading_heading')}
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" paragraph>
                        {isTradingGloballyEnabled
                            ? t(
                                  'common:user_preferences.trading_feature.offers_with_trading_feature_toggling_instruction'
                              )
                            : t('common:user_preferences.trading_feature.offers_feature_toggling_instruction')}
                    </Typography>
                </li>
            </ul>
        </StyledTextLegibilitySection>
    );
};
