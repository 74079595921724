import { PersistedUserPreferenceValues } from '@bladebinge/types';

interface RequiredInteractionUserPreferences {
    enableTradeOffersOption?: boolean;
    enableOffersOption?: boolean;
}

export const DEFAULT_USER_PREFERENCES: PersistedUserPreferenceValues & {
    enableFinancingPaymentOption: boolean;
    useListingAutoTagFilters: boolean;
} = {
    browsingPreferences: {
        listingPagingLimit: 25,
        listingViewStylePreference: 'grid'
    },
    favorites: {
        showSoldOutItems: true
    },
    communication: {
        activeEmailSubscriptions: {
            ALL_REMINDERS_OPT_OUT: false,
            BUYER_REVIEW: true,
            INACTIVE_ACCOUNT: true,
            PAYOUT_ACCOUNT_CONNECTION: true,
            UNREAD_MESSAGE: true,
            SALE_AWAITING_SHIPMENT: true,
            SELLER_REVIEW: true
        }
    },
    locale: 'en',
    enableOffersOption: undefined,
    enableTradeOffersOption: undefined,
    enableFinancingPaymentOption: true,
    useListingAutoTagFilters: false
};

export const REQURED_INTERACTION_USER_PREFERENCES_NEW_USER_DEFAULTS: RequiredInteractionUserPreferences = {
    enableTradeOffersOption: true,
    enableOffersOption: true
};
