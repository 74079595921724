import { useQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getUserSubmittedModerationFlags } from '../server/api-proxy/moderation-flags';

const userFetchModerationFlagsSubmittedQueryFn = (userId: string) => async () => {
    const response = await getUserSubmittedModerationFlags({ userId: userId as string });
    const { error: { message: fetchError = '' } = {} } = response as { error?: { message: string } };

    if (fetchError) {
        throw new Error(fetchError);
    }

    return response;
};

export const useUserModerationFlags = ({ userId }: { userId?: string | null }) =>
    useQuery({
        enabled: Boolean(userId),
        queryKey: uiCacheKeyBuilderMap.loggedInUserModerationFlagsSubmitted({ loggedInUserId: userId }),
        queryFn: userFetchModerationFlagsSubmittedQueryFn(userId as string),
        retry: false
    });
