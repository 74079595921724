import { blobToDataURL, canvasToBlob } from 'blob-util';
import { getImageAsync } from './get-image-async';

export const getRotatedImageBlob = async (dataUri: string, degrees: 90 | -90) => {
    const image = await getImageAsync(dataUri);
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.drawImage(image, 0, 0);

    ctx.canvas.width = image.height;
    ctx.canvas.height = image.width;
    ctx.rotate((degrees * Math.PI) / 180);

    switch (degrees) {
        case 90: {
            ctx.drawImage(image, 0, -image.height);
            break;
        }

        case -90: {
            ctx.drawImage(image, -image.width, 0);
            break;
        }

        default: {
            ctx.drawImage(image, 0, 0);
        }
    }

    ctx.save();

    const blob = await canvasToBlob(canvas, 'image/jpeg', 0.9);
    const blobAsUri = await blobToDataURL(blob);

    return {
        blob,
        dataUri: blobAsUri
    };
};
