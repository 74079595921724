import { MessageGraph, MessagePostJson } from '@bladebinge/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap, uiCachePrimaryKeys } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { useRouter } from 'next/router';
import { createMessageRequest } from '../../../server/api-proxy/messages';
import { useMe } from '../../../context/me/me-context';
import { PagedMessageGraph } from './sent-messages';

const createMessageMutateFn = async (messageData: MessagePostJson): Promise<MessageGraph> => {
    const messageResponse = await createMessageRequest(messageData);

    const { error: { message: createMessageError } = {} } = messageResponse as {
        error?: {
            message: string;
        };
    };

    if (createMessageError) {
        throw new Error(createMessageError);
    }

    return messageResponse as MessageGraph;
};

export const useCreateMessage = ({
    successCallback
}: {
    successCallback?: (message: MessageGraph, variables: MessagePostJson) => void;
}) => {
    const queryClient = useQueryClient();
    const { id: loggedInUserId } = useMe();
    const { query } = useRouter();

    return useMutation<
        MessageGraph,
        Error,
        MessagePostJson,
        {
            previousSentMessages?: PagedMessageGraph;
            previousMessageThread?: PagedMessageGraph;
        }
    >({
        mutationFn: createMessageMutateFn,
        onSuccess(
            createdMessage: MessageGraph,
            postedMessageJson: MessagePostJson,
            context: {
                previousSentMessages?: PagedMessageGraph;
                previousMessageThread?: PagedMessageGraph;
            }
        ) {
            if (successCallback) {
                successCallback(createdMessage as MessageGraph, postedMessageJson);
            }
        },
        async onMutate(postedMessageJson: MessagePostJson) {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [uiCachePrimaryKeys.messageThread] });
            await queryClient.cancelQueries({ queryKey: [uiCachePrimaryKeys.sentMessages] });

            // Snapshot the previous value
            const previousSentMessages = queryClient.getQueryData<PagedMessageGraph>([uiCachePrimaryKeys.sentMessages]);
            const previousMessageThread = queryClient.getQueryData<PagedMessageGraph>([
                uiCachePrimaryKeys.messageThread
            ]);

            // Optimistically update to the new value
            if (previousSentMessages) {
                queryClient.setQueryData(
                    uiCacheKeyBuilderMap.sentMessages({ query, userId: loggedInUserId }),
                    ({ result, _total, ...rest }) => ({
                        ...rest,
                        result: [...result, postedMessageJson],
                        _total: _total + 1
                    })
                );
            }

            // Return a context object with the snapshotted value
            return { previousSentMessages, previousMessageThread };
        },
        // Always refetch after error or success:
        onSettled() {
            queryClient.invalidateQueries({ queryKey: [uiCachePrimaryKeys.messageThread] });
        }
    });
};
