import { Image, ImageType, ListingGraph } from '@bladebinge/types';
import { CroppedImageData, ImageUploadData } from '../../../components/FormComponents/images/utils/types';
import { normalizedFetch } from '../utils/normalized-fetch';
import { PROXY_ROUTES } from '../ui-proxy-routes';

export const uploadImages = async ({
    fileBlobs,
    userId,
    imageType,
    uploadData
}: {
    fileBlobs: CroppedImageData[];
    userId: string;
    imageType: ImageType;
    uploadData?: ImageUploadData;
}): Promise<{
    images: Image[];
    listing?: ListingGraph;
}> => {
    const form = new FormData();

    fileBlobs.forEach(({ image: fb, thumbnail: tb }) => {
        form.append(imageType, fb?.blob, fb?.path);
        form.append(imageType, tb?.blob, tb?.path);

        // image pairs allow the server to easily determine which thumbnail links go with which image
        form.append(
            'imagePairs',
            JSON.stringify({
                image: fb?.path,
                thumb: tb?.path
            })
        );
    });

    form.append('imageType', imageType);

    Object.entries(uploadData || {}).forEach(([key, value]) => form.append(key, value));

    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.uploadImages.build({ userId }),
        options: {
            method: 'POST',
            body: form
        }
    });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
