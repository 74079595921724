import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { getGlobalStaticFeatureIsEnabledByDefault } from '@bladebinge/web-service-common/src/feature-flags/get-global-static-feature-enablement';

export const LegalContentRestrictionsText = () => {
    const { t } = useTranslation();
    const allowLegallySensitiveFilters = getGlobalStaticFeatureIsEnabledByDefault(
        'EnableLegallyRestrictedFilterCategories'
    );

    if (allowLegallySensitiveFilters) {
        return null;
    }

    return (
        <>
            <Typography variant="h5" component="div">
                {t('legal:terms.content_control.title')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.content_control.paragraph_1')}
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.automatic_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.butterly_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.dual_action_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.gravity_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.out_the_front_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.paratrooper_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.push_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.switchblade_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.sword_canes')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.spring_assisted_knives_extended_description')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.hidden_or_disguised_knives')}
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        {t('legal:terms.content_control.restricted_items.knife_clones')}
                    </Typography>
                </li>
            </ul>
            <Typography variant="body1" paragraph>
                {t('legal:terms.content_control.paragraph_2')}
            </Typography>
        </>
    );
};
