import type { Address, ApiResponseOrError, OwnedAddressPostJson, UserProfile } from '@bladebinge/types';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const fetchUserProfileAddressBook = async ({
    currentUserId,
    userProfileId
}: {
    currentUserId: string;
    userProfileId?: string;
}): Promise<Address[]> => {
    const addressBookResponse = await normalizedFetch({
        endpoint: userProfileId
            ? // if we know active profile use the faster endpoint
              PROXY_ROUTES.fetchUserProfileAddressBook.build({
                  userId: currentUserId,
                  userProfileId
              })
            : // otherwise use user endpoint that also finds an active or first profile
              PROXY_ROUTES.fetchUserActiveProfileAddressBook.build({
                  userId: currentUserId
              }),
        options: {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
    return addressBookResponse;
};

export const deleteUserProfileAddressById = async ({
    addressId,
    userProfileId
}: {
    addressId: string;
    userProfileId: string;
}) => {
    try {
        const addressResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.deleteUserProfileAddressById.build({
                addressId,
                userProfileId
            }),
            options: {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
        return addressResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const createAddressFromProfileManager = async ({
    address,
    userProfileId
}: {
    address: OwnedAddressPostJson;
    userProfileId: string;
}): Promise<ApiResponseOrError<Address>> => {
    try {
        const addressResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.createAddressFromProfileManager.build({ userProfileId }),
            options: {
                method: 'POST',
                body: JSON.stringify(address),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
        return addressResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const updateExistingUserProfileAddress = async ({
    updatedAddress,
    userProfileId
}: {
    updatedAddress: OwnedAddressPostJson;
    userProfileId: string;
}): Promise<ApiResponseOrError<UserProfile>> => {
    try {
        const addressResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.updateUserProfileAddressById.build({
                addressId: updatedAddress?.id,
                userProfileId
            }),
            options: {
                method: 'PUT',
                body: JSON.stringify(updatedAddress),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
        return addressResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};
