import { blobToDataURL, canvasToBlob } from 'blob-util';
import { getUniqueRandomId } from '../../../../utils/get-unique-random-id';
import { getImageAsync } from './get-image-async';
import { renameImagePath } from './rename-image-path';
import { getResizedImageBlob, MAX_IMAGE_WIDTH_PX, MIN_IMAGE_WIDTH_PX } from './get-resized-image-blob';
import { CroppedImageData, VerifiedPercentageCrop } from './types';

export const getCroppedSquareImageBlobData = async ({
    crop,
    dataUri,
    path
}: {
    crop?: VerifiedPercentageCrop;
    dataUri: string;
    path: string;
}): Promise<CroppedImageData> => {
    const image = await getImageAsync(dataUri);

    const backupCropDims =
        image.naturalWidth >= image.naturalHeight
            ? { width: (image.naturalHeight / image.naturalWidth) * 100, height: 100 }
            : { width: 100, height: (image.naturalWidth / image.naturalHeight) * 100 };

    const cropOrDefault = crop ?? {
        aspect: 1,
        x: 0,
        y: 0,
        ...backupCropDims,
        unit: '%'
    };

    const canvas: HTMLCanvasElement = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const cropWidthAsDecimal = cropOrDefault.width / 100;
    const totalCropWidth = cropWidthAsDecimal * scaleX * image.naturalWidth;
    // const totalCropHeight = cropHeightAsDecimal * scaleX * image.naturalHeight;
    const squareSideLength = Math.floor(totalCropWidth);

    canvas.width = cropOrDefault.width;
    canvas.height = cropOrDefault.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;

    ctx.canvas.width = squareSideLength;
    ctx.canvas.height = squareSideLength;
    ctx.drawImage(
        image, // HTMLImage
        (cropOrDefault.x / 100) * scaleX * image.naturalWidth, // sx top-left corner x coordinate
        (cropOrDefault.y / 100) * scaleY * image.naturalHeight, // sy top-left corner y coordinate
        squareSideLength, // sWidth crop width
        squareSideLength, // sHeight crop height
        0, // dx x-axis coordinate of topleft corner of new canvas
        0, // dy y-axis coordinate of topleft corner of new canvas
        squareSideLength, // dWidth - canvas draw width
        squareSideLength // dHeight - canvas draw height
    );

    ctx.save();

    const blob = await canvasToBlob(canvas);
    const blobAsUri = await blobToDataURL(blob);

    const fullData = await getResizedImageBlob(blobAsUri, MAX_IMAGE_WIDTH_PX);
    const thumbData = await getResizedImageBlob(blobAsUri, MIN_IMAGE_WIDTH_PX);

    // generate a name concatenated with a random string so we are not overwriting CDN assets with the same name
    const safePath = `${renameImagePath(path)}_${getUniqueRandomId()}`.toLowerCase();

    return {
        image: {
            ...fullData,
            path: `${safePath}.jpg`
        },
        thumbnail: {
            ...thumbData,
            path: `${safePath}_thumb.jpg`
        }
    };
};
