import React, { type SyntheticEvent, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Backdrop from '@mui/material/Backdrop';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { getGlobalStaticFeatureEnablement } from '@bladebinge/web-service-common/src/feature-flags/get-global-static-feature-enablement';
import { StyledFormLegalContentWrap, StyledModalCancelIcon } from '../styled-shared';
import { useMe } from '../../context/me/me-context';
import { TradesHowDoesItWorkText } from './TradesHowDoesItWorkText';

export const TradeFeatureHelpModal = ({ ignoreFlag = false }: { readonly ignoreFlag?: boolean }) => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();
    const [isConditionsModalOpen, setIsConditionsModalOpen] = useState<boolean>(false);

    const toggleModal = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsConditionsModalOpen((previousOpenState) => !previousOpenState);
    };

    if (!ignoreFlag && !getGlobalStaticFeatureEnablement('EnableTrades', loggedInUserId)) {
        return null;
    }

    return (
        <>
            <IconButton
                aria-label={t('common:user_preferences.trading_feature.how_does_it_work')}
                onClick={toggleModal}
                title={t('common:user_preferences.trading_feature.how_does_it_work')}
                color="inherit"
                size="medium"
                sx={{
                    position: 'relative',
                    bottom: 2,
                    color: 'text.primary',
                    ml: 1
                }}
            >
                <HelpIcon />
            </IconButton>
            <Modal
                aria-label={t('common:listing_form.bladebinge_condition_guidelines')}
                open={isConditionsModalOpen}
                onClose={() => setIsConditionsModalOpen(false)}
                closeAfterTransition
                components={{
                    Backdrop
                }}
                sx={{ overflow: 'scroll' }}
            >
                <StyledFormLegalContentWrap>
                    <StyledModalCancelIcon onClick={() => setIsConditionsModalOpen(false)} />
                    <TradesHowDoesItWorkText />
                </StyledFormLegalContentWrap>
            </Modal>
        </>
    );
};
