import { Address, OwnedAddressPostJson } from '@bladebinge/types';
import {
    createAddressFromProfileManager,
    deleteUserProfileAddressById,
    updateExistingUserProfileAddress
} from '../user-profile-addresses';
import { createValidatedAddress } from '../addresses';

export const createAddress = async (addressPostData: OwnedAddressPostJson) => {
    const response = await createValidatedAddress(addressPostData);

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response as Address;
};

export const createUserProfileAddress = async ({
    address,
    userProfileId
}: {
    address: OwnedAddressPostJson;
    userProfileId: string;
}) => {
    const response = await createAddressFromProfileManager({
        address,
        userProfileId
    });

    // We do not actually hard-delete addresses.
    // update is provided for the convenience of re-loading an address to edit it
    // but results in creation of a new address object and deletion of a UserProfileAddress relation on the previous object
    const { error: { message: apiErrorMessage = '' } = {}, ...addressResponse } = response as {
        error?: { message?: string };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return addressResponse as Address;
};

export const deleteUserProfileAddress = async ({
    addressId,
    userProfileId
}: {
    addressId: string;
    userProfileId: string;
}) => {
    const response = await deleteUserProfileAddressById({ addressId, userProfileId });

    const { error: { message: apiErrorMessage = '' } = {} } = response;

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};

export const updateUserProfileAddress = async ({
    updatedAddress,
    userProfileId
}: {
    updatedAddress: OwnedAddressPostJson;
    userProfileId: string;
}) => {
    const response = await updateExistingUserProfileAddress({
        updatedAddress,
        userProfileId
    });

    // We do not actually hard-delete addresses.
    // update is provided for the convenience of re-loading an address to edit it
    // but results in creation of a new address object and deletion of a UserProfileAddress relation on the previous object
    const { error: { message: apiErrorMessage = '' } = {} } = response as { error?: { message?: string } };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
