import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import type { SxProps } from '@mui/system';

export const OfferStepCheckmark = ({ checked, sx }: { readonly checked: boolean; readonly sx?: SxProps }) => (
    <CheckCircleOutlineIcon
        color={checked ? 'info' : 'disabled'}
        sx={{
            position: 'relative',
            top: '8px',
            mr: 1,
            fontSize: '1.4em',
            ...sx
        }}
    />
);
