import type { PersistedUserPreferenceValues, UserPreferencesFormSectionTypes } from '@bladebinge/types';
import { PROXY_ROUTES } from '../ui-proxy-routes';
import { normalizedFetch } from '../utils/normalized-fetch';

export const updateUserPreferences = async ({
    userId,
    preferences,
    subform,
    signal
}: {
    userId: string;
    preferences: Partial<PersistedUserPreferenceValues>;
    subform?: UserPreferencesFormSectionTypes;
    signal?: AbortSignal;
}) => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.updateUserPreferences.build({ userId }),
        options: {
            method: 'POST',
            body: JSON.stringify(preferences),
            headers: {
                'Content-Type': 'application/json'
            },
            ...(signal ? { signal } : {})
        }
    });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
