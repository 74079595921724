import type { Address, ApiResponseOrError, OwnedAddressPostJson } from '@bladebinge/types';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

// validate and create a new address object
export const createValidatedAddress = async (address: OwnedAddressPostJson): Promise<ApiResponseOrError<Address>> => {
    try {
        const addressResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.createAddress.build(),
            options: {
                method: 'POST',
                body: JSON.stringify(address),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });

        return addressResponse as ApiResponseOrError<Address>;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getAddressById = async (addressId: string, signal?: AbortSignal) => {
    try {
        const addressResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchAddressById.build({ addressId }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            }
        });

        return addressResponse as ApiResponseOrError<Address>;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getSellerAddressInformation = async ({
    addressId,
    sellerId,
    signal
}: {
    addressId: string;
    sellerId: string;
    signal?: AbortSignal;
}) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchSellerAddressInformation.build({ addressId, sellerId }),
            ...(signal
                ? {
                      options: {
                          signal
                      }
                  }
                : {})
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};
