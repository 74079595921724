import type { ParsedUrlQuery } from 'querystring';
import { useQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { UserOfferIdsByStatus } from '@bladebinge/types';
import { getUserOfferIds } from '../../../server/api-proxy/user-offers';

const DEFAULT_FAVORITES_POLL_INTERVAL = 1000 * 60 * 1; // every minute in milliseccods

const userFetchUserOfferIdsQueryFn =
    ({ query, userId }: { query?: ParsedUrlQuery; userId: string }) =>
    async (): Promise<UserOfferIdsByStatus> => {
        const response = await getUserOfferIds({ query, userId });
        const { error: { message: flagsFetchError = '' } = {} } = response as { error?: { message: string } };

        if (flagsFetchError) {
            throw new Error(flagsFetchError);
        }

        return response;
    };

export const useUserOfferIds = ({
    activePolling = false,
    query,
    userId
}: {
    activePolling?: boolean;
    query?: ParsedUrlQuery;
    userId?: string | null;
}) =>
    useQuery({
        enabled: Boolean(userId),
        queryKey: uiCacheKeyBuilderMap.loggedInUserOfferIds({
            loggedInUserId: userId
        }),
        queryFn: userFetchUserOfferIdsQueryFn({
            userId: userId as string,
            query
        }),
        ...(activePolling ? { refetchInterval: DEFAULT_FAVORITES_POLL_INTERVAL } : {}),
        retry: false
    });
