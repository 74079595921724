import format from 'date-fns/format';

const dateTimeDisplayFormat = 'MMMM do yyyy, h:mm a';
export const shortMonthDateTimeFormat = 'LLL d, yyyy, h:mm a';
export const shortDateFormat = 'MM/dd/yyyy';
export const abbreviatedMonthDayTimeFormat = 'MMM d, Y h:mm a';

export const displayDate = (dateTime: Date | number | string, displayFormat: string = dateTimeDisplayFormat) => {
    try {
        return dateTime ? format(new Date(dateTime), displayFormat) : '';
    } catch (err) {
        // we have been throwing cross-browser errors here in some cases
        // just return the unformatted datetime string rather than failing
        return typeof dateTime === 'string' ? dateTime : '';
    }
};

export const displayShortDate = (dateTime: Date | number | string) =>
    dateTime ? format(new Date(dateTime), shortDateFormat) : '';
