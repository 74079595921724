import React, { useEffect } from 'react';
import { useMessageImagesContext } from '../../../context/image-uploads/message-images-context';
import { StandaloneMessageBox } from './StandaloneMessageBox';

export const PurchaseOfferStandaloneMessageBox = ({
    defaultMessage = '',
    onSubmit,
    onUpdate,
    readonly
}: {
    readonly defaultMessage?: string;
    readonly onSubmit: ({ messageBody, imageIds }: { messageBody: string; imageIds?: string[] }) => void;
    readonly onUpdate?: ({ messageBody, imageIds }: { messageBody: string; imageIds?: string[] }) => void;
    readonly readonly?: boolean;
}) => {
    const { detachedImageUploads, setDetachedImageUploads } = useMessageImagesContext();

    // on DOM offload clear any detached messages
    useEffect(
        () => () => {
            if (detachedImageUploads.length === 0) {
                return;
            }

            setDetachedImageUploads([]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <StandaloneMessageBox
            defaultMessage={defaultMessage}
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            readonly={readonly}
        />
    );
};
