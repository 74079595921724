import type { ParsedUrlQuery } from 'querystring';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const getUserOfferIds = async ({ query = {}, userId }: { query?: ParsedUrlQuery; userId: string }) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserOfferIds.build({
                queryParams: query,
                userId
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getUserPurchaseOffersAsSeller = async ({
    query = {},
    userId
}: {
    query?: ParsedUrlQuery;
    userId: string;
}) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserPurchaseOffers.build({
                queryParams: {
                    ...query,
                    role: 'seller'
                },
                userId
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getUserPurchaseOffersAsBuyer = async ({
    query = {},
    userId
}: {
    query?: ParsedUrlQuery;
    userId: string;
}) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserPurchaseOffers.build({
                queryParams: {
                    ...query,
                    role: 'buyer'
                },
                userId
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};
