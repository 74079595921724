export const AUTO_TAGGING_STOP_WORDS = new Set<string>()
    .add('and')
    .add('by')
    .add('blade')
    .add('blades')
    .add('carry')
    .add('case')
    .add('ceramic')
    .add('company')
    .add('co')
    .add('custom')
    .add('cutlery')
    .add('dark')
    .add('david')
    .add('design')
    .add('designs')
    .add('detail')
    .add('edc')
    .add('edge')
    .add('engineering')
    .add('field')
    .add('forge')
    .add('great')
    .add('horse')
    .add('knife')
    .add('knives')
    .add('lock')
    .add('manufacturing')
    .add('mercantile')
    .add('other')
    .add('quiet')
    .add('red')
    .add('river')
    .add('sharp')
    .add('something')
    .add('stainless')
    .add('steel')
    .add('tactical')
    .add('tool')
    .add('will')
    .add('work')
    .add('works')
    .add('v');
