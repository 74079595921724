import React from 'react';
import { useTranslation } from 'next-i18next';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { StyledAutoHeightModalPaper, StyledModalCancelIcon } from '../styled-shared';

export const OffPlatformSalesActivityWarningModal = ({
    isOpen = false,
    onClose
}: {
    readonly isOpen: boolean;
    readonly onClose: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            aria-label={t('common:messaging.off_platform_sale_warning')}
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            components={{
                Backdrop
            }}
            sx={{
                overflow: 'scroll',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <StyledAutoHeightModalPaper>
                <StyledModalCancelIcon onClick={onClose} />
                <Grid container spacing={1} justifyContent="center">
                    <Paper sx={{ m: 1.5, p: 1.5 }}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h5">
                                {t('common:messaging.off_platform_warning_modal_title')}
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid container item xs={12} sx={{ pl: 2, pt: 0.5 }}>
                            <Grid item xs={12} sx={{ pl: 2, pt: 0.5 }}>
                                <Typography variant="body1">
                                    {t('common:messaging.off_platform_warning_modal_body')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </StyledAutoHeightModalPaper>
        </Modal>
    );
};
