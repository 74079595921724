import React from 'react';
import { default as NextImage } from 'next/image';
import Badge from '@mui/material/Badge';
import Link from 'next/link';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import FilterList from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'next-i18next';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useSearchFilterContext } from '../context/search-filter/search-filter-context';
import { CartNavButton } from './atoms/CartNavButton';
import { SalesDriverButton } from './atoms/SalesDriverButton';
import { AuthNav } from './auth-flow/AuthNav';
import { SiteSearch } from './SiteSearch';
import { UserNotificationsNavButton } from './auth-flow/notifications/UserNotificationsNavButton';

export const APP_BAR_HEIGHT_PX = 56;
export const MOBILE_SEARCH_BAR_HEIGHT_PX = 43;
const NAV_LOGO_WIDTH_PX = 145;

const MobileSearchToggle = styled(IconButton)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
        display: 'inline'
    }
}));

const StyledInlineSiteSearch = styled(SiteSearch)(({ theme }) => ({
    display: 'block',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));

const BottomNavSalesDriverButton = styled(SalesDriverButton)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    }
}));

const StyledInlineSalesDriverButton = styled(SalesDriverButton)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    }
}));

const StyledNavButtonsWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row'
}));

const StyledNavWrap = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '& ul': {
        listStyle: 'none',
        textIndent: 0,
        margin: theme.spacing(0.25, 0, 0, 0),
        padding: 0,
        '& > li': {
            display: 'inline-block',
            textIndent: 0,
            verticalAlign: 'bottom'
        }
    }
}));

const LogoWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center'
}));

const SimplifiedNavWrap = styled('div')(({ theme }) => ({
    width: `calc(100% - ${NAV_LOGO_WIDTH_PX}px)`,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '@media (max-width: 900px)': {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flexDirection: 'column-reverse'
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between'
    }
}));

const StyledNavBottomBar = styled(Grid)(({ theme }) => ({
    display: 'none',
    paddingBottom: theme.spacing(0.5),
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
        display: 'flex'
    }
}));

export const Navigation = ({ simplify = false }: { readonly simplify?: boolean }) => {
    const { t } = useTranslation('common');
    const { selectedDrawerFilterIds, setIsFilterDrawerOpen, showMobileSearchBar, setShowMobileSearchBar } =
        useSearchFilterContext();
    const selectedDrawerFilterCount = selectedDrawerFilterIds.length;

    const handleDrawerOpen = () => {
        setIsFilterDrawerOpen(true);
    };

    return (
        <AppBar
            position="fixed"
            className={showMobileSearchBar ? 'search-bar-expanded' : ''}
            sx={{
                backfgroundColor: 'background.default',
                px: 0.25,
                py: 0,
                transition: (theme) =>
                    theme.transitions.create(['margin', 'width'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    }),
                minHeight: `${APP_BAR_HEIGHT_PX}px`,
                borderBottom: '2px outset',
                borderColor: 'background.default'
            }}
        >
            <Toolbar
                sx={{
                    pb: 1,
                    // px: 1,
                    minHeight: `${APP_BAR_HEIGHT_PX}px`
                }}
            >
                {!simplify && (
                    <Badge
                        badgeContent={selectedDrawerFilterCount}
                        color="secondary"
                        onClick={handleDrawerOpen}
                        overlap="circular"
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        variant="standard"
                        sx={{ cursor: 'pointer', fontSize: '0.9em', mr: 0.25 }}
                    >
                        <IconButton
                            aria-label={t('common:navigation.open_filters_drawer')}
                            color="secondary"
                            edge="start"
                            id="filter_drawer_toggle_btn"
                            title={t('common:navigation.open_filters_drawer')}
                            size="medium"
                        >
                            <FilterList />
                        </IconButton>
                    </Badge>
                )}
                <StyledNavWrap>
                    <LogoWrap>
                        <Link href="/">
                            <Typography variant="h1">
                                <Box
                                    sx={{
                                        position: 'relative',
                                        top: '5px',
                                        height: {
                                            xs: '38px',
                                            sm: '38px',
                                            md: '50px',
                                            lg: '50px',
                                            xl: '50px'
                                        },
                                        width: {
                                            xs: '110px',
                                            sm: '110px',
                                            md: '144px',
                                            lg: '144px',
                                            xl: '144px'
                                        },
                                        '& h1': {
                                            padding: 0,
                                            margin: 0
                                        }
                                    }}
                                >
                                    <NextImage
                                        alt={t('common:navigation.bladebinge_primary_heading')}
                                        src={getCdnUrl('/logos/bladebinge_logo_white_230w_80h.png')}
                                        priority
                                        fill
                                    />
                                </Box>
                            </Typography>
                        </Link>
                        <MobileSearchToggle
                            aria-label={
                                showMobileSearchBar
                                    ? t('common:navigation.hide_mobile_search')
                                    : t('common:navigation.show_mobile_search')
                            }
                            onClick={() => setShowMobileSearchBar(!showMobileSearchBar)}
                            title={
                                showMobileSearchBar
                                    ? t('common:navigation.hide_mobile_search')
                                    : t('common:navigation.show_mobile_search')
                            }
                            color="inherit"
                            size="small"
                        >
                            <SearchIcon />
                        </MobileSearchToggle>
                    </LogoWrap>
                    {!simplify && (
                        <SimplifiedNavWrap>
                            <StyledInlineSiteSearch inputId="header_layout_search" />
                            <StyledNavButtonsWrap>
                                <StyledInlineSalesDriverButton />
                                <UserNotificationsNavButton />
                                <CartNavButton />
                                <AuthNav />
                            </StyledNavButtonsWrap>
                        </SimplifiedNavWrap>
                    )}
                </StyledNavWrap>
            </Toolbar>
            {showMobileSearchBar && (
                <StyledNavBottomBar container justifyContent="space-between" spacing={1} sx={{ my: 0, px: 0.25 }}>
                    <Grid item xs={8} sm={8} md={8} lg={12}>
                        <SiteSearch inputId="subheader_layout_search" />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <BottomNavSalesDriverButton />
                    </Grid>
                </StyledNavBottomBar>
            )}
        </AppBar>
    );
};
