import { useQuery } from '@tanstack/react-query';
import { UserProfileDisplayGraph } from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getUserProfileDisplayGraphByUserProfileId } from '../../server/api-proxy/user-profiles';

const userProfileDisplayGraphFetchFn =
    ({ id }: { id?: string | null }) =>
    async (): Promise<UserProfileDisplayGraph> => {
        if (!id) {
            throw new Error('A id is required to fetch a user profile display graph');
        }

        const data = await getUserProfileDisplayGraphByUserProfileId({ userProfileId: id });
        const { error: { message: loadError = '' } = {} } = data;

        if (loadError) {
            throw new Error(loadError);
        }

        return data;
    };

export const useUserProfileDisplayGraph = ({ id }: { id?: string | null }) =>
    useQuery({
        enabled: Boolean(id),
        queryKey: uiCacheKeyBuilderMap.userProfileDisplayGraph({ userProfileId: id }),
        queryFn: userProfileDisplayGraphFetchFn({ id }),
        retry: false
    });
