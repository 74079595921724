import { blobToDataURL, canvasToBlob } from 'blob-util';
import { getImageAsync } from './get-image-async';

export const MAX_IMAGE_WIDTH_PX = 1500;
export const MIN_IMAGE_WIDTH_PX = 640;

export const getResizedImageBlob = async (dataUri: string, size: number) => {
    const image = await getImageAsync(dataUri);
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const imageDrawSize = size > MIN_IMAGE_WIDTH_PX ? Math.min(MAX_IMAGE_WIDTH_PX, size) : MIN_IMAGE_WIDTH_PX;

    const ctx: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.canvas.width = imageDrawSize;
    ctx.canvas.height = imageDrawSize;
    ctx.drawImage(image, 0, 0, imageDrawSize, imageDrawSize);

    ctx.save();

    const blob = await canvasToBlob(canvas, 'image/jpeg', 0.9);
    const blobAsUri = await blobToDataURL(blob);

    return {
        blob,
        dataUri: blobAsUri
    };
};
