import React, { useId, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useProfileListingsByProfileId } from '../../../hooks/react-query/use-profile-listings-by-profile-id';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { ListingTile } from '../../listing/ListingTile';
import { NoResults } from '../../atoms/NoResults';
import { PaginationByLinks } from '../../atoms/PaginationByLinksButtons';
import { UserProfileWithAverageRating } from '../../atoms/UserProfileWithAverageRating';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { useMe } from '../../../context/me/me-context';
import { GroupedListingsSearchAndSort } from '../GroupedListingsSearchAndSort';

export const PurchaseOfferAddSellerBundleSection = ({
    isRequestingTradesForCounter = false
}: {
    readonly isRequestingTradesForCounter?: boolean;
}) => {
    const { t } = useTranslation();
    const ariaId = useId();
    const { activePurchaseOfferListingIds } = useMe();
    const [manualListingPagingQuery, setManualListingPagingQuery] = useState<{
        limit: number;
        offset: number;
    }>({
        offset: 0,
        limit: 6
    });
    const [searchAndSortParams, setSearchAndSortParams] = useState({
        q: '',
        userListingSortByData: {
            sortBy: '',
            sortDirection: '',
            uiSelectorValue: ''
        }
    });

    const {
        addListingToOffer,
        addTradeToOffer,
        offerHasTrades,
        offerListings,
        offerTradeListings,
        removeListingFromOffer,
        removeTradeFromOffer,
        offeredToUserContext,
        parentOffer,
        remoteUserProfileGraph
    } = useMakeAnOfferContext();

    const hasMounted = useHasMounted();
    const listingDataOwnerId = isRequestingTradesForCounter
        ? parentOffer?.offeredByUserProfileId
        : offeredToUserContext?.offeredToUserProfileId;

    const {
        data,
        error: loadListingsError,
        isLoading: loadingListings,
        refetch
    } = useProfileListingsByProfileId({
        userProfileId: listingDataOwnerId,
        hasMounted,
        query: {
            limit: `${manualListingPagingQuery.limit}`,
            offset: `${manualListingPagingQuery.offset}`
        },
        ...searchAndSortParams
    });

    const lastPageFetched = useMemo(() => (Array.isArray(data?.pages) ? data.pages.length - 1 : 0), [data]);
    const currentPageData = useMemo(
        () => data?.pages?.[lastPageFetched] ?? { result: [], _limit: 25, _total: 0, _links: undefined },
        [data, lastPageFetched]
    );
    const { _total = 0, result: currentPageItems = [], _links } = currentPageData;

    const heading = isRequestingTradesForCounter
        ? t('common:purchase_offer.request_trades_from_user')
        : t('common:purchase_offer.create_a_bundle');

    const instruction = isRequestingTradesForCounter
        ? t('common:purchase_offer.choose_seller_items_to_add_to_trade')
        : t('common:purchase_offer.choose_seller_items_to_add_to_bundle');

    if (!remoteUserProfileGraph) {
        return null;
    }

    return (
        <Grid container item xs={12} sx={{ my: 1 }}>
            <Accordion sx={{ width: '100%', pl: 0, ml: 0 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`offer-bundle-${ariaId}-content`}
                    id={`offer-bundle-${ariaId}-header`}
                >
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="h6" component="span" sx={{ py: 0, my: 0 }}>
                                {heading}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={6} md={4}>
                            {remoteUserProfileGraph && (
                                <UserProfileWithAverageRating
                                    size="small"
                                    gridDirection="row-reverse"
                                    showBorder={false}
                                    profile={remoteUserProfileGraph}
                                />
                            )}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={11} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div" sx={{ my: 1 }}>
                                {instruction}
                            </Typography>
                        </Grid>
                        {listingDataOwnerId && (
                            <GroupedListingsSearchAndSort
                                onChange={setSearchAndSortParams}
                                groupId={listingDataOwnerId}
                            />
                        )}
                        {loadListingsError && (
                            <Grid item xs={12}>
                                <Alert severity="error" onClose={() => refetch()}>
                                    {loadListingsError.message}
                                </Alert>
                            </Grid>
                        )}
                        {loadingListings && (
                            <Grid item xs={12}>
                                <LoadingIndicator />
                            </Grid>
                        )}
                        {currentPageItems.length > 0 ? (
                            <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                {currentPageItems.map((listingGraph) => {
                                    const itemIsInOfferAlready =
                                        offerTradeListings.has(listingGraph.id) || offerListings.has(listingGraph.id);
                                    const addFn = isRequestingTradesForCounter ? addTradeToOffer : addListingToOffer;

                                    const removeFn = isRequestingTradesForCounter
                                        ? removeTradeFromOffer
                                        : removeListingFromOffer;

                                    return (
                                        <Grid
                                            key={listingGraph.id}
                                            item
                                            xs={4}
                                            sm={4}
                                            md={2}
                                            sx={{
                                                opacity: activePurchaseOfferListingIds.has(listingGraph.id) ? 0.5 : 1
                                            }}
                                        >
                                            <ListingTile
                                                iconActionButton={
                                                    <Fab
                                                        color={itemIsInOfferAlready ? 'default' : 'primary'}
                                                        disabled={
                                                            (offerHasTrades && !listingGraph.isTradeEnabled) ||
                                                            (!offerHasTrades && !listingGraph.isOfferEnabled) ||
                                                            activePurchaseOfferListingIds.has(listingGraph.id)
                                                        }
                                                        onClick={() =>
                                                            itemIsInOfferAlready
                                                                ? removeFn(listingGraph)
                                                                : addFn(listingGraph)
                                                        }
                                                        size="small"
                                                        sx={{
                                                            position: 'absolute',
                                                            zIndex: 1,
                                                            top: '-20px',
                                                            right: '-15px'
                                                        }}
                                                        title={
                                                            itemIsInOfferAlready
                                                                ? t('common:purchase_offer.remove_item_from_offer')
                                                                : t('common:purchase_offer.add_item_to_offer')
                                                        }
                                                    >
                                                        {itemIsInOfferAlready ? <DeleteIcon /> : <AddIcon />}
                                                    </Fab>
                                                }
                                                listing={listingGraph}
                                                showActionButtons={false}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : (
                            <NoResults />
                        )}
                        <Grid item xs={12} sx={{ my: 2 }}>
                            <PaginationByLinks
                                currentPageItemsCount={currentPageItems.length}
                                defaultLimit={5}
                                handleSetPagingParams={setManualListingPagingQuery}
                                links={_links}
                                total={_total}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
