import React from 'react';
import GradeIcon from '@mui/icons-material/Grade';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import type { SxProps } from '@mui/system';
import { UserInterfaceNotificationMenuItemTypes } from '@bladebinge/types';

const NOTIFICATION_TYPE_ICONS = {
    BUYER_FEEDBACK_REQUESTED: StarBorderIcon,
    SELLER_FEEDBACK_REQUESTED: StarBorderIcon,
    NEW_MESSAGE: MarkEmailUnreadIcon,
    FEEDBACK: GradeIcon,
    NEW_PURCHASE_OFFER: LocalOfferIcon,
    NEW_COUNTER_OFFER: LocalOfferIcon,
    PURCHASE_OFFER_ACCEPTED: LocalOfferIcon,
    PURCHASE_OFFER_DECLINED: LocalOfferIcon,
    OUTGOING_PAYMENT_ERROR: LinkOffIcon,
    PRICE_DROP: TrendingDownIcon,
    SHIPMENT_AWAITING_SHIPMENT: LocalShippingIcon,
    SHIPMENT_SHIPPING_LABEL_REQUIRED: QrCodeScannerIcon
};

const NOTIFICATION_TYPE_ICON_COLORS: { [key: string]: 'primary' | 'error' | 'info' } = {
    BUYER_FEEDBACK_REQUESTED: 'primary',
    SELLER_FEEDBACK_REQUESTED: 'primary',
    NEW_MESSAGE: 'primary',
    FEEDBACK: 'primary',
    NEW_PURCHASE_OFFER: 'primary',
    NEW_COUNTER_OFFER: 'primary',
    PURCHASE_OFFER_ACCEPTED: 'primary',
    PURCHASE_OFFER_DECLINED: 'error',
    OUTGOING_PAYMENT_ERROR: 'error',
    PRICE_DROP: 'info',
    SHIPMENT_AWAITING_SHIPMENT: 'primary',
    SHIPMENT_SHIPPING_LABEL_REQUIRED: 'primary'
};

export const NotificationMenuDisplayTypeIcon = ({
    className,
    displayType,
    sx = {}
}: {
    readonly className?: string;
    readonly displayType: UserInterfaceNotificationMenuItemTypes;
    readonly sx?: SxProps;
}) => {
    const IconComponent = NOTIFICATION_TYPE_ICONS[displayType];
    const color = NOTIFICATION_TYPE_ICON_COLORS[displayType];

    if (!IconComponent) {
        return null;
    }

    return <IconComponent className={className} color={color} sx={sx} />;
};
