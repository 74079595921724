import React, { useCallback, useMemo } from 'react';
import { default as NextImage } from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import styled from '@emotion/styled';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { Image, ListingGraph, PurchaseOfferGraph, PurchaseOfferListingGraph } from '@bladebinge/types';
import { slugFromIdAndText } from '@bladebinge/web-service-common/src/utils/listings/slug-from-id-and-text';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { ItemQuantitySelector } from '../../atoms/ItemQuantitySelector';
import { ListingDisplayPrice } from '../../listing/ListingDisplayPrice';
import { ListingShippingOfferDescription } from '../../listing/ListingShippingOfferDescription';
import { OfferPriceEntryForm } from './OfferPriceEntryForm';

const StyledItemLink = styled(Link)(({ theme }) => ({
    textDecorationColor: theme.palette.primary.main
}));

// NOTE* - Line Items' price could differ from the current listing price, be sure to use unitPrice on the line item
export const PurchaseOfferListingDisplay = ({
    listing,
    confirmedOffer,
    urlRoot = '',
    isTradeListing = false,
    showRemoveAction = false
}: {
    readonly listing: ListingGraph;
    readonly confirmedOffer?: PurchaseOfferGraph;
    readonly urlRoot?: string;
    readonly isTradeListing?: boolean;
    readonly showRemoveAction?: boolean;
}) => {
    const { t } = useTranslation();
    const {
        offerListingDataByListingIdMap,
        offerTradeDataByListingIdMap,
        parentOffer,
        purchaseOfferSuccess,
        setOfferDataForListing,
        setTradeDataForListing,
        removeListingFromOffer,
        removeTradeFromOffer
    } = useMakeAnOfferContext();

    const isReadonly = Boolean(confirmedOffer);

    const removeItemFromOffer = useCallback(() => {
        if (!listing) {
            return;
        }

        removeListingFromOffer(listing);
        removeTradeFromOffer(listing);
    }, [listing, removeListingFromOffer, removeTradeFromOffer]);

    const offerPrice = useMemo(
        () =>
            isTradeListing
                ? ((confirmedOffer?.purchaseOfferTradeListings ?? []).find(
                      (offerListing) => offerListing.listingId === listing.id
                  )?.unitPrice ??
                  offerTradeDataByListingIdMap.get(listing.id)?.unitPrice ??
                  listing.price)
                : ((confirmedOffer?.purchaseOfferListings ?? []).find(
                      (offerListing) => offerListing.listingId === listing.id
                  )?.unitPrice ??
                  offerListingDataByListingIdMap.get(listing.id)?.unitPrice ??
                  listing.price),
        [
            confirmedOffer,
            isTradeListing,
            listing.id,
            listing.price,
            offerListingDataByListingIdMap,
            offerTradeDataByListingIdMap
        ]
    );

    const originalPurchaseOfferListing = useMemo(
        () =>
            (parentOffer?.purchaseOfferListings ?? []).find(
                ({ listingId }: PurchaseOfferListingGraph) => listingId === listing?.id
            ),
        [listing?.id, parentOffer]
    );

    const handleSelectQuantity = (quantity: number) => {
        const setMethod = isTradeListing ? setTradeDataForListing : setOfferDataForListing;
        setMethod(listing, {
            quantity,
            unitPrice: isTradeListing
                ? (offerTradeDataByListingIdMap.get(listing.id)?.unitPrice ?? listing.price)
                : (offerListingDataByListingIdMap.get(listing.id)?.unitPrice ?? listing.price)
        });
    };

    const displayQuantity = useMemo(() => {
        if (confirmedOffer) {
            return isTradeListing
                ? ((confirmedOffer?.purchaseOfferTradeListings ?? []).find(({ listingId }) => listingId === listing.id)
                      ?.quantity ?? 1)
                : ((confirmedOffer?.purchaseOfferListings ?? []).find(({ listingId }) => listingId === listing.id)
                      ?.quantity ?? 1);
        }

        return isTradeListing
            ? (offerTradeDataByListingIdMap?.get(listing.id)?.quantity ?? 1)
            : (offerListingDataByListingIdMap?.get(listing.id)?.quantity ?? 1);
    }, [confirmedOffer, isTradeListing, listing.id, offerListingDataByListingIdMap, offerTradeDataByListingIdMap]);

    const isPriceAdjusted = useMemo(
        () => listing?.price && listing?.price !== offerPrice,
        [listing?.price, offerPrice]
    );

    const showSoldOutNotice = confirmedOffer?.status === 'CANCELLED' && listing?.status === 'SOLD_OUT';

    if (!listing) {
        return null;
    }

    const { id: listingId, currency, images = [], quantityAvailable, price, title } = listing ?? {};
    const listingUrl = `${urlRoot}/listings/${slugFromIdAndText(listingId ?? '', title)}`;
    const primaryImage = images.find(({ isPrimary }: Image) => isPrimary);

    return (
        <Grid
            item
            container
            justifyContent="space-between"
            spacing={1}
            xs={12}
            sx={{
                position: 'relative',
                my: 1,
                px: 1,
                pt: 1,
                pb: 2,
                backgroundColor: 'rgba(255,255,255, 0.05)'
            }}
            direction={isTradeListing ? 'row-reverse' : 'row'}
        >
            {showRemoveAction && (
                <Box sx={{ position: 'absolute', top: '-12px', right: '-12px' }}>
                    <IconButton
                        aria-label={t('common:purchase_offer.remove_item_from_offer')}
                        color="inherit"
                        edge="start"
                        onClick={removeItemFromOffer}
                        title={t('common:purchase_offer.remove_item_from_offer')}
                        size="small"
                    >
                        <RemoveCircleOutlineOutlinedIcon />
                    </IconButton>
                </Box>
            )}
            <Grid item container xs={12} sm={7} md={8} direction={isTradeListing ? 'row-reverse' : 'row'}>
                {primaryImage && (
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                position: 'relative',
                                aspectRatio: 1,
                                width: '100%',
                                mt: 1,
                                maxHeight: '100px',
                                maxWidth: '100px'
                            }}
                        >
                            <Link href={listingUrl} style={{ marginRight: '4px', padding: '4px' }}>
                                <NextImage alt={title} src={getCdnUrl(primaryImage.thumbnailUrl)} fill priority />
                            </Link>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={9} sx={{ paddingTop: '0 !important', pl: 1 }}>
                    <StyledItemLink href={listingUrl}>
                        <Typography
                            variant="body1"
                            color="primary"
                            component="div"
                            sx={{
                                mt: 0,
                                pt: 0
                            }}
                        >
                            {title}
                        </Typography>
                    </StyledItemLink>
                    {showSoldOutNotice && (
                        <Typography variant="caption" color="warning" component="div" sx={{ fontStyle: 'italic' }}>
                            {t('common:listing.this_item_is_no_longer_available')}
                        </Typography>
                    )}
                    <ListingDisplayPrice
                        price={price}
                        currency={currency}
                        variant="body1"
                        component="p"
                        fontSize="1em"
                        color="contrastText"
                        sx={{
                            ...(isPriceAdjusted
                                ? {
                                      textDecoration: 'line-through',
                                      textDecorationColor: 'rgba(225,225,225, 0.5)'
                                  }
                                : {})
                        }}
                    />
                    <ListingShippingOfferDescription
                        listing={listing}
                        noticesVariant="caption"
                        shipFromVariant="caption"
                    />
                </Grid>
            </Grid>
            {quantityAvailable > 1 && (
                <Grid item xs={12} sm={2} md={2}>
                    <ItemQuantitySelector
                        fullWidth
                        defaultSelection={displayQuantity}
                        disabled={isReadonly || quantityAvailable < 1 || purchaseOfferSuccess}
                        itemId={listingId}
                        max={isReadonly ? displayQuantity : quantityAvailable}
                        onSelect={handleSelectQuantity}
                        zeroLabel={`0 - (${t('common:general.delete')})`}
                    />
                    {originalPurchaseOfferListing && (
                        <Typography variant="caption" component="div">
                            {t('common:purchase_offer.counter_offer_original_quantity', {
                                quantity: originalPurchaseOfferListing.quantity
                            })}
                        </Typography>
                    )}
                </Grid>
            )}
            <Grid item xs={12} sm={2} md={2}>
                {isReadonly ? (
                    <ListingDisplayPrice
                        price={offerPrice}
                        currency={listing?.currency}
                        variant="body1"
                        component="p"
                        fontSize="1.25em"
                    />
                ) : (
                    <OfferPriceEntryForm
                        isReadonly={isReadonly}
                        listingGraph={listing}
                        originalPurchaseOfferListing={originalPurchaseOfferListing}
                        isTradeListing={isTradeListing}
                    />
                )}
            </Grid>
        </Grid>
    );
};
