import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import { ListingGraph } from '@bladebinge/types';
import { StyledFormMessagingWrapper } from '../../styled-shared';
import { useMessageImagesContext } from '../../../context/image-uploads/message-images-context';
import { MessageThreadWrapper } from '../../my-bladebinge/messaging/MessageThreadWrapper';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { useMe } from '../../../context/me/me-context';
import { PurchaseOfferListingDisplay } from './PurchaseOfferListingDisplay';
import { PurchaseOfferAddSellerBundleSection } from './PurchaseOfferAddSellerBundleSection';
import { PurchaseOfferShippingForm } from './PurchaseOfferShippingForm';
import { CompleteOfferButtons } from './CompleteOfferButtons';
import { CreateOfferFormTotals } from './CreateOfferFormTotals';
import { OfferTradesSection } from './OfferTradesSection';
import { PurchaseOfferAddMessageForm } from './PurchaseOfferAddMessageForm';
import { CounterOfferAddMoreItemsSection } from './CounterOfferAddMoreItemsSection';

const StyledDrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
}));

const StyledChevronIcon = styled(ChevronLeftIcon)(({ theme }) => ({
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : ''
}));

export const PurchaseOfferDrawer = () => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();
    const { setDetachedImageUploads } = useMessageImagesContext();
    const {
        isOfferingEnabled,
        isTradingEnabled,
        clearOffer,
        completedOffer,
        firstMessage,
        hasOffer,
        isDrawerOpen,
        offerListings,
        offerTradeListings,
        parentOfferId,
        purchaseOfferSuccess,
        purchaseOfferError,
        resetPurchaseOfferMutation,
        setIsDrawerOpen,
        offeredToUserContext
    } = useMakeAnOfferContext();

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const isCounterOffer = useMemo(() => Boolean(parentOfferId), [parentOfferId]);
    const listingOfferMarkup = useMemo(
        () =>
            Array.from(offerListings.values()).map((listing: ListingGraph) => (
                <PurchaseOfferListingDisplay
                    key={listing.id}
                    listing={listing}
                    showRemoveAction={!purchaseOfferSuccess}
                />
            )),
        [offerListings, purchaseOfferSuccess]
    );

    const tradeOfferMarkup = useMemo(
        () =>
            isTradingEnabled && offerTradeListings.size > 0
                ? Array.from(offerTradeListings.values()).map((listing: ListingGraph) => (
                      <PurchaseOfferListingDisplay key={listing.id} listing={listing} isTradeListing showRemoveAction />
                  ))
                : null,
        [isTradingEnabled, offerTradeListings]
    );

    const allowActiveEdits = !purchaseOfferSuccess && !purchaseOfferError;

    if (!isOfferingEnabled) {
        return null;
    }

    return (
        <Box sx={{ position: 'relative' }}>
            {hasOffer && !isDrawerOpen && (
                <Fab
                    color="primary"
                    onClick={() => setIsDrawerOpen(true)}
                    size="small"
                    sx={{
                        position: 'fixed',
                        zIndex: 1,
                        top: {
                            xs: '60px',
                            sm: '70px'
                        },
                        right: '5px'
                    }}
                    title="open offer"
                >
                    <LocalOfferIcon />
                </Fab>
            )}
            <Drawer
                anchor="right"
                open={isDrawerOpen}
                elevation={3}
                ModalProps={{
                    onClose: () => setIsDrawerOpen(false),
                    disableScrollLock: false,
                    BackdropProps: { invisible: false }
                }}
                sx={{
                    position: 'relative'
                }}
            >
                <Box sx={{ display: 'block', position: 'relative' }}>
                    <Paper
                        className="sticky-paper-top"
                        elevation={1}
                        sx={{
                            borderRadius: 0,
                            width: {
                                xs: '95vw',
                                sm: '90vw'
                            },
                            zIndex: 1,
                            WebkitTransform: 'translateZ(1px)',
                            MozTransform: 'translateZ(1px)',
                            transform: 'translateZ(1px)'
                        }}
                    >
                        <StyledDrawerHeader>
                            <IconButton
                                onClick={handleDrawerClose}
                                aria-label={t('common:navigation.close_filters_drawer')}
                                title={t('common:navigation.close_filters_drawer')}
                                size="large"
                            >
                                <StyledChevronIcon />
                            </IconButton>
                            <Typography variant="h5" component="div" sx={{ ml: 0.5 }}>
                                {parentOfferId
                                    ? t('common:purchase_offer.counter_offer')
                                    : t('common:purchase_offer.make_an_offer_heading')}
                            </Typography>
                        </StyledDrawerHeader>
                        <Box sx={{ p: 4 }}>
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={12}>
                                    <CreateOfferFormTotals includeDetailedBreakdown={false} />
                                </Grid>
                                {!purchaseOfferSuccess && <CompleteOfferButtons size="small" textAlign="right" />}
                                {purchaseOfferSuccess && (
                                    <Grid item xs={12}>
                                        <StyledFormMessagingWrapper>
                                            <Alert
                                                onClose={() => {
                                                    setDetachedImageUploads([]);
                                                    clearOffer();
                                                }}
                                                severity="success"
                                            >
                                                {t('common:purchase_offer.offer_sent')}
                                            </Alert>
                                        </StyledFormMessagingWrapper>
                                    </Grid>
                                )}
                                {purchaseOfferError && (
                                    <Grid item xs={12}>
                                        <StyledFormMessagingWrapper>
                                            <Alert onClose={() => resetPurchaseOfferMutation()} severity="error">
                                                {purchaseOfferError?.message}
                                            </Alert>
                                        </StyledFormMessagingWrapper>
                                    </Grid>
                                )}
                                <Grid item xs={11} container sx={{ mb: 2 }}>
                                    <Typography variant="h6" component="div">
                                        {t('common:purchase_offer.offer_items')}
                                    </Typography>
                                    {listingOfferMarkup}
                                </Grid>
                                {isTradingEnabled && tradeOfferMarkup !== null && (
                                    <Grid
                                        item
                                        xs={11}
                                        container
                                        sx={{
                                            mb: 2,
                                            borderTopColor: 'divider',
                                            borderTopSize: '1px',
                                            borderTopStyle: 'solid'
                                        }}
                                    >
                                        <Typography variant="h6" component="div">
                                            {t('common:purchase_offer.trade_items')}
                                        </Typography>
                                        {tradeOfferMarkup}
                                    </Grid>
                                )}
                                {allowActiveEdits && (
                                    <>
                                        {!isCounterOffer && offeredToUserContext?.offeredToUserProfileId && (
                                            <PurchaseOfferAddSellerBundleSection
                                                key={offeredToUserContext.offeredToUserProfileId}
                                            />
                                        )}
                                        {!isCounterOffer && <OfferTradesSection />}
                                        {isCounterOffer && <CounterOfferAddMoreItemsSection key={loggedInUserId} />}
                                        {isCounterOffer && (
                                            <PurchaseOfferAddSellerBundleSection isRequestingTradesForCounter />
                                        )}
                                        <PurchaseOfferShippingForm />
                                        <PurchaseOfferAddMessageForm allowActiveEdits={allowActiveEdits} />
                                        <CompleteOfferButtons size="small" />
                                    </>
                                )}
                                {firstMessage && completedOffer?.id && (
                                    <Grid item xs={12}>
                                        <MessageThreadWrapper key={completedOffer.id} threadMessage={firstMessage} />
                                    </Grid>
                                )}
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <CreateOfferFormTotals includeDetailedBreakdown={false} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Drawer>
        </Box>
    );
};
