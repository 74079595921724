export const OFF_PLATFORM_FIND_TERMS = [
    ' ig ',
    ' pp',
    ' ppff',
    ' ppgs',
    ' gw',
    '@',
    'aol.com',
    'att.net',
    'avoid fees',
    'avoid the fees',
    'call me',
    'cash app',
    'cashapp',
    'comcast.net',
    'dotcom',
    'dotnet',
    'ebay',
    'facebook.com',
    'seller fees',
    'gmail.com',
    'hotmail.com',
    'icloud.com',
    'insta ',
    'instagram',
    'knifeswap',
    'msn.com',
    'msn.com',
    'off of the site',
    'off the site',
    'off site',
    'my number',
    'paypal',
    'seller fees',
    'sms',
    'text me',
    'text ',
    'venmo',
    'verizon.net',
    'yahoo.com',
    'zelle'
];

export const extractEmailsFromMessage = (message: string) =>
    message.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
// disabling for now due to false positives
// const extractPhoneNumbers = (message: string) =>
//     message.match(/(\d{3}[-\.\s]??\d{3}[-\.\s]??\d{4}|\(\d{3}\)\s*\d{3}[-\.\s]??\d{4}|\d{3}[-\.\s]??\d{4})/gi);

export const isOffPlatformMessage = (message: string | null | undefined = ''): boolean => {
    if (!message) {
        return false; // may be null
    }

    const lowerCaseMessage = message.toLocaleLowerCase();

    const includesOffPlatformFindTerms = Boolean(
        OFF_PLATFORM_FIND_TERMS.some((term) => lowerCaseMessage.includes(term))
    );

    const messageEmails = extractEmailsFromMessage(lowerCaseMessage);
    const hasEmails = Boolean(messageEmails && messageEmails.length > 0);

    // const messagePhoneNumbers = extractPhoneNumbers(lowerCaseMessage);
    // const hasPhoneNumbers = Boolean(messagePhoneNumbers && messagePhoneNumbers.length > 0);

    const isOffplatform = includesOffPlatformFindTerms || hasEmails;

    return isOffplatform;
};
