import React from 'react';
import { useHasMounted } from '../hooks/use-has-mounted';
import { useMe } from '../context/me/me-context';
import { useUserPreferences } from '../context/user-preferences/user-preferences-context';
import { ReConfirmUserTermsModal } from './FormComponents/ReConfirmUserTermsModal';
import { RequiredPreferenceSettingsModal } from './FormComponents/feature-opt-in-modals/RequiredPreferenceSettingsModal';

export const RequiredUserInteractionModals = () => {
    const { id: loggedInUserId, isLoggedInUserFetched } = useMe();
    const { persistedUserPreferencesFetched } = useUserPreferences();
    const hasMounted = useHasMounted();

    if (!isLoggedInUserFetched || !loggedInUserId || !persistedUserPreferencesFetched || !hasMounted) {
        return null;
    }

    return (
        <>
            <ReConfirmUserTermsModal />
            <RequiredPreferenceSettingsModal />
        </>
    );
};
