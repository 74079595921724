import { BulkIsReadMapping, MessageGraph, MessageRecipient } from '@bladebinge/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { useUserInterfaceNotificationsContext } from '../../../context/user-interface-notifications/user-interface-notifications-context';
import { bulkReadStatusRequest } from '../../../server/api-proxy/messages';

const bulkUpdateReadStatusMutateFn = async ({
    bulkIsReadMapping = {},
    userId
}: {
    bulkIsReadMapping: BulkIsReadMapping;
    userId?: string | null;
}): Promise<{ messageRecipients: MessageRecipient[] }> => {
    if (!userId) {
        throw new Error('A userid is required to update read status');
    }

    const updateData = await bulkReadStatusRequest({ bulkIsReadMapping, userId });
    const { error: { message: updateReadStatusError = '' } = {} } = updateData;

    if (updateReadStatusError) {
        throw new Error(updateReadStatusError);
    }

    return updateData;
};

export const useBulkUpdateReadStatus = ({ userId }: { userId?: string | null }) => {
    const queryClient = useQueryClient();
    const { invalidateNotificationsCounts } = useUserInterfaceNotificationsContext();
    const { query } = useRouter();

    return useMutation({
        mutationFn: bulkUpdateReadStatusMutateFn,
        onSuccess(data, variables, context) {
            invalidateNotificationsCounts();

            const { messageRecipients: updatedMessageRecipients = [] } = data ?? {};

            if (updatedMessageRecipients.length > 0) {
                queryClient.setQueryData<{ result: MessageGraph[]; _limit: number; _total: number; _offset: number }>(
                    uiCacheKeyBuilderMap.receivedMessages({ query, userId }),
                    // @ts-expect-error
                    (oldCacheData) => {
                        if (!oldCacheData) {
                            return oldCacheData;
                        }

                        const { result: oldMessagesInCache = [], ...restOfOldCacheData } = oldCacheData ?? {};

                        if (!Array.isArray(oldMessagesInCache) || oldMessagesInCache.length === 0) {
                            return oldCacheData;
                        }

                        const updatedResult = oldMessagesInCache.map((message) => {
                            const updatedRecipientMessages = updatedMessageRecipients.filter(
                                (recipient) => recipient.messageId === message.id
                            );

                            if (updatedRecipientMessages.length > 0) {
                                return {
                                    ...message,
                                    // @ts-expect-error
                                    recipients: message.recipients.map((recipient) => {
                                        const updatedRecipient = updatedRecipientMessages.find(
                                            (updatedRecipient) => updatedRecipient.id === recipient.id
                                        );
                                        if (updatedRecipient) {
                                            return updatedRecipient;
                                        }

                                        return recipient;
                                    })
                                };
                            }

                            return message;
                        });

                        return {
                            ...restOfOldCacheData,
                            result: updatedResult
                        };
                    }
                );
            }
        }
    });
};
