import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { getGlobalStaticFeatureEnablement } from '@bladebinge/web-service-common/src/feature-flags/get-global-static-feature-enablement';
import { TermsOfServiceText } from '../atoms/TermsOfServiceText';
import { StyledFormLegalContentWrap } from '../styled-shared';
import { useMe } from '../../context/me/me-context';
import { updateUserTermsAgreement } from '../../server/api-proxy/ui-mutation-fns/update-terms-agreement';

export const ReConfirmUserTermsModal = () => {
    const { t } = useTranslation();
    const {
        id: meId,
        logoutUser,
        userAgreedToCurrentTerms,
        requiredTermsVersion,
        updateUserTermsInActiveProfile
    } = useMe();

    const [showTerms, setShowTerms] = useState<boolean>(false);
    const autoUpdateTermsEnabled = getGlobalStaticFeatureEnablement('EnableAutoTermsUpdate', meId);

    const changeSummaryKey = `legal:latest_term_change_summaries.${requiredTermsVersion}`;
    const termsUpdateDescription = t(changeSummaryKey);

    const updateTermsMutation = useMutation({
        mutationFn: updateUserTermsAgreement,
        onSuccess(data) {
            if (data?.termsVersion === requiredTermsVersion) {
                updateUserTermsInActiveProfile(data);
            }
        }
    });

    const { error: termsError } = updateTermsMutation;

    const agreeAndClose = () => {
        if (!meId) {
            return;
        }

        updateTermsMutation.mutate({ userId: meId });
    };

    const disagreeAndLogout = async () => {
        await logoutUser();
    };

    if (!autoUpdateTermsEnabled) {
        return null;
    }

    if (!meId || userAgreedToCurrentTerms || userAgreedToCurrentTerms === null) {
        return null;
    }

    return (
        <Modal
            aria-label={t('common:navigation.terms_of_service')}
            open={!userAgreedToCurrentTerms}
            closeAfterTransition
            components={{
                Backdrop
            }}
            sx={{ overflow: 'scroll' }}
        >
            <StyledFormLegalContentWrap>
                {termsError && (
                    <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Alert severity="error">{termsError?.message}</Alert>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Alert severity="info">
                            <Grid container>
                                <Grid item>
                                    <Typography variant="h5" component="div">
                                        {t('common:auth.please_review_our_terms_of_service_as_they_have_changed')}
                                    </Typography>
                                    <Typography variant="body1">
                                        {t('common:auth.please_agree_to_the_new_terms_to_continue')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Alert>
                    </Grid>
                    {termsUpdateDescription && termsUpdateDescription !== changeSummaryKey && (
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div">
                                {t('common:auth.terms_update_summary')}
                            </Typography>
                            <Typography variant="body2">{termsUpdateDescription}</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container justifyContent="center" spacing={1} sx={{ my: 1 }}>
                    <Grid item xs={12}>
                        <Grid item sx={{ textAlign: 'center' }}>
                            <Button color="secondary" onClick={() => setShowTerms(!showTerms)} variant="contained">
                                {showTerms ? t('common:auth.hide_terms') : t('common:auth.show_terms')}
                            </Button>
                        </Grid>
                        {showTerms && <TermsOfServiceText titleVariant="h4" />}
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item sx={{ textAlign: 'center', mt: 2 }} xs={12}>
                        <hr />
                        <Button color="primary" onClick={agreeAndClose} variant="contained" size="large">
                            {t('common:auth.i_agree')}
                        </Button>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', mt: 2 }} xs={12}>
                        <Button color="primary" onClick={disagreeAndLogout} variant="text" size="large">
                            {t('common:auth.no_thanks_log_me_out')}
                        </Button>
                    </Grid>
                </Grid>
            </StyledFormLegalContentWrap>
        </Modal>
    );
};
