import React from 'react';
import { useTranslation } from 'next-i18next';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { ListingGraph } from '@bladebinge/types';

export const ListingTileFreeShippingIndicator = ({ listing }: { readonly listing: ListingGraph }) => {
    const { t } = useTranslation();

    const freeShipDom = listing?.offersSellerPaidDomesticShipping;
    const freeShipInt = listing?.offersSellerPaidInternationalShipping;

    if (!freeShipDom && !freeShipInt) {
        return null;
    }

    const shipFromCountry = listing?.shipFromData?.country ? listing.shipFromData.country : '';
    const freeDomText = freeShipDom ? t('common:listing.free_domestic_shipping', { country: shipFromCountry }) : '';
    const freeIntText = freeShipInt ? t('common:listing.free_international_shipping') : '';

    const titleText = `${freeDomText} ${freeIntText}`;

    return (
        <span title={titleText} style={{ marginLeft: '2px', marginRight: '2px' }}>
            <LocalShippingIcon
                sx={{
                    verticalAlign: 'middle',
                    color: 'info.dark',
                    fontSize: 'inherit'
                }}
                component="svg"
            />
        </span>
    );
};
