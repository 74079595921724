import type { Rating } from '@bladebinge/types';

// returns raw values out of ten. Must be converted to stars out of 5 for display
export const getAverageRating = (ratings: Rating[] = []): number => {
    if (ratings.length === 0) {
        return -1;
    }

    // rating may be a string here.  Forcing type conversion intentionally
    const ratingTotal: number = ratings
        .map(({ rating }) => parseInt(`${rating}`, 10))
        .reduce((total, rating) => total + rating, 0);

    // ratings are out of 10 but we use 5-star scale
    return ratingTotal / ratings.length;
};
