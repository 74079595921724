import { differenceInDays, differenceInHours, differenceInWeeks, parseISO } from 'date-fns';

export const validateItemAge = ({
    comparisonUnit,
    dateOrDateString,
    operation,
    overrideNow,
    unitQuantity
}: {
    comparisonUnit: 'hours' | 'days' | 'weeks';
    dateOrDateString?: string | Date;
    operation: 'greaterThan' | 'lessThan' | 'lessThanOrEqualTo' | 'greaterThanOrEqualTo';
    overrideNow?: Date; // only used for testing
    unitQuantity: number;
}) => {
    if (!dateOrDateString) {
        return false;
    }

    const date = typeof dateOrDateString === 'string' ? parseISO(dateOrDateString) : dateOrDateString;
    const now = overrideNow ? overrideNow : new Date(new Date().toISOString());

    let difference: number;
    switch (comparisonUnit) {
        case 'hours': {
            difference = differenceInHours(now, date);
            break;
        }

        case 'days': {
            difference = differenceInDays(now, date);
            break;
        }

        case 'weeks': {
            difference = differenceInWeeks(now, date);
            break;
        }

        default: {
            throw new Error('Invalid comparison unit');
        }
    }

    switch (operation) {
        case 'greaterThan': {
            return difference > unitQuantity;
        }

        case 'lessThan': {
            return difference < unitQuantity;
        }

        case 'lessThanOrEqualTo': {
            return difference <= unitQuantity;
        }

        case 'greaterThanOrEqualTo': {
            return difference >= unitQuantity;
        }

        default: {
            return false;
        }
    }
};
