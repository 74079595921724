import { useQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { PurchaseOfferGraph } from '@bladebinge/types';
import { normalizedFetch } from '../../../server/api-proxy/utils/normalized-fetch';
import { PROXY_ROUTES } from '../../../server/api-proxy/ui-proxy-routes';

const fetchLoggedInUserPurchaseOfferCheckoutDataFn =
    ({
        loggedInUserId,
        purchaseOfferId,
        signal
    }: {
        loggedInUserId?: string | null;
        purchaseOfferId?: string | null;
        signal?: AbortSignal;
    }) =>
    async (): Promise<PurchaseOfferGraph> => {
        if (!loggedInUserId || !purchaseOfferId) {
            throw new Error('User ID and purchaseOfferId are required for checkout');
        }

        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchLoggedInUserPurchaseOfferCheckoutData.build({
                loggedInUserId,
                purchaseOfferId
            }),
            options: {
                method: 'GET',
                ...(signal ? { signal } : {})
            }
        });

        const { error: { message: asyncError = '' } = {} } = response as { error?: { message: string } };

        if (asyncError) {
            throw new Error(asyncError);
        }

        return response;
    };

export const useLoggedInUserPurchaseOfferCheckoutData = ({
    loggedInUserId,
    purchaseOfferId
}: {
    loggedInUserId?: string | null;
    purchaseOfferId: string | null;
}) =>
    useQuery({
        queryKey: uiCacheKeyBuilderMap.loggedInUserPurchaseOfferCheckoutData({ loggedInUserId, purchaseOfferId }),
        queryFn: fetchLoggedInUserPurchaseOfferCheckoutDataFn({
            loggedInUserId,
            purchaseOfferId
        }),
        retry: false,
        enabled: typeof loggedInUserId === 'string' && typeof purchaseOfferId === 'string'
    });
