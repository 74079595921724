import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Crop } from 'react-image-crop';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ImageCropper } from './ImageCropper';
import type { VerifiedPercentageCrop } from './utils/types';
import type { ImageDataWithCropInfo, ImageUpload } from './types';

interface PercentCropMap {
    [filePath: string]: Crop;
}

interface DataMapByPath {
    [filePath: string]: ImageUpload;
}

export const CropGrid = ({
    rawImageFiles = [],
    onCancel,
    onSave
}: {
    readonly rawImageFiles: ImageUpload[];
    readonly onCancel: () => void;
    readonly onSave: (data: ImageDataWithCropInfo[]) => void;
}) => {
    const { t } = useTranslation();
    // todo map image data
    const [percentCrops, setPercentCrops] = useState<PercentCropMap>({});
    const [processedUploads, setProcessedUploads] = useState<DataMapByPath>({});

    useEffect(() => {
        // have to reset if raw data updates
        setProcessedUploads(
            rawImageFiles.reduce((acc, { path, dataUri }) => {
                acc[path] = {
                    dataUri,
                    path
                };
                return acc;
            }, {} as DataMapByPath)
        );
    }, [rawImageFiles, setProcessedUploads]);

    if (rawImageFiles.length === 0) {
        return null;
    }

    const onCropperUpdateImage = ({ path, dataUri }: { path: string; dataUri: string }) => {
        setProcessedUploads((prevData) => {
            prevData[path] = {
                path,
                dataUri
            };
            return prevData;
        });
    };

    // TODO need to adjust so crop data is saved with processed uploads info
    const saveCrops = () => {
        const imageDataWithCrops: ImageDataWithCropInfo[] = Object.keys(processedUploads).map((path: string) => {
            (processedUploads[path] as ImageDataWithCropInfo).crop = percentCrops[path] as VerifiedPercentageCrop;
            return processedUploads[path] as ImageDataWithCropInfo;
        });

        onSave(imageDataWithCrops);
    };

    return (
        <Grid container>
            <Grid container justifyContent="flex-start" spacing={2}>
                {Object.values(processedUploads).map(({ dataUri, path = '' }: ImageUpload) => (
                    <Grid item key={`${path}_grid_item`} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ImageCropper
                            path={path}
                            src={dataUri ?? ''}
                            onComplete={(_crop: Crop, percentage: Crop) => {
                                setPercentCrops((prevPercentCrops) => ({
                                    ...prevPercentCrops,
                                    [path]: percentage
                                }));
                            }}
                            onUpdateImage={onCropperUpdateImage}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button onClick={onCancel} variant="contained">
                        {t('common:images.image_editor.change_images')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button id="save_cropped_images_btn" onClick={saveCrops} variant="contained" color="primary">
                        {t('common:images.image_editor.save_cropped_images')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
