import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { default as NextImage } from 'next/image';
import { useTranslation } from 'next-i18next';
import debounce from 'lodash.debounce';
import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutlineSharp';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { Image, UserProfileEntity } from '@bladebinge/types';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { fetchAutocompleteByUsernameResults } from '../../server/api-proxy/user-profiles';
import { UNMOUNT_ABORT } from '../../utils/constants';
import { useSearchFilterContext } from '../../context/search-filter/search-filter-context';

const StyledDropdownPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3, 2),
    boxShadow: theme.shadows[3],
    width: '150%',
    marginLeft: '5%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.divider
}));

const TagLabel = ({
    onlyShowBladebingeOfficialPostings = false,
    profile,
    truncate = false,
    ...props
}: {
    readonly onlyShowBladebingeOfficialPostings?: boolean;
    readonly profile: UserProfileEntity;
    readonly truncate?: boolean;
}) => {
    const { username, avatar = {} } = profile ?? {};
    const { thumbnailUrl = '' } = (avatar ?? {}) as Image;
    const imageUrl = getCdnUrl(thumbnailUrl);
    return (
        <Grid
            key={username}
            {...props}
            container
            justifyContent="flex-start"
            sx={{
                cursor: onlyShowBladebingeOfficialPostings ? 'inherit' : 'pointer',
                '&:hover': {
                    backgroundColor: 'palette.divider'
                }
            }}
            {...props}
        >
            <Grid item>
                <Avatar
                    sx={{
                        position: 'relative',
                        left: '-6px',
                        width: '20px',
                        height: '20px',
                        mr: 0.5,
                        fontSize: '1.7em',
                        textAlign: 'center',
                        display: 'inline-block',
                        verticalAlign: 'middle'
                    }}
                >
                    {imageUrl ? <NextImage src={imageUrl} alt={username} fill priority /> : username.charAt(0)}
                </Avatar>
                <Typography
                    component="div"
                    sx={{
                        display: 'inline-block',
                        ...(truncate
                            ? {
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '55%'
                              }
                            : {}),
                        verticalAlign: 'middle'
                    }}
                    variant="caption"
                    title={username}
                >
                    {username}
                </Typography>
            </Grid>
        </Grid>
    );
};

export const UsernameAutocompleteSearch = () => {
    const { t } = useTranslation();
    const {
        browseOnlyUserProfiles,
        OFFICIAL_BLADEBINGE_USERNAME,
        onlyShowBladebingeOfficialPostings,
        setBrowseOnlyUserProfiles,
        usernameQueryUIInputValue
    } = useSearchFilterContext();
    const [options, setOptions] = React.useState<readonly UserProfileEntity[]>(browseOnlyUserProfiles);
    const [searchString, setSearchString] = useState<string>(usernameQueryUIInputValue);
    const [loading, setLoading] = useState<boolean>(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        let active = true;
        const controller = new AbortController();
        const { signal } = controller;

        (async () => {
            if (loading || !searchString) {
                return;
            }

            setLoading(true);

            const results: UserProfileEntity[] = await fetchAutocompleteByUsernameResults(
                {
                    searchString,
                    onlyActiveSellers: true
                },
                signal
            );

            setLoading(false);

            if (active && results) {
                setOptions((results ?? []).concat(browseOnlyUserProfiles));
                if (searchString === usernameQueryUIInputValue && browseOnlyUserProfiles.length === 0) {
                    setBrowseOnlyUserProfiles(
                        (results ?? []).filter(({ username }) => username !== OFFICIAL_BLADEBINGE_USERNAME)
                    );
                }
            }
        })();

        return () => {
            active = false;
            controller.abort(UNMOUNT_ABORT);
        };
    }, [searchString]);

    const valHtml = useMemo(
        () =>
            browseOnlyUserProfiles.map((profile) => {
                if (!profile) {
                    return null;
                }

                const { username } = profile ?? {};

                return (
                    <Chip
                        key={username}
                        label={
                            <TagLabel
                                onlyShowBladebingeOfficialPostings={onlyShowBladebingeOfficialPostings}
                                profile={profile}
                                truncate
                            />
                        }
                        deleteIcon={<RemoveIcon />}
                        onDelete={() => {
                            const updatedProfiles = browseOnlyUserProfiles.filter(
                                ({ username: valUsername }) => valUsername !== username
                            );
                            setBrowseOnlyUserProfiles(updatedProfiles);
                        }}
                        size="small"
                        sx={{
                            m: 0.5
                        }}
                    />
                );
            }),
        [browseOnlyUserProfiles]
    );
    /* eslint-enable react-hooks/exhaustive-deps */

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        setSearchString(e.target.value ?? '');
    };

    const debouncedHandleInputChange = debounce(handleInputChange, 300);

    const updateChoices = (_e: React.SyntheticEvent, newVal: UserProfileEntity[]) => {
        const updatedChoices = Array.from(new Set(newVal));
        setBrowseOnlyUserProfiles(updatedChoices);
    };

    return (
        <Autocomplete
            id="profile-username-autocomplete"
            autoComplete
            color="secondary"
            disabled={onlyShowBladebingeOfficialPostings}
            filterSelectedOptions
            getOptionLabel={(option) => option.username}
            isOptionEqualToValue={(option, value) => option.username === value.username}
            loading={loading}
            multiple
            noOptionsText={
                searchString
                    ? t('common:filters.util.no_sellers_match', { search: searchString })
                    : t('common:general.no_results')
            }
            onChange={updateChoices}
            options={options}
            PaperComponent={StyledDropdownPaper}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        multiline: true,
                        onChange: debouncedHandleInputChange,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                        sx: {
                            fontSize: '1.2em',
                            py: 0.5,
                            m: 0
                        }
                    }}
                    label={t('common:filters.util.filter_sellers')}
                    aria-label={t('common:filters.util.filter_sellers')}
                    margin="normal"
                    variant="outlined"
                    size="small"
                />
            )}
            renderOption={(props, profile) => <TagLabel profile={profile} {...props} />}
            renderTags={(value: UserProfileEntity[]) => valHtml}
            value={browseOnlyUserProfiles}
            sx={{
                m: 0,
                paddingRight: '0 !important',
                opacity: onlyShowBladebingeOfficialPostings ? 0.6 : 1
            }}
        />
    );
};
