import React from 'react';
import { default as NextImage } from 'next/image';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'next-i18next';
import type { Image } from '@bladebinge/types';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import ButtonBase from '@mui/material/ButtonBase';

export const ImageGridItem = ({
    image,
    onClick,
    onRemove,
    selected = false
}: {
    readonly image: Image;
    readonly onClick?: (imageId: string) => void;
    readonly onRemove?: (imageId: string) => void;
    readonly selected?: boolean;
}) => {
    const { t } = useTranslation();

    return (
        <Grid item container spacing={2} xs={6} sm={3} sx={{ position: 'relative' }}>
            <Grid item xs={onRemove ? 10 : 12} sx={{ overflow: 'hidden' }}>
                {onClick ? (
                    <>
                        <ButtonBase
                            onClick={() => onClick(image.id)}
                            sx={{
                                position: 'relative',
                                width: '94%',
                                display: 'block',
                                cursor: 'pointer',
                                border: '5px solid',
                                borderColor: 'transparent',
                                // ...(selected ? {
                                //     borderColor: 'primary.main'
                                // } : {}),
                                '&:hover': {
                                    borderColor: selected ? 'divider' : 'primary.main'
                                }
                            }}
                        >
                            <NextImage alt={image.alt ?? ''} src={getCdnUrl(image?.thumbnailUrl)} fill priority />
                        </ButtonBase>
                        {selected && (
                            <IconButton
                                aria-label=""
                                onClick={() => onClick(image.id)}
                                sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: '0',
                                    right: '0',
                                    backgroundColor: 'background.paper'
                                }}
                                size="small"
                            >
                                <CheckCircleIcon color="info" sx={{}} />
                            </IconButton>
                        )}
                    </>
                ) : (
                    <img
                        alt={image.alt}
                        src={getCdnUrl(image?.thumbnailUrl)}
                        style={{
                            maxWidth: '100%'
                        }}
                    />
                )}
            </Grid>
            {onRemove && (
                <Grid item xs={2} sx={{ position: 'relative' }}>
                    <IconButton
                        aria-label={t('common:messaging.remove_image')}
                        onClick={() => onRemove(image.id)}
                        sx={{
                            position: 'absolute',
                            zIndex: 1,
                            top: '-14px',
                            right: '-1px'
                        }}
                        size="small"
                    >
                        <CancelOutlined color="error" />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};
