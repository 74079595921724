import type { FlaggableEntityTypes } from '@bladebinge/types';

export const MODERATION_FLAG_TYPES = [
    'CONTAINS_OUTSIDE_CONTACT_INFORMATION',
    'FRAUDULENT_OR_MISLEADING_INFORMATION',
    'ITEMS_NOT_IN_USER_POSSESSION',
    'MISLABELLED_MISCATEGORIZED',
    'OBSCENE_OFFENSIVE',
    'OUTSIDE_OF_KNIFE_TOOL_AND_ACCESSORY_NICHE',
    'POTENTIAL_COPYRIGHT_VIOLATION',
    'POTENTIAL_LEGAL_ISSUE',
    'POTENTIALLY_STOLEN_PROPERTY',
    'SPAM_OR_SOLICITATION',
    'VIOLATION_OF_SITE_POLICY',
    'OTHER'
];

export const FLAGGABLE_ENTITY_TYPES: FlaggableEntityTypes[] = [
    'IMAGE',
    'LISTING',
    'MESSAGE',
    'PURCHASE_OFFER',
    'ORDER',
    'ORDER_SHIPMENT',
    'RATING',
    'USER'
];
