import type { BladebingeFeatureFlag, BladebingeFeatureFlagEnablement } from '@bladebinge/types';
import { OFFICIAL_BLADEBINGE_USER_UUID } from '../constants/official-bladebinge';

// only exported so when left unused it doesn't throw  tsc error
export const OWNER_GATE_LIST = [
    'd95c48cd-e497-4a3e-9c24-bdcb38405194', // scotland dev
    '7f2bc3a4-5160-40dc-ae77-a389feb6416f', // scotty prod
    'f2c53c3d-4bf8-435f-bd11-b597a7db19a9', // eric dev
    'a660bb5f-3c9b-4272-a2f7-142a50288c40', // eric prod
    '2c4c1321-c1a7-4f47-898c-b77f93f23a31', // garrett dev
    '3c15d2b2-93f7-4a24-9ea2-bcc11e893d38', // garrett prod
    '09cc439f-4006-49f3-ab79-04405ec8b439', // joshua dev
    'ba8517d1-2ebd-48fb-a045-5f57e3e7abc1', // joshua prod
    OFFICIAL_BLADEBINGE_USER_UUID
];

const GlobalFeatures: { [key in BladebingeFeatureFlag]: BladebingeFeatureFlagEnablement } = {
    EnableOffers: {
        default: false,
        userGateList: OWNER_GATE_LIST
    },
    EnableTrades: {
        default: false,
        userGateList: OWNER_GATE_LIST
    },
    EnableLegallyRestrictedFilterCategories: {
        default: false
        // userGateList: undefined
    },
    EnableAutoTermsUpdate: {
        default: false
    }
} as const;

export const getGlobalStaticFeatureEnablement = (key: BladebingeFeatureFlag, loggedInUserId?: string | null) => {
    const defaultValue = GlobalFeatures?.[key]?.default;
    if (defaultValue === true) {
        return true;
    }

    const gateList = GlobalFeatures?.[key]?.userGateList;
    if (gateList) {
        return loggedInUserId ? gateList.includes(loggedInUserId) : false;
    }

    return GlobalFeatures?.[key]?.default === true;
};

export const getGlobalStaticFeatureIsEnabledByDefault = (key: BladebingeFeatureFlag) =>
    GlobalFeatures?.[key]?.default === true;
