import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { DropzoneArea } from 'react-mui-dropzone';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Cancel from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { StyledMarginYDiv } from '../../styled-shared';
import { readableBytes } from './utils/readable-bytes';
import { CroppedImageData } from './utils/types';
import { getBase64Strings } from './utils/get-base-64-strings';
import { getCroppedSquareImageBlobData } from './utils/get-cropped-square-image-blob-data';
import { CropGrid } from './CropGrid';
import type { ImageDataWithCropInfo, ImageUpload } from './types';

interface ImageUploaderProps {
    readonly clearOnSave?: boolean;
    readonly isUploadingImages?: boolean;
    readonly filesLimit: number;
    readonly onCancel?: () => void;
    readonly onSave: (cropBlobs: CroppedImageData[]) => void | Promise<void>;
    readonly remainingFilesCount?: number;
}

const truncateFilename = (fileName: string, strLen: number): string => {
    if (fileName.length <= strLen) {
        return fileName;
    }

    const separator = '...';
    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return `${fileName.slice(0, Math.max(0, frontChars))}${separator}${fileName.slice(
        Math.max(0, fileName.length - backChars)
    )}`;
};

export const ImageUploader = ({
    clearOnSave = false,
    isUploadingImages,
    filesLimit = 4,
    onCancel,
    onSave,
    remainingFilesCount
}: ImageUploaderProps) => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const dropRef = useRef<React.ReactNode>();
    const [isProcessingImageCrops, setIsProcessingImageCrops] = useState<boolean>(false);
    const [imageUploadsRotatedData, setImageUploadsRotatedData] = useState<ImageUpload[]>([]);
    const [remainingUploadsAvailableCount, setRemainingUploadsAvailableCount] = useState<number>(
        remainingFilesCount ?? filesLimit
    );
    // re-render hack to clear error message data after save
    const [key, setKey] = useState<number>(0);

    useEffect(() => {
        if (!hasMounted) {
            return;
        }

        setRemainingUploadsAvailableCount(remainingFilesCount ?? filesLimit);
    }, [filesLimit, hasMounted, remainingFilesCount]);

    const getFileLimitExceedMessage = () =>
        t('common:images.images_form.errors.file_limit_exceeded', { limit: remainingUploadsAvailableCount });
    const getFileAddedMessage = (fileName: string) =>
        `${t('common:images.images_form.form_messages.file_added_message', {
            fileName: truncateFilename(fileName, 16)
        })} \n`;
    const getFileRemovedMessage = (fileName: string) =>
        `${t('common:images.images_form.form_messages.file_removed_message', {
            fileName: truncateFilename(fileName, 16)
        })} \n`;
    const getDropRejectMessage = (rejectedFile: File, _allowedTypes: string | string[], sizeLimitBytes: number) =>
        t('common:images.images_form.errors.drop_rejected', {
            // @ts-expect-error
            fileName: truncateFilename(rejectedFile?.path, 20),
            allowedTypes: 'image files',
            sizeLimit: readableBytes(sizeLimitBytes)
        });

    const handleCancelCrop = () => {
        setImageUploadsRotatedData([]);
    };

    const onChange = async () => {
        // @ts-expect-error
        const rotatedImageData = await getBase64Strings(dropRef.current.state.fileObjects.map(({ file }) => file));
        setImageUploadsRotatedData(rotatedImageData);
    };

    const saveCroppedImages = async (imageDataWithCrops: ImageDataWithCropInfo[]) => {
        setIsProcessingImageCrops(true);
        const cropBlobs = await Promise.all(
            imageDataWithCrops.map((imageDataWithCrop) => getCroppedSquareImageBlobData(imageDataWithCrop))
        );
        setIsProcessingImageCrops(false);
        onSave(cropBlobs);

        if (clearOnSave) {
            setImageUploadsRotatedData([]);
            // re-render hack to reset error message related internal state
            window.setTimeout(() => {
                setKey(key + 1);
            }, 0);
        }
    };

    const uploader = (
        <DropzoneArea
            key={key}
            acceptedFiles={['image/*']}
            dropzoneText={t('common:images.image_editor.drag_images_here_or_click', {
                count: remainingUploadsAvailableCount
            })}
            filesLimit={remainingUploadsAvailableCount}
            getDropRejectMessage={getDropRejectMessage}
            getFileAddedMessage={getFileAddedMessage}
            getFileLimitExceedMessage={getFileLimitExceedMessage}
            getFileRemovedMessage={getFileRemovedMessage}
            inputProps={{
                // @ts-expect-error
                'data-testid': 'image_uploader_input'
            }}
            onChange={onChange}
            maxFileSize={6_000_000}
            ref={dropRef}
        />
    );

    const hasUploads = imageUploadsRotatedData.length > 0;
    // @ts-expect-error
    const hasImages = dropRef?.current?.state?.fileObjects && dropRef.current.state.fileObjects.length > 0;

    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <Alert icon={<AspectRatioIcon />} severity="info">
                    {t('common:images.upload_in_square_mode')}
                </Alert>
                {uploader}
                {isProcessingImageCrops ||
                    (isUploadingImages && (
                        <Grid item>
                            <LoadingIndicator />
                        </Grid>
                    ))}
                <StyledMarginYDiv>
                    {hasUploads && hasImages && !isProcessingImageCrops && (
                        <CropGrid
                            rawImageFiles={imageUploadsRotatedData}
                            onCancel={handleCancelCrop}
                            onSave={saveCroppedImages}
                        />
                    )}
                </StyledMarginYDiv>
                <Grid container justifyContent="flex-end" spacing={1} sx={{ my: 1 }}>
                    {onCancel && (
                        <Grid item>
                            <Button
                                aria-label={t('common:avatar_editor.cancel')}
                                color="inherit"
                                onClick={onCancel}
                                startIcon={<Cancel />}
                                sx={{ mt: 0.5 }}
                                title={t('common:avatar_editor.cancel')}
                                variant="outlined"
                            >
                                {t('common:avatar_editor.cancel')}
                            </Button>
                        </Grid>
                    )}
                    {!isUploadingImages && !hasUploads && hasImages && (
                        <Grid item>
                            <Button onClick={onChange} variant="contained" color="primary">
                                {t('common:images.image_editor.next_step')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
