import currency from 'currency.js';
import { FINANCING_PROCESSING_FEE } from '../constants/commission-rates';

export const calculateBuyerFinancingCharge = ({
    merchandiseTotal = 0,
    shippingTotal = 0
}: {
    merchandiseTotal?: number;
    shippingTotal?: number;
}) => currency(merchandiseTotal + shippingTotal).multiply(FINANCING_PROCESSING_FEE).value;

export const calculateOfferSettlementBuyerFinancingCharge = ({
    incomingUserBalanceDue = 0
}: {
    incomingUserBalanceDue?: number;
}) => currency(incomingUserBalanceDue).multiply(FINANCING_PROCESSING_FEE).value;
