import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'next-i18next';
import { DB_DESCRIPTION_MAX } from '@bladebinge/web-service-common/src/constants/database-constants';
import { Button } from '@mui/material';
import {
    StyledFullWidthForm,
    StyledHookFormErrorMessage,
    StyledModalCancelIcon,
    StyledModalPaper
} from '../../styled-shared';
import { includesOffPlatformMessagingFilter } from '../../../utils/includes-off-platform-payment-messaging-filter';
import { OffPlatformSalesActivityWarningModal } from '../../atoms/OffPlatformSalesActivityWarningModal';
import { useMessageImagesContext } from '../../../context/image-uploads/message-images-context';
import { SelectUserImagesForm } from '../images/SelectUserImagesForm';
import { DeatchedImageUploadsViewer } from '../images/DetachedImageUploadViewer';
import { useHasMounted } from '../../../hooks/use-has-mounted';

interface StandaloneMessageForm {
    standaloneMessageBody: string;
}

export const StandaloneMessageBox = ({
    defaultMessage = '',
    onSubmit,
    onUpdate,
    readonly
}: {
    readonly defaultMessage?: string;
    readonly onSubmit: ({ messageBody, imageIds }: { messageBody: string; imageIds?: string[] }) => void;
    readonly onUpdate?: ({ messageBody, imageIds }: { messageBody: string; imageIds?: string[] }) => void;
    readonly readonly?: boolean;
}) => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const { detachedImageUploads } = useMessageImagesContext();
    const [showUploadImagesModalContent, setShowUploadImagesModalContent] = useState<boolean>(false);

    const handleClickAddImages = () => {
        setShowUploadImagesModalContent(true);
    };

    const closeModal = () => setShowUploadImagesModalContent(false);

    const [hasFlaggedTerms, setHasFlaggedTerms] = useState<boolean>(false);
    const [warningCount, setWarningCount] = useState<number>(0);

    const {
        formState: { errors, isDirty },
        getValues,
        setValue,
        handleSubmit,
        register
    } = useForm<StandaloneMessageForm>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true,
        defaultValues: {
            standaloneMessageBody: defaultMessage
        }
    });

    useEffect(() => {
        setValue('standaloneMessageBody', defaultMessage);
    }, [defaultMessage, setValue]);

    // if detached image uploads are present, we will update offer context with the image ids
    useEffect(() => {
        if (!hasMounted || detachedImageUploads.length === 0) {
            return;
        }

        if (onUpdate) {
            onUpdate({
                messageBody: getValues('standaloneMessageBody'),
                imageIds: detachedImageUploads.map(({ id }) => id)
            });
            return;
        }

        onSubmit({
            messageBody: getValues('standaloneMessageBody'),
            imageIds: detachedImageUploads.map(({ id }) => id)
        });
    }, [detachedImageUploads, getValues, hasMounted, onSubmit, onUpdate]);

    const handleMessageSubmission = useCallback(
        (message: StandaloneMessageForm) => {
            if (!isDirty && detachedImageUploads.length === 0) {
                return;
            }

            // will update an existing Standalone message if onUpdate is provided
            if (onUpdate) {
                onUpdate({
                    messageBody: message.standaloneMessageBody,
                    imageIds: detachedImageUploads.map(({ id }) => id)
                });
                return;
            }

            onSubmit({
                messageBody: message.standaloneMessageBody,
                imageIds: detachedImageUploads.map(({ id }) => id)
            });
        },
        [detachedImageUploads, isDirty, onUpdate, onSubmit]
    );

    // hook form material UI register calls
    const { ref: messageBodyRef, ...messageBodyRest } = register('standaloneMessageBody', {
        maxLength: DB_DESCRIPTION_MAX,
        validate(message: string) {
            const { result: hasFlaggedTerms } = includesOffPlatformMessagingFilter(message);
            if (hasFlaggedTerms && warningCount === 0) {
                setHasFlaggedTerms(true);
                setWarningCount(warningCount + 1);
            }

            // empty messages are only allowed if attachments are associated
            // in that case we handle the empty message on the backend API
            return message ? true : detachedImageUploads.length > 0;
        }
    });

    const hasValidMessageContent = getValues('standaloneMessageBody').length > 0 || detachedImageUploads.length > 0;

    return (
        <StyledFullWidthForm onSubmit={handleSubmit(handleMessageSubmission)}>
            <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item xs={12}>
                    <DeatchedImageUploadsViewer />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={errors.standaloneMessageBody ? 'input-error' : ''}
                        disabled={readonly}
                        fullWidth
                        id="standaloneMessageBody"
                        inputRef={messageBodyRef}
                        label={readonly ? t('common:messaging.message') : t('common:messaging.add_message')}
                        placeholder={t('common:messaging.message')}
                        margin="normal"
                        minRows={1}
                        maxRows={5}
                        multiline
                        variant="outlined"
                        InputProps={{
                            endAdornment: readonly ? null : (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={t('common:messaging.attach_images')}
                                        onClick={handleClickAddImages}
                                    >
                                        <AddPhotoAlternateIcon
                                            sx={{
                                                color: 'inherit',
                                                '&:hover': { color: 'primary.dark' }
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        aria-label={t('common:messaging.submit_reply')}
                                        data-testid="send_reply_btn"
                                        disabled={!hasValidMessageContent}
                                        type="submit"
                                        color="primary"
                                    />
                                </InputAdornment>
                            )
                        }}
                        {...messageBodyRest}
                    />
                    <StyledHookFormErrorMessage
                        as="div"
                        errors={errors}
                        message={t('common:messaging.errors.message_error', { limit: DB_DESCRIPTION_MAX })}
                        name="messageBody"
                    />
                </Grid>
                {!readonly && (
                    <Grid item xs={3}>
                        <Button
                            className="submit-offer-message-btn"
                            color={onUpdate ? 'secondary' : 'primary'}
                            disabled={hasFlaggedTerms}
                            fullWidth
                            size="small"
                            sx={{
                                mt: 0.5
                            }}
                            type="submit"
                            variant="contained"
                        >
                            {onUpdate ? t('common:messaging.update_message') : t('common:messaging.add_message')}
                        </Button>
                    </Grid>
                )}
                <OffPlatformSalesActivityWarningModal
                    isOpen={hasFlaggedTerms}
                    onClose={() => setHasFlaggedTerms(false)}
                />
                <Modal
                    aria-label={t('common:messaging.message_form')}
                    open={showUploadImagesModalContent}
                    onClose={closeModal}
                    closeAfterTransition
                    components={{
                        Backdrop
                    }}
                >
                    <StyledModalPaper>
                        <StyledModalCancelIcon onClick={closeModal} />
                        <Grid container justifyContent="center">
                            <Grid item container>
                                <Grid item xs={12}>
                                    <SelectUserImagesForm onCloseForm={closeModal} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledModalPaper>
                </Modal>
            </Grid>
        </StyledFullWidthForm>
    );
};
