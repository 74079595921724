import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

export const NoResults = ({
    alternateActionComponent = null,
    alternateMessage,
    messageVariant = 'body1'
}: {
    readonly alternateActionComponent?: React.ReactNode | null;
    readonly alternateMessage?: string;
    readonly messageVariant?: 'body1' | 'body2' | 'h6' | 'h5' | 'caption';
}) => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12}>
                <Typography variant={messageVariant} component="div" sx={{ fontStyle: 'italic', textAlign: 'center' }}>
                    {alternateMessage ?? t('common:general.no_results')}
                </Typography>
            </Grid>
            {alternateActionComponent && (
                <Grid item xs={12} sx={{ textAlign: 'center', my: 1 }}>
                    {alternateActionComponent}
                </Grid>
            )}
        </Grid>
    );
};
