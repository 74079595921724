import { useQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { PROXY_ROUTES } from '../../../server/api-proxy/ui-proxy-routes';
import { normalizedFetch } from '../../../server/api-proxy/utils/normalized-fetch';

const fetchLoggedInUserActivePurchaseOfferListingIdsFn =
    ({ loggedInUserId }: { loggedInUserId?: string | null }) =>
    async (): Promise<string[]> => {
        if (!loggedInUserId) {
            return [];
        }

        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchLoggedInUserActivePurchaseOfferListingIds.build({ loggedInUserId }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
        const { error: { message: asyncError = '' } = {} } = response as { error?: { message: string } };

        if (asyncError) {
            // we expect a 404 if the user does not have a referrer. Just return false on error
            throw new Error(asyncError);
        }

        return response;
    };

export const useLoggedInUserActivePurchaseOfferListingIds = ({ loggedInUserId }: { loggedInUserId?: string }) =>
    useQuery<string[]>({
        enabled: Boolean(loggedInUserId),
        queryKey: uiCacheKeyBuilderMap.loggedInUserActivePurchaseOfferListingIds({
            loggedInUserId
        }),
        queryFn: fetchLoggedInUserActivePurchaseOfferListingIdsFn({ loggedInUserId }),
        placeholderData: (prev) => prev,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always'
    });
