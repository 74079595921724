import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'next-i18next';
import type { MessageFormState, MessageGraph, MessageRelatedEntity } from '@bladebinge/types';
import { DB_DESCRIPTION_MAX } from '@bladebinge/web-service-common/src/constants/database-constants';
import { useMe } from '../../context/me/me-context';
import { LoadingIndicator } from '../atoms/LoadingIndicator';
import {
    StyledFormMessagingWrapper,
    StyledFullWidthForm,
    StyledHookFormErrorMessage,
    StyledModalCancelIcon,
    StyledModalPaper
} from '../styled-shared';
import { useCreateMessage } from '../../hooks/react-query/messaging/create-message';
import { includesOffPlatformMessagingFilter } from '../../utils/includes-off-platform-payment-messaging-filter';
import { OffPlatformSalesActivityWarningModal } from '../atoms/OffPlatformSalesActivityWarningModal';
import { useMessageImagesContext } from '../../context/image-uploads/message-images-context';
import { SelectUserImagesForm } from './images/SelectUserImagesForm';
import { DeatchedImageUploadsViewer } from './images/DetachedImageUploadViewer';

export const MessageReplyBox = ({
    onSuccess,
    parentMessageId,
    relatedEntityId,
    relatedEntityType,
    recipientUserId,
    recipientUserGroupId,
    recipientUserProfileId
}: {
    readonly onSuccess?: (createdMessage: MessageGraph) => void;
    readonly parentMessageId: string;
    readonly relatedEntityId?: string;
    readonly relatedEntityType?: MessageRelatedEntity;
    readonly recipientUserId?: string;
    readonly recipientUserGroupId?: string;
    readonly recipientUserProfileId?: string;
}) => {
    const { t } = useTranslation();
    const { activeProfileId: creatorUserProfileId, id: creatorId } = useMe();
    const { detachedImageUploads, setDetachedImageUploads } = useMessageImagesContext();

    const [showUploadImagesModalContent, setShowUploadImagesModalContent] = useState<boolean>(false);

    const handleClickAddImages = () => {
        setShowUploadImagesModalContent(true);
    };

    const closeModal = () => setShowUploadImagesModalContent(false);

    const createMessageMutation = useCreateMessage({
        successCallback(createMessageResponse: MessageGraph) {
            if (createMessageResponse?.id) {
                setDetachedImageUploads([]);
                resetForm();
                if (onSuccess) {
                    onSuccess(createMessageResponse);
                }
            }
        }
    });

    const {
        isPending: isCreating,
        error: createMessageError,
        reset: resetCreateMessageMutation
    } = createMessageMutation;
    const [hasFlaggedTerms, setHasFlaggedTerms] = useState<boolean>(false);
    const [warningCount, setWarningCount] = useState<number>(0);

    const {
        formState: { errors, isDirty },
        getValues,
        handleSubmit,
        register,
        reset: resetForm
    } = useForm<MessageFormState>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true,
        defaultValues: {
            creatorId: creatorId ? creatorId : '',
            creatorUserProfileId: creatorUserProfileId ? creatorUserProfileId : '',
            messageBody: '',
            parentMessageId,
            ...(relatedEntityId ? { relatedEntityId } : {}),
            ...(relatedEntityType ? { relatedEntityType } : {}),
            ...(recipientUserId ? { recipientUserId } : {}),
            ...(recipientUserGroupId ? { recipientUserGroupId } : {}),
            ...(recipientUserProfileId ? { recipientUserProfileId } : {})
        }
    });

    const handleMessageSubmission = (message: MessageFormState) => {
        if (!isDirty && detachedImageUploads.length === 0) {
            return;
        }

        createMessageMutation.mutate({
            ...message,
            ...(detachedImageUploads.length > 0 ? { imageIds: detachedImageUploads.map((image) => image.id) } : {})
        });
    };

    // hook form material UI register calls
    const { ref: messageBodyRef, ...messageBodyRest } = register('messageBody', {
        maxLength: DB_DESCRIPTION_MAX,
        validate(message: string) {
            const { result: hasFlaggedTerms } = includesOffPlatformMessagingFilter(message);
            if (hasFlaggedTerms && warningCount === 0) {
                setHasFlaggedTerms(true);
                setWarningCount(warningCount + 1);
            }

            // empty messages are only allowed if attachments are associated
            // in that case we handle the empty message on the backend API
            return message ? true : detachedImageUploads.length > 0;
        }
    });

    const hasValidMessageContent = getValues('messageBody').length > 0 || detachedImageUploads.length > 0;

    return (
        <StyledFullWidthForm onSubmit={handleSubmit(handleMessageSubmission)}>
            <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item xs={12}>
                    <DeatchedImageUploadsViewer />
                </Grid>
                <Grid item xs={11} sm={10} md={9}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        autoComplete={t('common:messaging.reply')}
                        className={errors.messageBody ? 'input-error' : ''}
                        fullWidth
                        id="messageBody"
                        inputRef={messageBodyRef}
                        label={t('common:messaging.reply')}
                        placeholder={t('common:messaging.reply')}
                        margin="normal"
                        minRows={1}
                        maxRows={5}
                        multiline
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={t('common:messaging.attach_images')}
                                        onClick={handleClickAddImages}
                                    >
                                        <AddPhotoAlternateIcon
                                            sx={{
                                                color: 'inherit',
                                                '&:hover': { color: 'primary.dark' }
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        aria-label={t('common:messaging.submit_reply')}
                                        data-testid="send_reply_btn"
                                        disabled={isCreating || !hasValidMessageContent}
                                        type="submit"
                                        color="primary"
                                    >
                                        {isCreating ? <LoadingIndicator color="primary" inline /> : <SendIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...messageBodyRest}
                    />
                    <StyledHookFormErrorMessage
                        as="div"
                        errors={errors}
                        message={t('common:messaging.errors.message_error', { limit: DB_DESCRIPTION_MAX })}
                        name="messageBody"
                    />
                </Grid>
                {createMessageError && (
                    <Grid item xs={9}>
                        <StyledFormMessagingWrapper>
                            <Alert onClose={resetCreateMessageMutation} severity="error">
                                {createMessageError?.message}
                            </Alert>
                        </StyledFormMessagingWrapper>
                    </Grid>
                )}
                <OffPlatformSalesActivityWarningModal
                    isOpen={hasFlaggedTerms}
                    onClose={() => setHasFlaggedTerms(false)}
                />
                <Modal
                    aria-label={t('common:messaging.message_form')}
                    open={showUploadImagesModalContent}
                    onClose={closeModal}
                    closeAfterTransition
                    components={{
                        Backdrop
                    }}
                >
                    <StyledModalPaper>
                        <StyledModalCancelIcon onClick={closeModal} />
                        <Grid container justifyContent="center">
                            <Grid item container>
                                <Grid item xs={12}>
                                    <SelectUserImagesForm onCloseForm={closeModal} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledModalPaper>
                </Modal>
            </Grid>
        </StyledFullWidthForm>
    );
};
