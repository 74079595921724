import React from 'react';
import { useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Cancel from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';

export const AddressValidationOverrideForm = ({
    onCancel,
    onSubmit
}: {
    readonly onCancel: () => void;
    readonly onSubmit: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sx={{ mt: 1 }}>
                <Divider />
                <Typography variant="body1" sx={{ my: 1 }}>
                    {t('common:address_form.this_address_could_not_be_validated')}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    {t('common:address_form.are_you_sure_you_want_to_use_address')}
                </Typography>
            </Grid>
            <Grid item xs={11} container spacing={1} justifyContent="flex-end" sx={{ my: 1 }}>
                <Grid item>
                    <Button
                        color="inherit"
                        onClick={onCancel}
                        startIcon={<Cancel />}
                        sx={{ mt: 0.5 }}
                        variant="contained"
                    >
                        {t('common:general.cancel')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" onClick={onSubmit} sx={{ mt: 0.5 }} variant="contained">
                        {t('common:address_form.confirm_address')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
