import { UiQueryCacheKey } from '@bladebinge/types';
import { useQueryClient } from '@tanstack/react-query';

export const useReplaceInfiniteQueryListItem = <T>() => {
    const queryClient = useQueryClient();

    return ({
        cacheKey,
        item,
        variablesId
    }: {
        readonly cacheKey: UiQueryCacheKey;
        readonly item: T;
        readonly variablesId: string;
    }) =>
        queryClient.setQueryData(cacheKey, ({ pages, ...rest }) => ({
            ...rest,
            // @ts-expect-error
            pages: pages.map(({ result, _total, ...restOfPage }) => ({
                ...restOfPage,
                // @ts-expect-error
                result: result.map((originalItem: T) => (originalItem?.id === variablesId ? item : originalItem))
            }))
        }));
};
