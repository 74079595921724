import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { UserProfileAddressSelector } from '../UserProfileAddressSelector';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { useUserAddressBook } from '../../../hooks/react-query/logged-in-user-hooks/use-user-address-book';
import { AddressFormWrapper } from '../AddressFormWrapper';
import { OfferStepCheckmark } from './OfferStepCheckmark';

export const PurchaseOfferShippingForm = () => {
    const { t } = useTranslation();
    const ariaId = useId();
    const { offeredByUserShipToAddressId, setOfferedByUserShipToAddressId } = useMakeAnOfferContext();
    const { data: addressBook = [], isFetched: isAddressBookFetched } = useUserAddressBook();
    const [showCreateAddressForm, setShowCreateAddressForm] = useState<boolean>(false);

    const handleSelectFromAddressBook = useCallback(
        (addressId: string) => {
            setOfferedByUserShipToAddressId(addressId ?? null);
        },
        [setOfferedByUserShipToAddressId]
    );

    const defaultShippingAddress = useMemo(
        () => addressBook.find((address) => address.isPrimaryShipping),
        [addressBook]
    );

    useEffect(() => {
        setShowCreateAddressForm(!offeredByUserShipToAddressId);
    }, [offeredByUserShipToAddressId]);

    useEffect(() => {
        if (!isAddressBookFetched) {
            return;
        }

        if (defaultShippingAddress) {
            handleSelectFromAddressBook(defaultShippingAddress.id);
        }
    }, [defaultShippingAddress, handleSelectFromAddressBook, isAddressBookFetched]);

    return (
        <Grid container item alignItems="flex-start" xs={12} sx={{ my: 1 }}>
            <Accordion sx={{ width: '100%', pl: 0, ml: 0 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`offer-details-${ariaId}-content`}
                    id={`offer-details-${ariaId}-header`}
                >
                    <Typography variant="h6" component="div" sx={{ py: 0, my: 0 }}>
                        {t('common:purchase_offer.shipping_info')}
                    </Typography>
                    <OfferStepCheckmark checked={Boolean(offeredByUserShipToAddressId)} sx={{ ml: 1, py: 0, my: 0 }} />
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            {!isAddressBookFetched && <LoadingIndicator />}
                            <Typography variant="h6" component="div">
                                {t('common:address_form.select_from_address_book')}
                            </Typography>
                            <UserProfileAddressSelector
                                clearSelectionText={t('common:purchase.clear_selection_and_show_new_address_form')}
                                inputLabel={t('common:purchase.stages.shipping_address')}
                                onSelect={handleSelectFromAddressBook}
                                preselectPrimaryType="shipping"
                                preselectedAddressId={defaultShippingAddress?.id ?? ''}
                                title=""
                            />
                        </Grid>
                        <Grid item xs={1}>
                            {' '}
                        </Grid>
                        {showCreateAddressForm && (
                            <Grid item xs={11} sx={{ mt: 2 }}>
                                <Typography variant="h6" component="div">
                                    {t('common:purchase.or_add_a_new_address')}
                                </Typography>
                                <AddressFormWrapper
                                    formAction="create"
                                    submitButtonText={t('common:address_form.add_a_new_address')}
                                    submitCallback={(confirmedAddressId?: string | null) =>
                                        setOfferedByUserShipToAddressId(confirmedAddressId ? confirmedAddressId : null)
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
