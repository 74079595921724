import React from 'react';
import { useTranslation } from 'next-i18next';
import type { SxProps } from '@mui/system';
import Typography from '@mui/material/Typography';
import type { ListingEntity, ListingGraph } from '@bladebinge/types';

export const ListingShippingOfferDescription = ({
    listing,
    noticesVariant = 'body1',
    shipFromVariant = 'body1',
    sx = {}
}: {
    readonly listing: ListingEntity | ListingGraph;
    readonly sx?: SxProps;
    readonly noticesVariant?: 'caption' | 'body1' | 'body2';
    readonly shipFromVariant?: 'caption' | 'body1' | 'body2';
}) => {
    const { t } = useTranslation();

    const freeShipDom = listing?.offersSellerPaidDomesticShipping;
    const freeShipInt = listing?.offersSellerPaidInternationalShipping;

    const notices: React.ReactNode[] = [];
    const shipFromCountry = listing?.shipFromData?.country ? listing.shipFromData.country : '';

    if (!freeShipDom && !freeShipInt) {
        notices.push(
            <Typography
                key="buyer_paid_shipping"
                className="shipping-bp"
                variant={noticesVariant}
                component="div"
                sx={sx}
            >
                {t('common:listing.shipping_calculated_at_checkout')}
            </Typography>
        );
    }

    if (freeShipDom) {
        notices.push(
            <Typography
                key="shipper_paid_dom_shipping"
                className="shipping-spd"
                variant={noticesVariant}
                component="div"
                sx={{ color: 'info.main', ...sx }}
            >
                {t('common:listing.free_domestic_shipping', { country: listing?.shipFromData?.country })}
            </Typography>
        );
    }

    if (freeShipInt) {
        notices.push(
            <Typography
                key="shipper_paid_intl_shipping"
                className="shipping-spi"
                variant={noticesVariant}
                component="div"
                sx={{ color: 'info.main', ...sx }}
            >
                {t('common:listing.free_international_shipping')}
            </Typography>
        );
    }

    return (
        <>
            {notices.map((NoticeComponent) => NoticeComponent)}
            {shipFromCountry && (
                <Typography variant={shipFromVariant} component="div" sx={sx}>
                    {t('common:listing.ships_from', { country: shipFromCountry })}
                </Typography>
            )}
        </>
    );
};
