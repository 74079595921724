import { PurchaseOfferGraph, PurchaseOfferPostJson } from '@bladebinge/types';
import { PROXY_ROUTES } from '../ui-proxy-routes';
import { normalizedFetch } from '../utils/normalized-fetch';

export const createPurchaseOffer = async (
    {
        loggedInUserId,
        makeAnOfferData
    }: {
        loggedInUserId: string;
        makeAnOfferData: PurchaseOfferPostJson;
    },
    signal?: AbortSignal
): Promise<PurchaseOfferGraph> => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.createPurchaseOffer.build({
            userId: loggedInUserId
        }),
        options: {
            method: 'POST',
            body: JSON.stringify(makeAnOfferData),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
