import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    ListingGraph,
    MessageGraph,
    PurchaseOfferGraph,
    PurchaseOfferListingData,
    PurchaseOfferListingGraph,
    UiQueryCacheKey,
    UserProfileDisplayGraph
} from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { getGlobalStaticFeatureEnablement } from '@bladebinge/web-service-common/src/feature-flags/get-global-static-feature-enablement';
import { ALLOWED_PRICE_PERCENTAGE_OVERPAY_THRESHOLD } from '@bladebinge/web-service-common/src/constants/offer-payment-thresholds';
import { useMe } from '../me/me-context';
import { createPurchaseOffer } from '../../server/api-proxy/ui-mutation-fns/create-purchase-offer';
import { messageCreatedAtSortReversed } from '../../components/my-bladebinge/messaging/MessageThreadView';
import { useMessageImagesContext } from '../image-uploads/message-images-context';
import { useReplaceInfiniteQueryListItem } from '../../hooks/use-replace-inifinite-query-list-item';
import { useUserPreferences } from '../user-preferences/user-preferences-context';
import { useUserProfileDisplayGraph } from '../../hooks/react-query/use-user-profile-display-graph';
import { useUserInterfaceNotificationsContext } from '../user-interface-notifications/user-interface-notifications-context';

interface CounterOfferContext {
    listCacheKey: UiQueryCacheKey;
    originalOffer: PurchaseOfferGraph;
}

interface OfferToUserContext {
    offeredToUserId: string;
    offeredToUserProfileId: string;
}

interface OfferMessageData {
    messageBody: string;
    imageIds?: string[];
}

interface MakeAnOfferContext {
    isOfferingEnabled?: boolean;
    isTradingEnabled?: boolean;
    addListingToOffer: (listing: ListingGraph) => void;
    removeListingFromOffer: (listing: ListingGraph) => void;
    addTradeToOffer: (listing: ListingGraph) => void;
    removeTradeFromOffer: (listing: ListingGraph) => void;

    clearOffer: () => void;
    completedOffer: PurchaseOfferGraph | null;
    hasOffer: boolean;
    firstMessage: MessageGraph | null;
    hasAllRequirements: boolean;

    offerHasTrades: boolean;
    offerListings: Map<string, ListingGraph>;
    offerTradeListings: Map<string, ListingGraph>;
    offerListingDataByListingIdMap: Map<string, PurchaseOfferListingData>;
    offerTradeDataByListingIdMap: Map<string, PurchaseOfferListingData>;
    offerMessageData: OfferMessageData | null;
    originalTotal: number;
    originalTradeTotal: number;
    offerTotal: number;
    offerTradeTotal: number;
    parentOfferId: string | null;
    parentOffer: PurchaseOfferGraph | null;

    purchaseOfferError?: Error | null;
    purchaseOfferPending: boolean;
    purchaseOfferSuccess: boolean;
    resetPurchaseOfferMutation: () => void;

    offeredToUserContext: OfferToUserContext | null;
    remoteUserProfileGraph: UserProfileDisplayGraph | null;
    setOfferDataForListing: (listing: ListingGraph, listingOfferData: PurchaseOfferListingData) => void;
    setTradeDataForListing: (listing: ListingGraph, listingOfferData: PurchaseOfferListingData) => void;
    setOfferMessageData: (messageData: OfferMessageData | null) => void;

    // shipping addresses
    offeredByUserShipToAddressId: string | null;
    offeredToUserShipToAddressId: string | null;
    setOfferedToUserShipToAddressId: (addressId: string | null) => void;
    setOfferedByUserShipToAddressId: (addressId: string | null) => void;

    // open/close states
    isDrawerOpen: boolean;
    showSellerListingSelection: boolean;
    setIsDrawerOpen: (open: boolean) => void;
    setShowSellerListingSelection: (show: boolean) => void;

    startCounterOffer: ({
        listCacheKey,
        originalOffer
    }: {
        listCacheKey: UiQueryCacheKey;
        originalOffer: PurchaseOfferGraph;
    }) => void;
    submitPurchaseOffer: ({
        offeredByUserId,
        parentOfferId
    }: {
        offeredByUserId: string;
        parentOfferId?: string;
    }) => void;
}

const makeAnOfferContext = createContext<MakeAnOfferContext>({
    isOfferingEnabled: false,
    isTradingEnabled: false,
    isDrawerOpen: false,
    addListingToOffer(listing: ListingGraph) {},
    addTradeToOffer(listing: ListingGraph) {},
    clearOffer() {},
    completedOffer: null,
    firstMessage: null,
    hasAllRequirements: false,
    hasOffer: false,
    offerHasTrades: false,
    offerMessageData: null,
    removeListingFromOffer(listing: ListingGraph) {},
    removeTradeFromOffer(listing: ListingGraph) {},
    offerListings: new Map<string, ListingGraph>(),
    offerTradeListings: new Map<string, ListingGraph>(),
    offerListingDataByListingIdMap: new Map<string, PurchaseOfferListingData>(),
    offerTradeDataByListingIdMap: new Map<string, PurchaseOfferListingData>(),
    originalTotal: 0,
    originalTradeTotal: 0,
    offerTotal: 0,
    offerTradeTotal: 0,
    parentOfferId: null,
    parentOffer: null,
    purchaseOfferError: null,
    purchaseOfferPending: false,
    purchaseOfferSuccess: false,
    resetPurchaseOfferMutation() {},
    setIsDrawerOpen() {},
    setOfferMessageData(messageData: OfferMessageData | null) {},
    setOfferDataForListing(listing: ListingGraph, listingOfferData: PurchaseOfferListingData) {},
    setTradeDataForListing(listing: ListingGraph, listingOfferData: PurchaseOfferListingData) {},
    setOfferedByUserShipToAddressId(addressId: string | null) {},
    offeredByUserShipToAddressId: null,
    setOfferedToUserShipToAddressId(addressId: string | null) {},
    startCounterOffer({
        listCacheKey,
        originalOffer
    }: {
        listCacheKey: UiQueryCacheKey;
        originalOffer: PurchaseOfferGraph;
    }) {},
    offeredToUserShipToAddressId: null,
    offeredToUserContext: null,
    remoteUserProfileGraph: null,
    showSellerListingSelection: false,
    setShowSellerListingSelection() {},
    submitPurchaseOffer({ offeredByUserId, parentOfferId }: { offeredByUserId: string; parentOfferId?: string }) {}
});

const { Provider } = makeAnOfferContext;

export const MakeAnOfferContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const { id: loggedInUserId, activeProfileId: creatorUserProfileId } = useMe();
    const { invalidateNotificationsCounts } = useUserInterfaceNotificationsContext();
    const { persistedUserPreferences } = useUserPreferences();
    const queryClient = useQueryClient();
    const listItemReplaceFn = useReplaceInfiniteQueryListItem<PurchaseOfferGraph>();
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [counterOfferContext, setCounterOfferContext] = useState<CounterOfferContext | null>(null);
    const [completedOffer, setCompletedOffer] = useState<PurchaseOfferGraph | null>(null);
    const [offerListings, setOfferListings] = useState<Map<string, ListingGraph>>(new Map<string, ListingGraph>());
    const [offerTradeListings, setOfferTradeListings] = useState<Map<string, ListingGraph>>(
        new Map<string, ListingGraph>()
    );
    const [offeredByUserShipToAddressId, setOfferedByUserShipToAddressId] = useState<string | null>(null);
    const [offeredToUserShipToAddressId, setOfferedToUserShipToAddressId] = useState<string | null>(null);
    const [offerMessageData, setOfferMessageData] = useState<OfferMessageData | null>(null);
    const [offerListingDataByListingIdMap, setOfferListingDataByListingIdMap] = useState<
        Map<string, PurchaseOfferListingData>
    >(new Map<string, PurchaseOfferListingData>());
    const [offerTradeDataByListingIdMap, setOfferTradeDataByListingIdMap] = useState<
        Map<string, PurchaseOfferListingData>
    >(new Map<string, PurchaseOfferListingData>());
    const [offeredToUserContext, setOfferedToUserContext] = useState<OfferToUserContext | null>(null);
    const [parentOfferId, setParentOfferId] = useState<string | null>(null);
    const [showSellerListingSelection, setShowSellerListingSelection] = useState<boolean>(false);
    const { setDetachedImageUploads } = useMessageImagesContext();

    const { data: remoteUserProfileGraph = null, refetch: refetchSellerProfile } = useUserProfileDisplayGraph({
        id: counterOfferContext?.originalOffer
            ? counterOfferContext?.originalOffer?.offeredByUserProfileId
            : offeredToUserContext?.offeredToUserProfileId
    });

    useEffect(() => {
        refetchSellerProfile();
    }, [offeredToUserContext, refetchSellerProfile]);

    useEffect(() => {
        if (!isDrawerOpen && showSellerListingSelection) {
            setShowSellerListingSelection(false);
        }
    }, [isDrawerOpen, showSellerListingSelection, setShowSellerListingSelection]);

    const startCounterOffer = useCallback(
        ({ listCacheKey, originalOffer }: { listCacheKey: UiQueryCacheKey; originalOffer: PurchaseOfferGraph }) => {
            const {
                id: counterOfferParentOfferId,
                purchaseOfferListings = [],
                purchaseOfferTradeListings = [],
                // flip user offered By/To roles from original offer
                offeredByUserId: counterOfferOfferedToUserId,
                offeredByUserProfileId: counterOfferedToUserProfileId
                // offeredToUserId: counterOfferOfferByUserId,
                // offeredToUserProfileId: counterOfferedByUserProfileId,
            } = originalOffer;

            const counterOfferListingsMap = new Map<string, ListingGraph>();
            const counterOfferListingsOfferData = new Map<string, PurchaseOfferListingData>();
            purchaseOfferListings.forEach((purchaseOfferListing: PurchaseOfferListingGraph) => {
                counterOfferListingsMap.set(purchaseOfferListing.listingId, purchaseOfferListing?.listing);
                counterOfferListingsOfferData.set(purchaseOfferListing.listingId, {
                    quantity: purchaseOfferListing.quantity,
                    unitPrice: purchaseOfferListing.unitPrice
                });
            });

            const counterOfferTradeListingsMap = new Map<string, ListingGraph>();
            const counterOfferTradeListingsOfferData = new Map<string, PurchaseOfferListingData>();
            purchaseOfferTradeListings.forEach((purchaseOfferTradeListing: PurchaseOfferListingGraph) => {
                counterOfferTradeListingsMap.set(
                    purchaseOfferTradeListing.listingId,
                    purchaseOfferTradeListing?.listing
                );
                counterOfferTradeListingsOfferData.set(purchaseOfferTradeListing.listingId, {
                    quantity: purchaseOfferTradeListing.quantity,
                    unitPrice: purchaseOfferTradeListing.unitPrice
                });
            });

            setCounterOfferContext({
                originalOffer,
                listCacheKey
            });
            setParentOfferId(counterOfferParentOfferId);
            setOfferedToUserContext({
                offeredToUserId: counterOfferOfferedToUserId,
                offeredToUserProfileId: counterOfferedToUserProfileId
            });
            setOfferListings(counterOfferListingsMap);
            setOfferTradeListings(counterOfferTradeListingsMap);
            setOfferListingDataByListingIdMap(counterOfferListingsOfferData);
            setOfferTradeDataByListingIdMap(counterOfferTradeListingsOfferData);
        },
        []
    );

    const { originalTradeTotal, offerTradeTotal } = useMemo(
        () =>
            Array.from(offerTradeListings.keys()).reduce<{
                originalTradeTotal: number;
                offerTradeTotal: number;
            }>(
                (acc, listingId) => {
                    const listing = offerTradeListings.get(listingId);
                    const listingOffer = offerTradeDataByListingIdMap.get(listingId);

                    const listingOfferPriceOrOriginalPrice = listingOffer?.unitPrice ?? listing?.price ?? 0;
                    const listingOfferQuantity = listingOffer?.quantity ?? 1;

                    acc.originalTradeTotal += listing?.price ?? 0 * listingOfferQuantity;
                    acc.offerTradeTotal += listingOfferPriceOrOriginalPrice * listingOfferQuantity;

                    return acc;
                },
                {
                    originalTradeTotal: 0,
                    offerTradeTotal: 0
                }
            ),
        [offerTradeDataByListingIdMap, offerTradeListings]
    );

    const { originalTotal, offerTotal } = useMemo(
        () =>
            Array.from(offerListings.keys()).reduce<{
                originalTotal: number;
                offerTotal: number;
            }>(
                (acc, listingId) => {
                    const listing = offerListings.get(listingId);
                    const listingOffer = offerListingDataByListingIdMap.get(listingId);

                    const listingOfferPriceOrOriginalPrice = listingOffer?.unitPrice ?? listing?.price ?? 0;
                    const listingOfferQuantity = listingOffer?.quantity ?? 1;

                    acc.originalTotal += listing?.price ?? 0 * listingOfferQuantity;
                    acc.offerTotal += listingOfferPriceOrOriginalPrice * listingOfferQuantity;

                    return acc;
                },
                {
                    originalTotal: 0,
                    offerTotal: 0
                }
            ),
        [offerListingDataByListingIdMap, offerListings]
    );

    const submitPurchaseOfferMutation = useMutation({
        mutationFn: createPurchaseOffer,
        onSuccess(offer: PurchaseOfferGraph) {
            invalidateNotificationsCounts();

            if (offerMessageData?.imageIds?.length) {
                setDetachedImageUploads([]);
            }

            if (counterOfferContext) {
                listItemReplaceFn({
                    cacheKey: counterOfferContext.listCacheKey,
                    item: {
                        ...counterOfferContext.originalOffer,
                        status: offer?.parentOffer?.status ?? counterOfferContext.originalOffer?.status
                    },
                    variablesId: counterOfferContext.originalOffer.id
                });
                setCounterOfferContext(null);
            }

            setCompletedOffer(offer);
            queryClient.invalidateQueries({
                queryKey: uiCacheKeyBuilderMap.loggedInUserActivePurchaseOfferListingIds({
                    loggedInUserId
                })
            });
        }
    });

    // Saving offer
    const {
        error: purchaseOfferError,
        isPending: purchaseOfferPending,
        isSuccess: purchaseOfferSuccess,
        reset: resetPurchaseOfferMutation
    } = submitPurchaseOfferMutation;

    const clearOffer = useCallback(() => {
        setIsDrawerOpen(false);

        resetPurchaseOfferMutation();
        setCompletedOffer(null);
        setCounterOfferContext(null);
        setDetachedImageUploads([]);
        setOfferedByUserShipToAddressId(null);
        setOfferedToUserContext(null);
        setOfferedToUserShipToAddressId(null);
        setOfferListingDataByListingIdMap(new Map<string, PurchaseOfferListingData>());
        setOfferListings(new Map<string, ListingGraph>());
        setOfferMessageData(null);
        setOfferTradeDataByListingIdMap(new Map<string, PurchaseOfferListingData>());
        setOfferTradeListings(new Map<string, ListingGraph>());
        setParentOfferId(null);
        setShowSellerListingSelection(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        completedOffer,
        offerMessageData,
        offerListings,
        isDrawerOpen,
        offerListingDataByListingIdMap,
        resetPurchaseOfferMutation
    ]);

    const submitPurchaseOffer = useCallback(
        ({ offeredByUserId }: { offeredByUserId: string }) => {
            if (!loggedInUserId) {
                return;
            }

            submitPurchaseOfferMutation.mutate({
                loggedInUserId,
                makeAnOfferData: {
                    offeredByUserId,
                    offeredByUserProfileId: creatorUserProfileId ?? '',
                    creatorUserProfileId: creatorUserProfileId ?? '',
                    ...(parentOfferId ? { parentOfferId } : {}),
                    offeredToUserId: offeredToUserContext?.offeredToUserId ?? '',
                    offeredToUserProfileId: offeredToUserContext?.offeredToUserProfileId ?? '',
                    offerDataByListingId: Object.fromEntries(offerListingDataByListingIdMap),
                    offerTradeDataByListingId: Object.fromEntries(offerTradeDataByListingIdMap),
                    offerMerchandiseTotal: offerTotal,
                    offerTradeMerchandiseTotal: offerTradeTotal,
                    message: offerMessageData?.messageBody ?? '',
                    imageIds: offerMessageData?.imageIds ?? [],
                    offeredByUserShipToAddressId: offeredByUserShipToAddressId ?? '',
                    offeredToUserShipToAddressId: offeredToUserShipToAddressId ?? ''
                }
            });
        },
        [
            creatorUserProfileId,
            loggedInUserId,
            offerListingDataByListingIdMap,
            offerTradeDataByListingIdMap,
            offerMessageData?.imageIds,
            offerMessageData?.messageBody,
            offerTotal,
            offerTradeTotal,
            offeredToUserContext?.offeredToUserId,
            offeredToUserContext?.offeredToUserProfileId,
            offeredByUserShipToAddressId,
            offeredToUserShipToAddressId,
            parentOfferId,
            submitPurchaseOfferMutation
        ]
    );

    const offerHasTrades = useMemo(() => offerTradeListings.size > 0, [offerTradeListings]);
    const hasOffer = useMemo(() => {
        const offerHasListings = offerListingDataByListingIdMap.size > 0;

        if (offerHasTrades) {
            return true;
        }

        if (!offerHasListings) {
            return false;
        }

        return offerTotal !== originalTotal;
    }, [offerHasTrades, offerListingDataByListingIdMap, originalTotal, offerTotal]);

    const hasAllRequirements = useMemo(() => {
        const hasMessageImages = offerMessageData?.imageIds?.length ? offerMessageData?.imageIds?.length > 0 : false;
        const hasMessageBody = Boolean(offerMessageData?.messageBody);
        const hasMessageContent = hasMessageImages || hasMessageBody;
        const hasMessageInfoIfMessage = offerMessageData === null || hasMessageContent;
        const isQualifyingCounterOffer =
            counterOfferContext === null ||
            counterOfferContext?.originalOffer?.offerMerchandiseTotal !== offerTotal ||
            counterOfferContext?.originalOffer?.offerTradeMerchandiseTotal !== offerTradeTotal;

        return Boolean(
            hasMessageInfoIfMessage &&
                hasOffer &&
                offeredByUserShipToAddressId &&
                offeredToUserContext?.offeredToUserId &&
                isQualifyingCounterOffer
        );
    }, [
        counterOfferContext,
        hasOffer,
        offerMessageData,
        offeredToUserContext?.offeredToUserId,
        offeredByUserShipToAddressId,
        offerTotal,
        offerTradeTotal
    ]);

    const removeTradeFromOffer = useCallback(
        (listing: ListingGraph) => {
            if (offerTradeListings.has(listing.id)) {
                // need to rebuild for reference to refresh in consumer components
                const updatedMap = new Map<string, ListingGraph>();
                offerTradeListings.forEach((value: ListingGraph, key: string) => {
                    if (key !== listing.id) {
                        updatedMap.set(key, value);
                    }
                });
                setOfferTradeListings(updatedMap);
            }
        },
        [offerTradeListings, setOfferTradeListings]
    );

    const removeListingFromOffer = useCallback(
        (listing: ListingGraph) => {
            if (offerListings.has(listing.id)) {
                // need to rebuild for reference to refresh in consumer components
                const updatedMap = new Map<string, ListingGraph>();
                offerListings.forEach((value: ListingGraph, key: string) => {
                    if (key !== listing.id) {
                        updatedMap.set(key, value);
                    }
                });
                setOfferListings(updatedMap);
            }
        },
        [offerListings, setOfferListings]
    );

    const setTradeDataForListing = useCallback(
        (listing: ListingGraph, tradeOfferData: PurchaseOfferListingData) => {
            const listingId = listing.id;
            // need to rebuild for reference to refresh in consumer components
            const updatedMap = new Map<string, PurchaseOfferListingData>();
            offerTradeDataByListingIdMap.delete(listingId);

            // remove any listings with 0 quantity and skip listing being passed in
            offerTradeDataByListingIdMap.forEach((value: PurchaseOfferListingData, key: string) => {
                if (value.quantity > 0) {
                    updatedMap.set(key, value);
                }
            });

            // if we are setting a valid quantity for the listing, set its value in the map
            if (tradeOfferData.quantity > 0) {
                const maxAllowableTradePrice = (ALLOWED_PRICE_PERCENTAGE_OVERPAY_THRESHOLD + 1) * listing.price;
                updatedMap.set(listingId, {
                    ...tradeOfferData,
                    // can't set trade value greater than public listing price * overpay threshold
                    unitPrice: Math.min(tradeOfferData.unitPrice, maxAllowableTradePrice)
                });
            } else {
                removeTradeFromOffer(listing);
            }

            setOfferTradeDataByListingIdMap(updatedMap);
        },
        [offerTradeDataByListingIdMap, setOfferTradeDataByListingIdMap, removeTradeFromOffer]
    );

    const setOfferDataForListing = useCallback(
        (listing: ListingGraph, listingOfferData: PurchaseOfferListingData) => {
            const listingId = listing.id;
            // need to rebuild for reference to refresh in consumer components
            const updatedMap = new Map<string, PurchaseOfferListingData>();
            offerListingDataByListingIdMap.delete(listingId);

            // remove any listings with 0 quantity and skip listing being passed in
            offerListingDataByListingIdMap.forEach((value: PurchaseOfferListingData, key: string) => {
                if (value.quantity > 0) {
                    updatedMap.set(key, value);
                }
            });

            // if we are setting a valid quantity for the listing, set its value in the map
            if (listingOfferData.quantity > 0) {
                const maxAllowListingPriceIfTradingActive =
                    (ALLOWED_PRICE_PERCENTAGE_OVERPAY_THRESHOLD + 1) * listing.price;
                // allow over-offers on trades only, otherwise max allowable price is the marketplace price
                const allowedMaxListingPrice = offerHasTrades ? maxAllowListingPriceIfTradingActive : listing.price;
                updatedMap.set(listingId, {
                    ...listingOfferData,
                    // can't set listing offer value greater than public listing price
                    unitPrice: Math.min(listingOfferData?.unitPrice, allowedMaxListingPrice)
                });
            } else {
                removeListingFromOffer(listing);
            }

            setOfferListingDataByListingIdMap(updatedMap);
        },
        [offerHasTrades, offerListingDataByListingIdMap, setOfferListingDataByListingIdMap, removeListingFromOffer]
    );

    const addListingToOffer = useCallback(
        (listing: ListingGraph) => {
            const currentOwnerId = listing?.ownerId;
            const currentOwnerProfileId = listing?.ownerProfileId;

            // don't reset this info in a counter offer
            if (!parentOfferId && currentOwnerId !== offeredToUserContext?.offeredToUserId) {
                setOfferListingDataByListingIdMap(new Map<string, PurchaseOfferListingData>());
                setOfferedToUserContext(
                    currentOwnerId && currentOwnerProfileId
                        ? {
                              offeredToUserId: currentOwnerId,
                              offeredToUserProfileId: currentOwnerProfileId
                          }
                        : null
                );
                setOfferMessageData(null);
                resetPurchaseOfferMutation();
            }

            if (offerListings.size === 0) {
                setOfferListings(new Map<string, ListingGraph>().set(listing.id, listing));
                setOfferDataForListing(listing, { quantity: 1, unitPrice: listing.price });
                return;
            }

            if (listing.ownerId !== currentOwnerId) {
                setOfferListings(new Map<string, ListingGraph>().set(listing.id, listing));
                setOfferDataForListing(listing, { quantity: 1, unitPrice: listing.price });
                return;
            }

            // need to rebuild for reference to refresh in consumer components
            const updatedOfferListingsMap = new Map<string, ListingGraph>();
            offerListings.forEach((value: ListingGraph, key: string) => {
                if (value.ownerId === currentOwnerId) {
                    updatedOfferListingsMap.set(key, value);
                }
            });
            updatedOfferListingsMap.set(listing.id, listing);
            setOfferListings(updatedOfferListingsMap);
            setOfferDataForListing(listing, { quantity: 1, unitPrice: listing.price });
        },
        [
            offerListings,
            parentOfferId,
            setOfferListings,
            setOfferDataForListing,
            offeredToUserContext?.offeredToUserId,
            resetPurchaseOfferMutation
        ]
    );

    const addTradeToOffer = useCallback(
        (listing: ListingGraph) => {
            if (offerTradeListings.size === 0) {
                setOfferTradeListings(new Map<string, ListingGraph>().set(listing.id, listing));
                setTradeDataForListing(listing, { quantity: 1, unitPrice: listing.price });
                return;
            }

            // need to rebuild for reference to refresh in consumer components
            const updatedTradeOfferListingsMap = new Map<string, ListingGraph>();
            offerTradeListings.forEach((value: ListingGraph, key: string) => {
                updatedTradeOfferListingsMap.set(key, value);
            });
            updatedTradeOfferListingsMap.set(listing.id, listing);
            setOfferTradeListings(updatedTradeOfferListingsMap);
            setTradeDataForListing(listing, { quantity: 1, unitPrice: listing.price });
        },
        [offerTradeListings, setOfferTradeListings, setTradeDataForListing]
    );

    const firstMessage = useMemo(
        () => (completedOffer?.messages ? completedOffer.messages.sort(messageCreatedAtSortReversed)[0] : null),
        [completedOffer]
    );

    const isOfferingEnabled = useMemo(() => {
        const isEnvEnabled = getGlobalStaticFeatureEnablement('EnableOffers', loggedInUserId);
        const isBuyerEnabled = persistedUserPreferences?.enableOffersOption !== false;

        return isEnvEnabled && isBuyerEnabled;
    }, [loggedInUserId, persistedUserPreferences]);

    const isTradingEnabled = useMemo(() => {
        const isEnvEnabled = getGlobalStaticFeatureEnablement('EnableTrades', loggedInUserId);
        const isBuyerEnabled = persistedUserPreferences?.enableTradeOffersOption !== false;
        const isRemoteUserTradingEnabled = remoteUserProfileGraph?.allowsTrades;

        return isEnvEnabled && isBuyerEnabled && isRemoteUserTradingEnabled;
    }, [loggedInUserId, persistedUserPreferences, remoteUserProfileGraph]);

    useEffect(() => {
        if (!isDrawerOpen && offerListings.size > 0) {
            setIsDrawerOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerListings]);

    return (
        <Provider
            value={{
                addListingToOffer,
                addTradeToOffer,
                isOfferingEnabled,
                isTradingEnabled,
                clearOffer,
                completedOffer,
                firstMessage,
                hasAllRequirements,
                hasOffer,
                isDrawerOpen,
                offerListings,
                offerTradeListings,
                offerListingDataByListingIdMap,
                offerTradeDataByListingIdMap,
                offerMessageData,
                originalTotal,
                offerHasTrades,
                offerTotal,
                offerTradeTotal,
                originalTradeTotal,
                parentOfferId,
                parentOffer: counterOfferContext?.originalOffer ?? null,
                purchaseOfferError,
                purchaseOfferPending,
                purchaseOfferSuccess,
                removeListingFromOffer,
                removeTradeFromOffer,
                resetPurchaseOfferMutation,
                offeredToUserContext,
                remoteUserProfileGraph,
                setIsDrawerOpen,
                setOfferDataForListing,
                setOfferMessageData,
                setShowSellerListingSelection,
                setOfferedByUserShipToAddressId,
                setTradeDataForListing,
                offeredByUserShipToAddressId,
                setOfferedToUserShipToAddressId,
                offeredToUserShipToAddressId,
                showSellerListingSelection,
                startCounterOffer,
                submitPurchaseOffer
            }}
        >
            {children}
        </Provider>
    );
};

export const useMakeAnOfferContext = (): MakeAnOfferContext => useContext(makeAnOfferContext);
