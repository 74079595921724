import type { ParsedUrlQuery } from 'querystring';
import type { ApiResponseOrError, BulkIsReadMapping, MessageGraph, MessagePostJson } from '@bladebinge/types';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const bulkReadStatusRequest = async ({
    bulkIsReadMapping,
    userId
}: {
    userId: string;
    bulkIsReadMapping: BulkIsReadMapping;
}) => {
    try {
        const updateReadStatusResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.bulkUpdateMessageReadStatuses.build({ userId }),
            options: {
                method: 'POST',
                body: JSON.stringify({
                    bulkIsReadMapping
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });

        return updateReadStatusResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const createMessageRequest = async (
    createMessageJson: MessagePostJson
): Promise<ApiResponseOrError<MessageGraph>> => {
    try {
        const createMessageResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.createMessage.build({
                userId: createMessageJson?.creatorId
            }),
            options: {
                method: 'POST',
                body: JSON.stringify(createMessageJson),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });

        return createMessageResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const userMessageThreadRequest = async (
    {
        queryParams,
        parentMessageId,
        threadUserIds,
        userId
    }: {
        queryParams: ParsedUrlQuery;
        parentMessageId: string;
        threadUserIds: string[];
        userId: string;
    },
    signal?: AbortSignal
) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchMessageThread.build({
                queryParams,
                userId
            }),
            options: {
                method: 'POST',
                body: JSON.stringify({
                    parentMessageId,
                    threadUserIds
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                ...(signal ? { signal } : {})
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const userReceivedMessagesRequest = async (
    {
        userId,
        queryParams
    }: {
        userId: string;
        queryParams: ParsedUrlQuery;
    },
    signal?: AbortSignal
) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchMessageInboxReceivedMessages.build({
                queryParams,
                userId
            }),
            options: { signal }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const userSentMessagesRequest = async (
    {
        userId,
        queryParams
    }: {
        userId: string;
        queryParams: ParsedUrlQuery;
    },
    signal?: AbortSignal
) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchMessageOuboxSentMessages.build({
                queryParams,
                userId
            }),
            options: { signal }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};
