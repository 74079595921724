import {
    extractEmailsFromMessage,
    OFF_PLATFORM_FIND_TERMS
} from '@bladebinge/web-service-common/src/utils/demerits/is-off-platform-message';

export const includesOffPlatformMessagingFilter = (
    message?: string
): {
    result: boolean;
    includesOffPlatformFindTerms: boolean;
    hasEmails: boolean;
} => {
    if (!message) {
        return {
            result: false,
            includesOffPlatformFindTerms: false,
            hasEmails: false
        };
    }

    const lowerCaseMessage = message.toLocaleLowerCase();

    const includesOffPlatformFindTerms = Boolean(
        OFF_PLATFORM_FIND_TERMS.some((term) => lowerCaseMessage.includes(term))
    );

    const messageEmails = extractEmailsFromMessage(lowerCaseMessage);
    const hasEmails = Boolean(messageEmails && messageEmails.length > 0);

    return {
        result: includesOffPlatformFindTerms || hasEmails,
        includesOffPlatformFindTerms: Boolean(includesOffPlatformFindTerms),
        hasEmails
    };
};
